import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "no-data-container" }
const _hoisted_2 = { class: "no-data-text" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = {
  key: 0,
  class: "no-data-button"
}

import IconAtom from "@/components/atoms/IconAtom.vue";
import ButtonAtom from "@/components/atoms/ButtonAtom.vue";
import { RouterLink } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'NoDataMolecule',
  props: {
    noDataButtonProps: {},
    icon: {}
  },
  emits: ["update:modelValue", "buttonClick"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;

const props = __props;


// const props = defineProps({
//   noDataButtonProps: {
//     type: Object,
//     default: () => ({
//       route: "",
//       buttonClass: "",
//       icon: "",
//       iconColor: "",
//       buttonText: "",
//       headerText: "There are no data to display yet",
//       descriptionText: "It's time to create the first one!",
//     }),
//   },
//   icon: {
//     type: String,
//     default: "",
//   },
// });

const emitButtonClick = () => {
  emits("buttonClick", props.noDataButtonProps);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(IconAtom, { icon: _ctx.icon }, null, 8, ["icon"])
      ]),
      _createElementVNode("h3", null, _toDisplayString(props.noDataButtonProps.headerText), 1),
      _createElementVNode("p", null, _toDisplayString(props.noDataButtonProps.descriptionText), 1)
    ]),
    (props.noDataButtonProps?.buttonText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            onClick: emitButtonClick,
            class: "link"
          }, [
            _createVNode(ButtonAtom, {
              class: _normalizeClass(props.noDataButtonProps.buttonClass)
            }, {
              default: _withCtx(() => [
                _createVNode(IconAtom, {
                  icon: props.noDataButtonProps.icon,
                  color: props.noDataButtonProps.iconColor
                }, null, 8, ["icon", "color"]),
                _createElementVNode("p", null, _toDisplayString(props.noDataButtonProps.buttonText), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})