import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-molecule-container" }
const _hoisted_2 = { class: "input-molecule" }
const _hoisted_3 = { for: "props.id" }

import { ref } from "vue";

import InputAtom from "@/components/atoms/InputAtom.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputMolecule',
  props: {
  title: String,
  label: String,
  id: String,
  placeholder: String,
  modelValue: String,
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const localModelValue = ref(props.modelValue);

const updateValue = (value) => {
  localModelValue.value = value;
  emit("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(props.title), 1),
      _createElementVNode("label", _hoisted_3, _toDisplayString(props.label), 1),
      _createVNode(InputAtom, {
        id: props.id,
        placeholder: props.placeholder,
        modelValue: localModelValue.value,
        "onUpdate:modelValue": updateValue
      }, null, 8, ["id", "placeholder", "modelValue"])
    ])
  ]))
}
}

})