import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "comments-header" }
const _hoisted_2 = { class: "comments-title" }
const _hoisted_3 = { class: "comments-modal-content" }
const _hoisted_4 = { class: "status" }
const _hoisted_5 = { class: "icon-wrapper" }

import { onMounted, onUnmounted, computed, ref } from 'vue';
  import commentsIcon from '@/assets/svg/commentsIcon.vue';
  
  import { SearchParamsAND } from '@/shared/filterParams';
  import TableMolecule from '../table-molecule/TableMolecule.vue';
  import { useStore } from 'vuex';
  import IconAtom from '@/components/atoms/IconAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ViewCommentsMolecule',
  props: {
    show: { type: Boolean },
    resource_id: {},
    type: {}
  },
  emits: ['update:show', 'currentIdChange'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const store = useStore();

  const currentId = ref(props.resource_id || '');
  const currentType = ref(props.type || 'resource');
  const commentHistory = ref<Array<{ id: string; type: string }>>([]);

  const defaultFilters = computed(() => {
    try {
      let filterField;
      switch (currentType.value) {
        case 'GroupDiscussion':
          filterField = 'discussion_id';
          break;
        case 'parent_comment':
          filterField = 'parent_comment_id';
          break;
        default:
          filterField = 'resource_id';
      }
      return [new SearchParamsAND(currentId.value, filterField, 'equals')];
    } catch (error) {
      console.error('Error creating filters:', error);
      return [];
    }
  });

  const comments = computed(() => {
    try {
      return store.getters['comment/getCount'];
    } catch (error) {
      console.error('Error getting comment count:', error);
      return 0;
    }
  });

  const closeModal = () => {
    try {
      emit('update:show', false);
      currentId.value = props.resource_id || '';
      currentType.value = props.type || 'resource';
      commentHistory.value = [];
    } catch (error) {
      console.error('Error closing modal:', error);
    }
  };

  const navigateToParent = (parentId: string) => {
    if (!parentId) {
      console.error('Invalid parent ID');
      return;
    }

    try {
      commentHistory.value.push({
        id: currentId.value,
        type: currentType.value,
      });

      currentId.value = parentId;
      currentType.value = 'parent_comment';

      store.commit('comment/CLEAR_ITEMS');
      emit('currentIdChange', parentId);
    } catch (error) {
      console.error('Error navigating to parent:', error);
      commentHistory.value.pop();
    }
  };

  const goBack = () => {
    try {
      if (commentHistory.value.length > 0) {
        const previousState = commentHistory.value.pop();
        if (previousState) {
          currentId.value = previousState.id;
          currentType.value = previousState.type;
          store.commit('comment/CLEAR_ITEMS');
        }
      }
    } catch (error) {
      console.error('Error navigating back:', error);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "comments-overlay",
    onClick: closeModal
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["comments-content", {
        'no-comments': comments.value,
        'with-comments': comments.value,
      }]),
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        (commentHistory.value.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "back-button",
              onClick: goBack
            }, [
              _createVNode(IconAtom, {
                icon: 'angleLeft',
                color: "black"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, _toDisplayString(currentType.value === 'parent_comment' ? 'Replies' : 'Comments'), 1)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(TableMolecule, {
          dataType: "Comment",
          dynamicDefaultFilter: defaultFilters.value,
          onNavigateToParent: navigateToParent,
          type: currentType.value,
          currentId: currentId.value
        }, null, 8, ["dynamicDefaultFilter", "type", "currentId"])
      ], 512), [
        [_vShow, comments.value]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(commentsIcon, { class: "no-comments-icon" })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("h4", null, "This post has no comments yet", -1))
      ], 512), [
        [_vShow, !comments.value]
      ])
    ], 2)
  ], 512)), [
    [_vShow, props.show]
  ])
}
}

})