import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "group-details-discussion-table" }

import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import GroupsFormOrganism from '@/components/organisms/groups-form-organism/GroupsFormOrganism.vue';
  import CreateDiscussionAtom from '@/components/atoms/CreateDiscussionAtom.vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Group from '@/models/group/group.model';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import { SearchParamsAND } from '@/shared/filterParams';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'GroupsDetailsView',
  setup(__props) {

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const group = computed(() => store.getters['group/getGroup']);
  const showCreateDiscussionModal = computed(
    () => store.getters['group/getIsDiscussionModalOpen'],
  );

  const loading = ref(true);

  const customFilter = ref([]);

  let topbarPrimaryButtonText = '';
  let topbarSecondaryButtonText = '';
  let topbarPrimaryButtonHandler;
  let topbarSecondaryButtonHandler;

  onMounted(() => {
    if (route.query.id) {
      customFilter.value.push(
        new SearchParamsAND(
          route.query.id.toString(),
          'discussions.discussion.discussion_id',
          'equals',
        ),
      );
      store.dispatch('group/fetchItemDetails', route.query.id).then(() => {
        loading.value = false;
      });

      topbarPrimaryButtonText = 'Save';
      topbarSecondaryButtonText = 'Cancel';
      topbarSecondaryButtonHandler = cancelHandler;
      topbarPrimaryButtonHandler = publish;
    } else {
      store.commit('group/SET_GROUP', new Group({}));
      loading.value = false;
      loading.value = false;
      topbarPrimaryButtonText = 'Publish';
      topbarSecondaryButtonText = 'Save to draft';
      topbarPrimaryButtonHandler = publish;
      topbarSecondaryButtonHandler = submitToDraft;
    }
  });

  const publish = async () => {
    store.commit('group/SET_GROUP', {
      ...group.value,
      status: 'published',
      is_published: true,
    });
    await nextTick();
    store.dispatch('group/createItem', group.value).then(() => {
      router.back();
    });
  };

  const submitToDraft = async () => {
    store.commit('group/SET_GROUP', {
      ...group.value,
      status: 'draft',
      is_published: false,
    });
    await nextTick();
    store.dispatch('group/createItem', group.value).then(() => {
      router.back();
    });
  };

  onBeforeUnmount(() => {
    store.commit('group/CLEAR_GROUP');
  });

  const cancelHandler = async () => {
    router.back();
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(TopbarOrganism, {
        linkText: "Back to groups",
        showIcon: true,
        icon: "angleLeft",
        secondaryButtonText: _unref(topbarSecondaryButtonText),
        primaryButtonText: _unref(topbarPrimaryButtonText),
        onSecondaryClick: _unref(topbarSecondaryButtonHandler),
        onPrimaryClick: _unref(topbarPrimaryButtonHandler)
      }, null, 8, ["secondaryButtonText", "primaryButtonText", "onSecondaryClick", "onPrimaryClick"]),
      (!loading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(GroupsFormOrganism, { initialDetails: group.value }, null, 8, ["initialDetails"]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "group-details-discussion" }, [
              _createElementVNode("h2", null, "Discussions")
            ], -1)),
            _createVNode(TableMolecule, { dataType: "GroupDiscussion" })
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, "Loading..."))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(CreateDiscussionAtom, {
        show: showCreateDiscussionModal.value,
        group_id: group.value?.group_id,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (_unref(store).commit('group/SHOW_DISCUSSION_MODAL', $event)))
      }, null, 8, ["show", "group_id"])
    ])
  ], 64))
}
}

})