import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-container" }

import SubscriptionsAnalyticsOrganism from '@/components/organisms/subscriptions-analytics/SubscriptionsAnalyticsOrganism.vue';
  import store from '@/store';
  import { onBeforeMount } from 'vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionsAnalytics',
  setup(__props) {

  onBeforeMount(() => {
    store.dispatch('analytics/fetchSubscriptionsAnalytics');
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SubscriptionsAnalyticsOrganism)
  ]))
}
}

})