import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

import GroupsFormMolecule from '@/components/molecules/groups-form-molecule/GroupsFormMolecule.vue';
  import { computed, nextTick, ref, watch } from 'vue';
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'GroupsFormOrganism',
  props: {
    initialDetails: {}
  },
  emits: ['update:groupDetails'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;

  const emit = __emit;

  let isUpdating = false;

  const store = useStore();

  const group = computed(() => store.getters['group/getGroup']);
  const localDetails = ref({ ...props.initialDetails });

  watch(
    group,
    (newValue) => {
      if (!isUpdating && newValue) {
        localDetails.value = { ...newValue };
      }
    },
    { immediate: true, deep: true },
  );

  const updateDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('group/SET_GROUP', updatedDetails);

    emit('update:groupDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(GroupsFormMolecule, {
      details: localDetails.value,
      onUpdateDetails: updateDetails
    }, null, 8, ["details"])
  ]))
}
}

})