import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "placeholder", "value", "type"]


  
export default /*@__PURE__*/_defineComponent({
  __name: 'InputAtom',
  props: {
    id: {},
    placeholder: {},
    type: { default: 'text' },
    modelValue: {}
  },
  setup(__props: any) {

  const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("input", {
    id: props.id,
    placeholder: props.placeholder,
    value: props.modelValue,
    type: props.type,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    class: "input"
  }, null, 40, _hoisted_1))
}
}

})