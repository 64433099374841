import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "demographics-analytics-grid" }
const _hoisted_2 = { class: "col-left" }
const _hoisted_3 = { class: "col-right" }

import PieChart from '../charts/PieChart.vue';
  import BarChart from '../charts/BarChart.vue';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { ChartData, ChartOptions } from 'chart.js';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'DemographicsAnalyticsOrganism',
  setup(__props) {

  const store = useStore();
  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

  const chartData = computed<ChartData<'pie'>>(() => {
    const gender = analyticsData.value.genderDemographics || [];

    const maleData = gender[0]?.data || 0;
    const femaleData = gender[1]?.data || 0;

    return {
      labels: [gender[0]?.label || 'Unknown', gender[1]?.label || 'Unknown'],
      datasets: [
        {
          data: [maleData, femaleData],
          backgroundColor: ['#1C3D92', '#7AD1DC'],
          borderColor: ['#1C3D92', '#7AD1DC'],
          borderWidth: 1,
        },
      ],
    };
  });

  const chartOptions: ChartOptions<'pie'> = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(AnalyticsCardOrganism, { title: "Age Demographics" }),
      _createVNode(AnalyticsCardOrganism, { title: "Top Countries" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(AnalyticsCardOrganism, {
        title: "Gender Demographics",
        class: "col-gender"
      }, {
        body: _withCtx(() => [
          _createVNode(PieChart, {
            chartData: chartData.value,
            chartOptions: chartOptions
          }, null, 8, ["chartData"])
        ]),
        _: 1
      }),
      _createVNode(AnalyticsCardOrganism, { title: "Children Age Demographics" }, {
        body: _withCtx(() => [
          (analyticsData.value?.kidsAgeDemographics)
            ? (_openBlock(), _createBlock(BarChart, {
                key: 0,
                data: analyticsData.value?.kidsAgeDemographics,
                "bar-thickness": 30,
                "bar-color": "#B471F8",
                orientation: "horizontal",
                "x-axis-title": "Amount"
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(AnalyticsCardOrganism, { title: "How many kids parents have" }, {
        body: _withCtx(() => [
          (analyticsData.value?.kidsNumberDemographics)
            ? (_openBlock(), _createBlock(BarChart, {
                key: 0,
                data: analyticsData.value?.kidsNumberDemographics,
                "bar-thickness": 30,
                "bar-color": "#87DFF2",
                orientation: "horizontal"
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})