import apiService from '../api.service';

class AnalyticsService {
  public async getUserAnalytics(
    startDate: string,
    endDate: string,
    dauDate?: string,
  ) {
    return apiService.get('/analytics/users', {
      params: { startDate, endDate, dauDate },
    });
  }

  public async getPerformanceAnalytics(startDate: string, endDate: string) {
    const response = await apiService.get('/analytics/performance', {
      params: { startDate, endDate },
    });
    return response;
  }

  public async getDemographicsAnalytics() {
    const response = await apiService.get('/analytics/demographics');
    return response;
  }

  public async getMostViewedResourceContent(
    startDate: string,
    endDate: string,
  ) {
    return apiService.get('/analytics/performance/resource-content', {
      params: { startDate, endDate },
    });
  }

  public async getFinancialAnalytics() {
    const response: any = await apiService.get('/analytics/financial');
    return response;
  }

  public async getFinancialAnalyticsForPeriod() {
    const response: any = await apiService.get('/analytics/financial/period');
    return response;
  }

  public async getGroupStats(startDate: string, endDate: string) {
    const response = await apiService.get('/analytics/performance/groups', {
      params: { startDate, endDate },
    });
    return response;
  }

  public async getDownloadCount() {
    const response = await apiService.get('/app-analytics/summary');

    return response;
  }

  public async getSubscriptionsAnalytics() {
    return apiService.get('/analytics/subscriptions');
  }

  public async getDownloadsForDateInterval(startDate: string, endDate: string) {
    return apiService.get('/app-analytics/downloads', {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }
}

export default new AnalyticsService();
