<script setup lang="ts">
  import { computed, ref } from 'vue';
  import ScheduleNotificationModal from '@/components/atoms/ScheduleNotificationModal.vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TabbarOrganism from '@/components/organisms/tabbar-organism/TabbarOrganism.vue';
  import { RouterView } from 'vue-router';
  import router from '@/router';

  const showAddNewTagModal = ref(false);
  const activeTab = ref('users');

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function updateActiveTab(tab: string) {
    activeTab.value = tab;
    router.push({ name: `${tab}-analytics` });
  }

  const showDatePicker = computed(() => {
    return !['technical', 'demographics'].includes(activeTab.value);
  });
</script>

<template>
  <div class="analytics-main">
    <TopbarOrganism title="Analytics" />
    <TabbarOrganism
      filter-field=""
      :buttons="[
        { text: 'Usage & Users', value: 'users' },
        { text: 'Subscriptions', value: 'subscriptions' },
        { text: 'Performance', value: 'performance' },
        // { text: 'Financials', value: 'financial' },
        { text: 'Technical', value: 'technical' },
        { text: 'Demographics', value: 'demographics' },
      ]"
      :model-value="{ page: 1, pageSize: 10 }"
      @update:active-tab="updateActiveTab"
      :show-date-picker="showDatePicker"
    />
    <RouterView />
    <ScheduleNotificationModal
      :show="showAddNewTagModal"
      @update:show="showAddNewTagModal = $event"
    />
  </div>
</template>

<style scoped>
  .analytics-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .analytics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    width: 90%;
    margin: 0 auto;
  }

  .link {
    margin: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .central-content {
    margin: auto;
  }
</style>
