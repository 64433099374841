import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

import NotificationFormMolecule from '@/components/molecules/notification-form-molecule/NotificationFormMolecule.vue';
  import { computed, nextTick, ref, watch } from 'vue';
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationFormOrganisam',
  props: {
    initialDetails: {}
  },
  emits: ['update:notificationDetails'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;

  const emit = __emit;

  let isUpdating = false;

  const store = useStore();

  const notification = computed(
    () => store.getters['notification/getNotification'],
  );
  const localDetails = ref({ ...props.initialDetails });

  watch(
    notification,
    (newValue) => {
      if (!isUpdating && newValue) {
        localDetails.value = { ...newValue };
      }
    },
    { immediate: true, deep: true },
  );

  const updateDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('notification/SET_NOTIFICATION', updatedDetails);

    emit('update:notificationDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NotificationFormMolecule, {
      details: localDetails.value,
      onUpdateDetails: updateDetails
    }, null, 8, ["details"])
  ]))
}
}

})