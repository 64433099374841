import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "article-wrapper" }
const _hoisted_2 = { class: "article-editor" }

import { ref, computed, watch, nextTick } from "vue";
import ArticleEditorMolecule from "@/components/molecules/article-editor-molecules/ArticleEditorMolecule.vue";
import ArticlePreviewMolecule from "@/components/molecules/article-editor-molecules/ArticlePreviewMolecule.vue";
import { useStore } from "vuex";
import TagEditorMolecule from "@/components/molecules/tag-editor-molecules/TagEditorMolecule.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TagEditorOrganism',
  props: {
    initialDetails: {}
  },
  emits: ["update:tagDetails"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();

const props = __props;

const details = computed(() => store.getters["tag/getTag"]);
const localDetails = ref({ ...props.initialDetails });

const emit = __emit;

let isUpdating = false;

watch(
  details,
  (newValue) => {
    if (!isUpdating && newValue) {
      localDetails.value = { ...newValue };
    }
  },
  { immediate: true, deep: true }
);

const updateDetails = (updatedDetails) => {
  if (isUpdating) return;
  isUpdating = true;

  store.commit("tag/SET_TAG", updatedDetails);

  emit("update:tagDetails", updatedDetails);

  nextTick(() => {
    isUpdating = false;
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TagEditorMolecule, {
        details: localDetails.value,
        onUpdateDetails: updateDetails
      }, null, 8, ["details"])
    ])
  ]))
}
}

})