<template>
  <div class="analytics-grid performance-analytics-grid">
    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.challengesPerformance?.checkins"
      name="Checkins initiated"
      icon="challenges-icon"
      class="col-basecard"
    />
    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.challengesPerformance?.challenges"
      name="Challenges completed"
      icon="challenges-icon"
      class="col-basecard"
    />
    <AnalyticsCardOrganism
      title="Most activity on a group"
      class="col-groupactivity"
    >
      <template #body>
        <GroupActivityTableMolecule />
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism
      title="Most viewed resource content"
      class="col-mostviewed"
    >
      <template #body>
        <BarChart
          v-if="analyticsData?.mostViewedResourceContent"
          :data="analyticsData?.mostViewedResourceContent"
          bar-color="#1D43A6"
          orientation="horizontal"
          x-axis-title="Views"
        />
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism title="Top resources in selected date range" class="col-full">
      <template #body>
        <div class="monthly-top-container">
          <MostViewedCardMolecule
            v-for="resource in analyticsData?.monthlyTopByType?.monthlyTop"
            :key="resource.resource_id"
            v-bind="resource"
          />
        </div>
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism
      v-if="analyticsData?.roiOverTime"
      title="ROI over time"
      class="col-full"
    />
  </div>
</template>

<script setup lang="ts">
  import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import store from '@/store';
  import { computed, watch } from 'vue';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';
  import MostViewedCardMolecule from '@/components/molecules/most-viewed-card/MostViewedCardMolecule.vue';
  import BarChart from '../charts/BarChart.vue';
  import GroupActivityTableMolecule from '@/components/molecules/group-activity-table/GroupActivityTableMolecule.vue';

  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

  watch(analyticsData, (val) => {
    console.log(val);
  });
</script>

<style scoped src="./style.css" />
