import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "personal-details" }
const _hoisted_2 = ["onClick"]

import IconAtom from '@/components/atoms/IconAtom.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import userImgSrc from '@/assets/user-default.png';
  import UserImgAtom from '@/components/atoms/UserImgAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'SignOutMolecule',
  emits: ['close', 'change-password'],
  setup(__props, { emit: __emit }) {

  const emit = __emit;
  const store = useStore();

  const user = computed(() => store.getters['user/getUser']);

  const actions = [
    { icon: 'lockIcon', label: 'Change password', type: 'changePassword' },
    { icon: 'signOutIcon', label: 'Sign out', type: 'signOut' },
  ];

  const onActionClick = (action: string): void => {
    if (action === 'signOut') {
      store.dispatch('user/signOut');
      emit('close');
    } else if (action === 'changePassword') {
      emit('change-password');
      emit('close');
    }
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "sign-out-action-container",
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(UserImgAtom, {
        class: "image-detail",
        src: _unref(userImgSrc)
      }, null, 8, ["src"]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "name-email-details" }, [
        _createElementVNode("p", { class: "name-detail" }),
        _createElementVNode("p", { class: "email-detail" })
      ], -1))
    ]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(actions, (action, index) => {
      return _createElementVNode("span", {
        key: index,
        class: "action",
        onClick: ($event: any) => (onActionClick(action.type))
      }, [
        _createVNode(IconAtom, {
          icon: action.icon,
          class: "action-icon",
          "aria-label": action.label
        }, null, 8, ["icon", "aria-label"]),
        _createTextVNode(" " + _toDisplayString(action.label), 1)
      ], 8, _hoisted_2)
    }), 64))
  ]))
}
}

})