import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from "vue";

// TinyMCE
import "tinymce";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver/theme";
import "tinymce/models/dom/model";
import "tinymce/skins/ui/oxide/skin.css";

// TinyMCE plugins
import "tinymce/plugins/lists/plugin";
import "tinymce/plugins/link/plugin";
import "tinymce/plugins/image/plugin";
import "tinymce/plugins/table/plugin";
import "tinymce/plugins/code/plugin";
import "tinymce/plugins/help/plugin";
import "tinymce/plugins/wordcount/plugin";

import Editor from "@tinymce/tinymce-vue";
import uploadService from "@/services/upload/upload.service";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'TinyEditor',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();

const props = __props;
const emit = __emit;

const content = ref(props.modelValue);

watch(content, (newValue) => {
  content.value = newValue;
  emit("update:modelValue", newValue);
});

const init = {
  license_key: "gpl",
  skin: false,
  plugins: "lists link image table code wordcount",
  content_css: false,
  toolbar: "h1 h2 h3 bold italic numlist bullist link blockquote image",
  menubar: false,
  statusbar: false,
  image_title: true,
  automatic_uploads: true,
  file_picker_types: "image",
  file_picker_callback: function (cb, value, meta) {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = async function (event: Event) {
      store.dispatch("loader/showLoader");
      const target = event.target as HTMLInputElement;
      if (!target.files) return;

      const file = target.files[0];

      // Create a FormData object and append the file
      var formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the file using uploadService.upload

        const response = await uploadService.upload(formData);

        // Assuming the response contains the uploaded image URL
        const imageUrl = response.data.link;

        // Insert the image into TinyMCE editor
        cb(imageUrl, { title: file.name });
      } catch (error) {
        console.error("Image upload failed:", error);
        store.dispatch("loader/hideLoader");
      }
      store.dispatch("loader/hideLoader");
    };

    input.click();
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Editor), {
    init: init,
    modelValue: content.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((content).value = $event)),
    onInput: _ctx.updateContent
  }, null, 8, ["modelValue", "onInput"]))
}
}

})