import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import TrendArrow from '@/assets/svg/TrendArrow.vue';

type Props = {
    trendValue: string;
    trendType: 'positive' | 'negative'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AnalyticsTrendMolecule',
  props: {
    trendValue: {},
    trendType: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["label-wrapper", {
        'trend-positive': _ctx.trendType === 'positive',
        'trend-negative': _ctx.trendType === 'negative'
    }])
  }, [
    _createVNode(TrendArrow, {
      class: _normalizeClass(["arrow", _ctx.trendType])
    }, null, 8, ["class"]),
    _createElementVNode("label", null, _toDisplayString(_ctx.trendValue), 1)
  ], 2))
}
}

})