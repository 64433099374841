import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-wrapper" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = {
  key: 0,
  class: "icon"
}
const _hoisted_4 = { class: "analytics-name" }
const _hoisted_5 = { class: "evaluation-period datepick" }
const _hoisted_6 = {
  key: 2,
  class: "evaluation-period"
}
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "analytics-value" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

import { computed, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import AnalyticsTrendMolecule from '../analytics-trend/AnalyticsTrendMolecule.vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAnalyticsCardMolecule',
  props: {
    count: { default: 0 },
    trend: {},
    name: { default: 'Analytics' },
    icon: {},
    prefix: {},
    suffix: {},
    customEvaluationPeriod: {},
    datePicker: { type: Boolean }
  },
  setup(__props: any) {

  const props = __props;

  const store = useStore();
  const dauDate = computed(() => store.getters['analytics/getDauDate']);

  watch(dauDate, (dauDateNew) => {
    store.commit('analytics/SET_DAU_DATE', dauDateNew);
    store.dispatch('analytics/fetchAnalyticsData');
  });

  const dateRange = computed(() => store.getters['analytics/getDateRange']);
  const defaultEvaluationPeriod = computed(() => {
    const startDate = new Date(dateRange.value.startDate).toLocaleDateString(
      'en-US',
      {
        month: 'short',
        day: 'numeric',
      },
    );
    const endDate = new Date(dateRange.value.endDate).toLocaleDateString(
      'en-US',
      {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      },
    );
    return `${startDate} - ${endDate}`;
  });

  const evaluationPeriod = computed(() => {
    return props.datePicker
      ? dauDate.value.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      : props.customEvaluationPeriod || defaultEvaluationPeriod.value;
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(IconAtom, {
              icon: _ctx.icon,
              color: "black"
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.name), 1)
      ]),
      (_ctx.datePicker)
        ? (_openBlock(), _createBlock(_unref(VueDatePicker), {
            key: 1,
            modelValue: dauDate.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dauDate).value = $event)),
            "max-date": new Date(),
            "auto-apply": "",
            range: false,
            clearable: false,
            "time-picker": false,
            "enable-time-picker": false,
            onDateUpdate: _cache[1] || (_cache[1] = 
          (e) => {
            _unref(store).commit('analytics/SET_DAU_DATE', e);
          }
        )
          }, {
            trigger: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(evaluationPeriod.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "max-date"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(evaluationPeriod.value), 1))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.prefix)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_9, _toDisplayString(_ctx.prefix), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h3", null, _toDisplayString(_ctx.count), 1),
        (_ctx.suffix)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_10, _toDisplayString(_ctx.suffix), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.trend)
        ? (_openBlock(), _createBlock(AnalyticsTrendMolecule, {
            key: 0,
            "trend-value": _ctx.trend + '%',
            "trend-type": _ctx.trend > 0 ? 'positive' : 'negative'
          }, null, 8, ["trend-value", "trend-type"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})