import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

type ButtonAtomProps = {
    class: string;
    disabled: boolean;
    onClick?: (event: MouseEvent) => void;
  };

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonAtom',
  props: {
    class: {},
    disabled: { type: Boolean, default: false },
    onClick: {}
  },
  setup(__props: any) {

  const props = __props;
  const handleClick = (event: MouseEvent) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    onClick: handleClick,
    class: _normalizeClass([props.class, { disabled: _ctx.disabled }]),
    disabled: props.disabled,
    s: ""
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})