<template>
  <div class="container">
    <h2 class="analytics-group-title">Number of users using coupon</h2>
    <div class="analytics-grid-2-cols">
      <AnalyticsCardOrganism
        v-for="coupon in analyticsData?.subscriptionsAnalytics?.usersPerCoupon
          ?.coupons"
        :title="coupon.couponCode"
        :key="coupon.couponCode"
        icon="analyticsUsers"
        class="analytics-card"
      >
        <template #body>
          <div class="app-analytics-body">
            <h3>
              {{ coupon.usersCount || 'n/a' }}
            </h3>
          </div>
        </template>
      </AnalyticsCardOrganism>
    </div>
  </div>
  <div class="container">
    <h2 class="analytics-group-title">Subscription details</h2>
    <div class="analytics-grid-2-cols">
      <AnalyticsCardOrganism
        title="Users in free trial"
        icon="analyticsUsers"
        class="analytics-card"
      >
        <template #body>
          <div class="app-analytics-body">
            <h3>
              {{ analyticsData?.subscriptionsAnalytics?.usersInTrial || 'n/a' }}
            </h3>
          </div>
        </template>
      </AnalyticsCardOrganism>
      <AnalyticsCardOrganism
        v-for="subscriptionPlan in analyticsData?.subscriptionsAnalytics
          ?.payingUsers?.subscriptionPlans"
        :title="subscriptionPlan.plan_id.replaceAll('_', ' ')"
        :key="subscriptionPlan.plan_id"
        icon="analyticsUsers"
        class="analytics-card"
      >
        <template #body>
          <div class="app-analytics-body">
            <h3>
              {{ subscriptionPlan.usersCount || 'n/a' }}
            </h3>
          </div>
        </template>
      </AnalyticsCardOrganism>
      <AnalyticsCardOrganism
        title="Total paying users"
        :key="analyticsData?.subscriptionsAnalytics?.payingUsers?.totalUsers"
        icon="analyticsUsers"
        class="analytics-card"
      >
        <template #body>
          <div class="app-analytics-body">
            <h3>
              {{
                analyticsData?.subscriptionsAnalytics?.payingUsers
                  ?.totalUsers || 'n/a'
              }}
            </h3>
          </div>
        </template>
      </AnalyticsCardOrganism>
    </div>
  </div>
</template>

<script setup lang="ts">
  import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';

  const store = useStore();
  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );
</script>

<style scoped src="./style.css" />
