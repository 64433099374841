import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import DemographicsAnalyticsOrganism from '@/components/organisms/demographics-analytics/DemographicsAnalyticsOrganism.vue';
  import store from '@/store';
  import { onBeforeMount } from 'vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'DemographicsAnalytics',
  setup(__props) {

  onBeforeMount(() => {
    store.dispatch('analytics/fetchDemographicsAnalytics');
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DemographicsAnalyticsOrganism))
}
}

})