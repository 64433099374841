import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import PerformanceAnalyticsOrganism from '@/components/organisms/performance-analytics/PerformanceAnalyticsOrganism.vue';
  import store from '@/store';
  import { computed, onBeforeMount, watch } from 'vue';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'PerformanceAnalytics',
  setup(__props) {

  onBeforeMount(() => {
    store.dispatch('analytics/fetchPerformanceAnalytics');
  });

  const dateRange = computed(() => store.getters['analytics/getDateRange']);
  watch(dateRange.value, () => {
    store.dispatch('analytics/fetchPerformanceAnalytics');
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PerformanceAnalyticsOrganism))
}
}

})