import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import store from '@/store';
  import { computed } from 'vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'GroupActivityTableMolecule',
  setup(__props) {

  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", null, [
    _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th", null, "Groups"),
        _createElementVNode("th", null, "Views")
      ])
    ], -1)),
    (analyticsData.value?.mostActiveGroups)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(analyticsData.value.mostActiveGroups, (dataItem) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: dataItem.data
            }, [
              _createElementVNode("td", null, _toDisplayString(dataItem.label || '-'), 1),
              _createElementVNode("td", null, _toDisplayString(dataItem.data), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})