import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "collection-items-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "name-and-img" }
const _hoisted_4 = ["src"]

import {
    onMounted,
    onUnmounted,
    watch,
    computed,
  } from 'vue';
  import SearchbarMolecule from '../molecules/searchbar-molecule/SearchbarMolecule.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import Collection from '@/models/collection/collection.model';
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'AddToCollectionAtom',
  props: {
    id: {},
    show: { type: Boolean },
    collections: {},
    actionCollectionsGetter: {},
    actionCollectionSet: {},
    actionCollectionRemove: {}
  },
  emits: ['update:show', 'updateCollections'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;

  const emit = __emit;
  const store = useStore();

  const selectedCollections = computed(
    () => store.getters[props.actionCollectionsGetter],
  );
  const rawSelectedCollections = computed(() => [...selectedCollections.value]);

  watch(
    () => props.show,
    (newShow) => {
      if (newShow) {
        // selectedCollections.value = [];
      }
    },
  );

  const handleCollectionClick = (collection: Collection) => {
    const selected = selectedCollections.value || []; // Ensure it's an array
    if (selected.includes(collection)) {
      store.dispatch(props.actionCollectionRemove, collection);
    } else {
      store.dispatch(props.actionCollectionSet, collection);
    }
  };

  const closeModal = () => {
    emit('update:show', false);
  };

  const addCollections = () => {
    // emit("updateCollections", selectedCollections.value);
    closeModal();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "collection-modal-overlay",
        onClick: closeModal
      }, [
        _createElementVNode("div", {
          class: "collection-modal",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Add to Collection", -1)),
          _createVNode(IconAtom, {
            class: "close-icon",
            icon: "closeIcon",
            onClick: closeModal
          }),
          _createVNode(SearchbarMolecule, {
            class: "border searchbar",
            showFilter: false,
            showSort: false
          }),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.collections, (collection) => {
              return (_openBlock(), _createElementBlock("div", {
                key: collection.collection_id,
                class: _normalizeClass(["collection-item", {
            selected: rawSelectedCollections.value
              .map((c) => c.collection_id)
              .includes(collection.collection_id),
          }]),
                onClick: ($event: any) => (handleCollectionClick(collection))
              }, [
                _createElementVNode("div", _hoisted_3, [
                  (collection.banner)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: collection.banner,
                        alt: "Collection Image",
                        class: "collection-icon"
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(collection.collection_name), 1)
                ]),
                (
              rawSelectedCollections.value
                .map((c) => c.collection_id)
                .includes(collection.collection_id)
            )
                  ? (_openBlock(), _createBlock(IconAtom, {
                      key: 0,
                      icon: "checkmarkIcon"
                    }))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_2))
            }), 128))
          ]),
          _createVNode(ButtonAtom, {
            class: "btn btn-primary add-btn",
            onClick: addCollections
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("p", null, "Add", -1)
            ])),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})