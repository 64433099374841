import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch-container" }

import { ref, computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SwitchAtom',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isOn = ref(props.modelValue);

const computedClass = computed(() => ({
  on: isOn.value,
}));

function toggle() {
  isOn.value = !isOn.value;
  emit("update:modelValue", isOn.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h4", null, "Feature collection", -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["switch", computedClass.value]),
      onClick: toggle
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "toggle" }, null, -1)
    ]), 2)
  ]))
}
}

})