import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "analytics-name" }
const _hoisted_4 = { class: "header-subtitle" }


export default /*@__PURE__*/_defineComponent({
  __name: 'AnalyticsCardOrganism',
  props: {
    title: {},
    columnSpan: { default: 1 },
    headerSubtitle: {}
  },
  setup(__props: any) {

  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.headerSubtitle), 1)
    ]),
    _renderSlot(_ctx.$slots, "subheader", { class: "subheader" }),
    _renderSlot(_ctx.$slots, "body", { class: "body" })
  ]))
}
}

})