import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-grid" }

import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import AnalyticsCardOrganism from '@/components/organisms/analytics-card/AnalyticsCardOrganism.vue';
  import BarChart from '@/components/organisms/charts/BarChart.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'TechnicalAnalytics',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseAnalyticsCardMolecule, {
      count: 1000,
      suffix: "ms",
      name: "Load time"
    }),
    _createVNode(BaseAnalyticsCardMolecule, {
      count: 0,
      name: "Error incidents"
    }),
    _createVNode(AnalyticsCardOrganism, {
      title: "Error Rate",
      class: "col-2"
    }, {
      body: _withCtx(() => [
        _createVNode(BarChart, { data: [
            { label: 'May', data: 0 },
            { label: 'Jun', data: 0 },
            { label: 'Jul', data: 0 },
            { label: 'Aug', data: 0 },
            { label: 'Sep', data: 0 },
            { label: 'Oct', data: 0 },
          ] })
      ]),
      _: 1
    })
  ]))
}
}

})