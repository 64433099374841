import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "video-preview-molecule" }
const _hoisted_2 = { class: "preview-header" }
const _hoisted_3 = {
  key: 0,
  controls: ""
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "preview-content" }
const _hoisted_7 = { class: "preview-h1" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "tags-container" }

import { onMounted, ref, watch } from 'vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'VideoPreviewMolecule',
  props: {
    previewData: {},
    videoFile: {}
  },
  setup(__props: any) {

  const props = __props;

  const localPreviewData = ref({ ...props.previewData });

  onMounted(() => {
    localPreviewData.value = props.previewData;
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "preview-title" }, "Preview", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (props.videoFile)
          ? (_openBlock(), _createElementBlock("video", _hoisted_3, [
              _createElementVNode("source", {
                src: props.videoFile,
                type: "video/mp4"
              }, null, 8, _hoisted_4),
              _createElementVNode("source", {
                src: props.videoFile,
                type: "video/quicktime"
              }, null, 8, _hoisted_5),
              _cache[0] || (_cache[0] = _createTextVNode(" Your browser does not support the video element. "))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h1", _hoisted_7, _toDisplayString(props.previewData.resource_name), 1),
        _createElementVNode("div", {
          innerHTML: props.previewData.text_content
        }, null, 8, _hoisted_8),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.previewData.tags, (tag) => {
            return (_openBlock(), _createBlock(ButtonAtom, {
              key: tag.id,
              class: "btn btn-secondary tag-button"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tag.value), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})