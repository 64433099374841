import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "article-preview-molecule" }
const _hoisted_2 = { class: "tags-container" }
const _hoisted_3 = ["innerHTML"]

import { onMounted, ref, watch } from 'vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ArticlePreviewMolecule',
  props: {
    previewData: {},
    coverImage: {}
  },
  setup(__props: any) {

  const props = __props;

  const localPreviewData = ref({ ...props.previewData });

  onMounted(() => {
    localPreviewData.value = props.previewData;
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "preview-title" }, "Preview", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "preview-header",
        style: _normalizeStyle({
          backgroundImage: `url(${props.coverImage})`,
        })
      }, [
        _createElementVNode("h1", null, _toDisplayString(props.previewData.resource_name), 1),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.previewData.tags, (tag) => {
            return (_openBlock(), _createBlock(ButtonAtom, {
              key: tag.id,
              class: "btn btn-secondary tag-button"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tag.value), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ])
      ], 4),
      _createElementVNode("div", {
        class: "preview-content",
        innerHTML: props.previewData.text_content
      }, null, 8, _hoisted_3)
    ])
  ]))
}
}

})