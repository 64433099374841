import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "buttons" }

import { ref, onMounted, onUnmounted } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import SendNotificationModal from './SendNotificationModal.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'PushNotificationsModal',
  props: {
    show: Boolean,
  },
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const showSendNotification = ref(false);

  const emits = __emit;

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  const handleClick = () => {
    showSendNotification.value = true;
    closeModal();
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "push-notifications-modal-overlay",
          onClick: closeModal
        }, [
          _createElementVNode("div", {
            class: "push-notifications-modal",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "push-notifications-modal-title" }, "Personal Push Notification", -1)),
            _createVNode(IconAtom, {
              class: "close-icon",
              icon: "closeIcon",
              onClick: closeModal
            }),
            _createElementVNode("div", _hoisted_1, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "table" }, null, -1)),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(ButtonAtom, {
                  class: "btn btn-secondary",
                  onClick: closeModal
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                }),
                _createVNode(ButtonAtom, {
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (handleClick()))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Send personal notification")
                  ])),
                  _: 1
                })
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(SendNotificationModal, {
      show: showSendNotification.value,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (showSendNotification.value = $event))
    }, null, 8, ["show"])
  ], 64))
}
}

})