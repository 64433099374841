import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import CollectionsFormOrganism from '@/components/organisms/collections-form-organism/CollectionsFormOrganism.vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Collection from '@/models/collection/collection.model';
  import { AdditionalParams } from '@/shared/filterParams';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import ResourceModalMolecule from '@/components/molecules/resouce-modal-molecule/ResourceModalMolecule.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CollectionsDetailsView',
  setup(__props) {

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const collection = computed(() => store.getters['collection/getCollection']);
  const collection_id = ref('');
  const tableKey = ref(0);

  const loading = ref(true);
  const showResourcesModal = ref(false);

  let topbarPrimaryButtonText = '';
  let topbarSecondaryButtonText = '';
  let topbarPrimaryButtonHandler;
  let topbarSecondaryButtonHandler;

  onMounted(() => {
    if (route.query.id) {
      collection_id.value = route.query.id.toString();
      store
        .dispatch('collection/fetchItemDetails', collection_id.value)
        .then(() => {
          loading.value = false;
        });

      topbarPrimaryButtonText = 'Save';
      topbarSecondaryButtonText = 'Cancel';
      topbarSecondaryButtonHandler = cancelHandler;
      topbarPrimaryButtonHandler = publish;
    } else {
      store.commit('collection/SET_COLLECTION', new Collection({}));
      loading.value = false;
      loading.value = false;
      topbarPrimaryButtonText = 'Publish';
      topbarSecondaryButtonText = 'Save to draft';
      topbarPrimaryButtonHandler = publish;
      topbarSecondaryButtonHandler = submitToDraft;
    }
  });

  const openResourcesModal = () => {
    showResourcesModal.value = true;
  };

  const closeResourcesModal = () => {
    showResourcesModal.value = false;
    store.commit('resource/CLEAR_NOT_CONNECTED');
    tableKey.value += 1;
  };

  onBeforeUnmount(() => {
    store.commit('collection/CLEAR_COLLECTION');
    store.commit('resource/CLEAR_CONNECTED_RESOURCE');
    store.commit('resource/CLEAR_NOT_CONNECTED');
  });

  const cancelHandler = async () => {
    router.back();
  };

  const submitToDraft = async () => {
    store.commit('collection/SET_COLLECTION', {
      ...collection.value,
      status: 'draft',
      is_published: false,
    });
    await nextTick();
    store.dispatch('collection/createItem', collection.value).then(() => {
      router.back();
    });
  };

  const publish = async () => {
    store.commit('collection/SET_COLLECTION', {
      ...collection.value,
      status: 'published',
      is_published: true,
    });
    await nextTick();
    store.dispatch('collection/createItem', collection.value).then(() => {
      router.back();
    });
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(TopbarOrganism, {
        linkText: "Back to collections",
        showIcon: true,
        icon: "angleLeft",
        secondaryButtonText: _unref(topbarSecondaryButtonText),
        primaryButtonText: _unref(topbarPrimaryButtonText),
        onSecondaryClick: _unref(topbarSecondaryButtonHandler),
        onPrimaryClick: _unref(topbarPrimaryButtonHandler)
      }, null, 8, ["secondaryButtonText", "primaryButtonText", "onSecondaryClick", "onPrimaryClick"]),
      (!loading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(CollectionsFormOrganism, { initialDetails: collection.value }, null, 8, ["initialDetails"]),
            (_openBlock(), _createBlock(TableMolecule, {
              dataType: "ConnectedResources",
              key: tableKey.value,
              dynamicAdditionalFilter: 
          new (_unref(AdditionalParams))(
            'collections',
            'collection_id',
            collection_id.value,
            'some',
          )
        ,
              onActionClick: openResourcesModal
            }, null, 8, ["dynamicAdditionalFilter"]))
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, "Loading..."))
    ]),
    (showResourcesModal.value)
      ? (_openBlock(), _createBlock(ResourceModalMolecule, {
          key: 0,
          onClick: closeResourcesModal
        }, {
          default: _withCtx(() => [
            _createVNode(TableMolecule, {
              dataType: "NotConnectedResources",
              dynamicAdditionalFilter: 
        new (_unref(AdditionalParams))(
          'collections',
          'collection_id',
          collection_id.value,
          'none',
        )
      
            }, null, 8, ["dynamicAdditionalFilter"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})