import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, unref as _unref, vShow as _vShow, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "dropdown-container" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = {
  key: 'everyone',
  class: "option-item"
}
const _hoisted_4 = { class: "checkbox-label" }
const _hoisted_5 = { class: "checkbox-label" }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "user-info-wrapper" }
const _hoisted_9 = { class: "full-name" }

import { ref, computed, watch, onMounted, useTemplateRef } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import { useStore } from 'vuex';
  import userDefaultImage from '@/assets/default-user-img.png';
  import { FilterParams } from '@/shared/filterParams';
  import User from '@/models/user/user.model';
  import { useInfiniteScroll } from '@vueuse/core';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownAtom',
  props: {
    modelValue: Array,
  },
  setup(__props) {

  const isModalOpen = ref(false);
  const searchQuery = ref('');
  const isEveryoneSelected = ref(false);
  const selectedOptions = ref<User[]>([]);
  const optionsListRef = useTemplateRef<HTMLElement>('optionsListRef');

  const store = useStore();
  const props = __props;

  const initialFilterParams: FilterParams = {
    page: 1,
    pageSize: 10,
    searchParamsOR: { searchTerm: '' },
    searchParamsAND: [
      { searchTerm: 'active', searchField: 'user_status', action: 'equals' },
    ],
  };
  const resetFilters = () => (filters.value = { ...initialFilterParams });
  const filters = ref<FilterParams>({ ...initialFilterParams });

  const users = computed(() => store.getters['user/getItems']);
  const notification = computed(
    () => store.getters['notification/getNotification'],
  );
  const totalPages = computed(() => store.getters['user/getTotalPages']);

  const initializeSelectedOptions = (sendTo = props.modelValue) => {
    if (sendTo && typeof sendTo[0] === 'object' && sendTo[0].user_id) {
      sendTo = sendTo.map((item) => item.user_id);
    }

    if (sendTo?.includes('Everyone')) {
      isEveryoneSelected.value = true;
      selectedOptions.value = [];
    } else {
      isEveryoneSelected.value = false;
      selectedOptions.value = users.value.filter((user) =>
        sendTo?.includes(user.user_id),
      );
    }
  };

  onMounted(() => {
    store.dispatch('user/fetchUsers', filters.value);
    initializeSelectedOptions();
  });

  watch(
    () => props.modelValue,
    (newSendTo) => {
      initializeSelectedOptions(newSendTo);
    },
    { immediate: true },
  );

  watch(searchQuery, (newValue) => {
    resetFilters();
    filters.value.searchParamsOR.searchTerm = newValue;
    store.dispatch('user/fetchUsers', filters.value);
  });

  const selectedOption = computed(() => {
    if (isEveryoneSelected.value) {
      return 'Everyone';
    }
    if (selectedOptions.value.length === 1) {
      return selectedOptions.value[0].full_name;
    }
    return `${selectedOptions.value.length} selected`;
  });

  const toggleModal = () => {
    isModalOpen.value = !isModalOpen.value;
  };

  const closeModal = () => {
    isModalOpen.value = false;
  };

  const onEveryoneChange = () => {
    if (isEveryoneSelected.value) {
      store.commit('notification/SET_NOTIFICATION', {
        ...notification.value,
        send_to_all: true,
        send_to: [],
      });
      selectedOptions.value = [];
    }
  };

  const onUserOptionChange = () => {
    if (selectedOptions.value.length > 0) {
      const userIds = selectedOptions.value.map((option) => option.user_id);
      store.commit('notification/SET_NOTIFICATION', {
        ...notification.value,
        send_to_all: false,
        send_to: userIds,
      });
      isEveryoneSelected.value = false;
    }
  };

  const { reset } = useInfiniteScroll(
    optionsListRef,
    () => {
      console.log('Load more triggered');
      filters.value.page += 1;
      store.dispatch('user/fetchMoreUsers', filters.value);
    },
    {
      distance: 5,
      canLoadMore: () => filters.value.page < totalPages.value,
    },
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dropdown",
      onClick: toggleModal
    }, [
      _createElementVNode("span", null, _toDisplayString(selectedOption.value), 1),
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "arrow" }, "▾", -1))
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "modal-overlay",
      onClick: closeModal
    }, [
      _createElementVNode("div", {
        class: "modal-content",
        onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(IconAtom, {
            icon: "searchIcon",
            color: "#333",
            class: "search-icon"
          }),
          _createVNode(InputAtom, {
            class: "search-input",
            placeholder: "Search...",
            modelValue: searchQuery.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("ul", {
          class: "options-list",
          ref_key: "optionsListRef",
          ref: optionsListRef
        }, [
          (_openBlock(), _createElementBlock("li", _hoisted_3, [
            _createElementVNode("label", null, [
              _createElementVNode("label", _hoisted_4, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "checkbox-input",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isEveryoneSelected).value = $event)),
                  onChange: onEveryoneChange
                }, null, 544), [
                  [_vModelCheckbox, isEveryoneSelected.value]
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "custom-checkbox" }, null, -1)),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "checkbox-label" }, null, -1))
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("span", null, _toDisplayString('Everyone'), -1))
            ])
          ])),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (option) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.user_id,
              class: "option-item"
            }, [
              _createElementVNode("label", null, [
                _createElementVNode("label", _hoisted_5, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "checkbox-input",
                    value: option,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedOptions).value = $event)),
                    onChange: onUserOptionChange
                  }, null, 40, _hoisted_6), [
                    [_vModelCheckbox, selectedOptions.value]
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "custom-checkbox" }, null, -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("span", { class: "checkbox-label" }, null, -1))
                ]),
                _createElementVNode("img", {
                  src: option.user_photo ? option.user_photo : _unref(userDefaultImage),
                  alt: "",
                  class: "avatar"
                }, null, 8, _hoisted_7),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(option.full_name), 1),
                  _withDirectives(_createElementVNode("span", { class: "username" }, " @" + _toDisplayString(option.username), 513), [
                    [_vShow, option.username]
                  ])
                ])
              ])
            ]))
          }), 128))
        ], 512)
      ])
    ], 512), [
      [_vShow, isModalOpen.value]
    ])
  ]))
}
}

})