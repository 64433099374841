import ResourceService from '@/services/resource/resource.service';
import Resource from '@/models/resource/resource.model';
import { FilterParams, SearchParamsAND } from '@/shared/filterParams';
import { transformToSelectOptions } from '@/shared/selectOption';

const resourceModule = {
  namespaced: true,
  state: {
    resource: null,
    counts: { all: 0, draft: 0, published: 0, unpublished: 0 },
    items: [], // Add an items state to store rowsData
    totalPages: 1, // Add a totalPages state to store pagination data
    resource_tags: [],
    resource_collections: [],

    conn_resources: [],
    conn_resources_counts: { all: 0, audio: 0, video: 0, article: 0 },
    conn_resources_totalPages: 1,

    not_conn_resources: [],
    not_conn_resources_counts: { all: 0, audio: 0, video: 0, article: 0 },
    not_conn_resources_totalPages: 1,
    metadata: {},
  },
  mutations: {
    SET_RESOURCE(state, resource) {
      state.resource = resource;
      if (!state.resource_tags || state.resource_tags.length < 1) {
        state.resource_tags =
          transformToSelectOptions(
            resource.tags?.map((tag) => tag.tag),
            'tag_id',
            'tag_name',
          ) || [];
      }
      if (
        !state.resource_collections ||
        state.resource_collections.length < 1
      ) {
        state.resource_collections =
          resource.collections?.map((collection) => collection.collection) ||
          [];
      }
    },
    SET_ITEMS(state, data) {
      state.items = data.data;
      state.totalPages = data.totalPages;
      state.counts = { ...data.counts.status };
    },
    SET_CONNECTED_RESOURCE(state, data) {
      state.conn_resources = data.data;
      state.conn_resources_totalPages = data.totalPages;
      state.conn_resources_counts = { ...data.counts.resource_type };
    },

    SET_NOT_CONNECTED(state, data) {
      state.not_conn_resources = data.data;
      state.not_conn_resources_totalPages = data.totalPages;
      state.not_conn_resources_counts = { ...data.counts.resource_type };
    },
    SET_RESOURCE_TAGS(state, resource_tags) {
      state.resource_tags = resource_tags;
    },
    ADD_TAG(state, tag) {
      state.resource_tags.push(tag);
    },
    REMOVE_TAG(state, tag) {
      state.resource_tags = state.resource_tags.filter((t) => t.id !== tag.id);
    },
    SET_RESOURCE_COLLECTIONS(state, resource_collections) {
      state.resource_collections = resource_collections;
    },
    ADD_COLLECTION(state, collection) {
      state.resource_collections.push(collection);
      state.resource_collections = [...state.resource_collections];
    },
    REMOVE_COLLECTION(state, collection) {
      state.resource_collections = state.resource_collections.filter(
        (t) => t.collection_id !== collection.collection_id,
      );
    },
    CLEAR_RESOURCE(state) {
      state.resource = null;
      state.resource_collections = [];
      state.resource_tags = [];
    },

    CLEAR_CONNECTED_RESOURCE(state) {
      state.conn_resources = [];
      state.conn_resources_counts = { all: 0, audio: 0, video: 0, article: 0 };
      state.conn_resources_totalPages = 1;
    },

    CLEAR_NOT_CONNECTED(state) {
      state.not_conn_resources = [];
      state.not_conn_resources_counts = {
        all: 0,
        audio: 0,
        video: 0,
        article: 0,
      };
      state.not_conn_resources_totalPages = 1;
    },
    SET_METADATA(state, payload) {
      state.metadata = payload;
    },
  },
  actions: {
    async fetchResources({ commit }, params: FilterParams) {
      const data = await ResourceService.getAll(params);
      commit('SET_ITEMS', data);
    },

    async fetchConnectedResources(
      { commit, dispatch },
      payload: { params: FilterParams; notConnected: boolean },
    ) {
      try {
        const data = await ResourceService.getConnected(payload.params);
        if (payload.notConnected) {
          commit('SET_NOT_CONNECTED', data);
        } else {
          commit('SET_CONNECTED_RESOURCE', data);
        }
      } catch (error) {
        console.error('Error fatiching resources', error);
      }
    },

    async fetchItemDetails({ commit }, id: string) {
      const details: any = await ResourceService.getById(id);
      commit('SET_RESOURCE', details.data);
    },

    async disconnectResources({ dispatch }, payload) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await ResourceService.disconnect(payload);
      } catch (error) {
        console.error('Error deleting resources', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },

    async connectResources({ dispatch }, payload) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await ResourceService.connect(payload);
      } catch (error) {
        console.error('Error deleting resources', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },

    async createItem({ state, dispatch }, payload: Resource) {
      dispatch('loader/showLoader', null, { root: true });
      const formData = new FormData();

      // Append string fields
      formData.append('resource_name', payload.resource_name); // String
      formData.append('resource_type', payload.resource_type); // String
      formData.append('text_content', payload.text_content); // String
      formData.append('status', payload.status); // Enum as string

      // Convert and append boolean fields as strings
      formData.append('is_published', payload.is_published.toString()); // Boolean as string ("true"/"false")
      formData.append(
        'use_to_train_chatbot',
        payload.use_to_train_chatbot.toString(),
      ); // Boolean as string ("true"/"false")

      // Append banner file if it exists
      if (payload.banner && typeof payload.banner === 'object') {
        const file = payload.banner as any;
        const fileName = file.name.replace(/\s+/g, '_'); // Replace whitespaces with underscores
        formData.append('files', file, fileName);
      } else if (payload.banner != '') {
        formData.append('banner', payload.banner);
      }

      if (payload.url_path && typeof payload.url_path === 'object') {
        const file = payload.url_path as any;
        const fileName = file.name.replace(/\s+/g, '_');

        if (file.type.startsWith('audio/')) {
          formData.append('files', file, fileName);
        } else if (file.type.startsWith('video/')) {
          formData.append('files', file, fileName);
        } else {
          console.error('Unsupported file type in url_path:', file.type);
          dispatch('loader/hideLoader', null, { root: true });
          return;
        }
      } else if (payload.url_path != '') {
        formData.append('url_path', payload.url_path);
      }

      if (state.resource_collections.length > 0) {
        const collectionIds = state.resource_collections.map(
          (collection) => collection.collection_id,
        );
        formData.append('collection_ids', collectionIds.join(','));
      }

      if (state.resource_tags.length > 0) {
        const tagIds = state.resource_tags.map((tag) => tag.id);
        formData.append('tag_ids', tagIds.join(','));
      }

      if (state.metadata?.duration) {
        formData.append('metadata', state.metadata);
      }

      try {
        if (payload.resource_id) {
          await ResourceService.update(payload.resource_id, formData);
          if (state.metadata?.duration) {
            await ResourceService.syncMetadata(
              payload.resource_id,
              state.metadata,
            );
          }
        } else {
          await ResourceService.create(formData);
        }
      } catch (error) {
        console.error('Error creating resource', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async deleteItems({ dispatch }, payload: { data: string[] }) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await ResourceService.deleteMultiple({ resource_ids: payload.data });
      } catch (error) {
        console.error('Error deleting resources', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async updateItem({ dispatch }, payload: Resource) {
      await ResourceService.update(payload.resource_id, payload);
      dispatch('fetchItemDetails', payload.resource_id);
    },
    async publishItem(
      { dispatch },
      data: { id: string; filters: FilterParams },
    ) {
      const filters = structuredClone(data.filters);
      await ResourceService.publish(data.id);
      filters.searchParamsAND?.push(
        new SearchParamsAND('draft', 'status', 'equals'),
      );
      dispatch('fetchResources', filters);
    },
    async restoreItem(
      { dispatch },
      data: { id: string; filters: FilterParams },
    ) {
      const filters = structuredClone(data.filters);
      await ResourceService.restore(data.id);
      filters.searchParamsAND?.push(
        new SearchParamsAND('unpublished', 'status', 'equals'),
      );
      dispatch('fetchResources', filters);
    },
    addTag({ commit }, tag) {
      commit('ADD_TAG', tag);
    },
    removeTag({ commit }, tag) {
      commit('REMOVE_TAG', tag);
    },
    addCollection({ commit }, collection) {
      commit('ADD_COLLECTION', collection);
    },
    removeCollection({ commit }, collection) {
      commit('REMOVE_COLLECTION', collection);
    },
    // Other actions...
  },
  getters: {
    getResourceCounts(state) {
      return state.counts;
    },
    getResourceTags(state) {
      return state.resource_tags;
    },
    getResourceCollections(state) {
      return state.resource_collections;
    },
    getResource(state) {
      return state.resource;
    },
    getItems(state) {
      return state.items;
    },
    getTotalPages(state) {
      return state.totalPages;
    },

    getConnectedResources(state) {
      return state.conn_resources;
    },
    getConnectedResourcesTotalPages(state) {
      return state.conn_resources_totalPages;
    },

    getConnectedResourceCounts(state) {
      return state.conn_resources_counts;
    },

    getNotConnectedResources(state) {
      return state.not_conn_resources;
    },
    getNotConnectedResourcesTotalPages(state) {
      return state.not_conn_resources_totalPages;
    },

    getNotConnectedResourceCounts(state) {
      return state.not_conn_resources_counts;
    },
  },
};

export default resourceModule;
