<script setup lang="ts">
  import { onMounted, onUnmounted, computed, ref } from 'vue';
  import commentsIcon from '@/assets/svg/commentsIcon.vue';
  import { defineProps, defineEmits } from 'vue';
  import { SearchParamsAND } from '@/shared/filterParams';
  import TableMolecule from '../table-molecule/TableMolecule.vue';
  import { useStore } from 'vuex';
  import IconAtom from '@/components/atoms/IconAtom.vue';

  const props = defineProps<{
    show: boolean;
    resource_id: string;
    type?: 'resource' | 'GroupDiscussion' | 'parent_comment';
  }>();
  const emit = defineEmits(['update:show', 'currentIdChange']);
  const store = useStore();

  const currentId = ref(props.resource_id || '');
  const currentType = ref(props.type || 'resource');
  const commentHistory = ref<Array<{ id: string; type: string }>>([]);

  const defaultFilters = computed(() => {
    try {
      let filterField;
      switch (currentType.value) {
        case 'GroupDiscussion':
          filterField = 'discussion_id';
          break;
        case 'parent_comment':
          filterField = 'parent_comment_id';
          break;
        default:
          filterField = 'resource_id';
      }
      return [new SearchParamsAND(currentId.value, filterField, 'equals')];
    } catch (error) {
      console.error('Error creating filters:', error);
      return [];
    }
  });

  const comments = computed(() => {
    try {
      return store.getters['comment/getCount'];
    } catch (error) {
      console.error('Error getting comment count:', error);
      return 0;
    }
  });

  const closeModal = () => {
    try {
      emit('update:show', false);
      currentId.value = props.resource_id || '';
      currentType.value = props.type || 'resource';
      commentHistory.value = [];
    } catch (error) {
      console.error('Error closing modal:', error);
    }
  };

  const navigateToParent = (parentId: string) => {
    if (!parentId) {
      console.error('Invalid parent ID');
      return;
    }

    try {
      commentHistory.value.push({
        id: currentId.value,
        type: currentType.value,
      });

      currentId.value = parentId;
      currentType.value = 'parent_comment';

      store.commit('comment/CLEAR_ITEMS');
      emit('currentIdChange', parentId);
    } catch (error) {
      console.error('Error navigating to parent:', error);
      commentHistory.value.pop();
    }
  };

  const goBack = () => {
    try {
      if (commentHistory.value.length > 0) {
        const previousState = commentHistory.value.pop();
        if (previousState) {
          currentId.value = previousState.id;
          currentType.value = previousState.type;
          store.commit('comment/CLEAR_ITEMS');
        }
      }
    } catch (error) {
      console.error('Error navigating back:', error);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div v-show="props.show" class="comments-overlay" @click="closeModal">
    <div
      class="comments-content"
      :class="{
        'no-comments': comments,
        'with-comments': comments,
      }"
      @click.stop
    >
      <div class="comments-header">
        <button
          v-if="commentHistory.length > 0"
          class="back-button"
          @click="goBack"
        >
          <IconAtom :icon="'angleLeft'" color="black" />
        </button>
        <span class="comments-title">
          {{ currentType === 'parent_comment' ? 'Replies' : 'Comments' }}
        </span>
      </div>
      <div v-show="comments" class="comments-modal-content">
        <TableMolecule
          dataType="Comment"
          :dynamicDefaultFilter="defaultFilters"
          @navigateToParent="navigateToParent"
          :type="currentType"
          :currentId="currentId"
        />
      </div>
      <div v-show="!comments" class="status">
        <div class="icon-wrapper">
          <commentsIcon class="no-comments-icon" />
        </div>
        <h4>This post has no comments yet</h4>
      </div>
    </div>
  </div>
</template>

<style scoped src="./ViewCommentsMolecule.css"></style>
