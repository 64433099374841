import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'TagsListView',
  setup(__props) {

  const buttons = [{ text: 'All', count: 0 }];

  const detailsButtonprops = {
    route: '/tagsDetails',
    buttonClass: 'btn btn-primary',
    icon: 'plusIcon',
    iconColor: 'white',
    buttonText: 'New Tag',
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(TopbarOrganism, { title: "Tags" }),
    _createVNode(TableMolecule, { dataType: "Tag" })
  ]))
}
}

})