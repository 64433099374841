import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tags-detail" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TagEditorOrganism from '@/components/organisms/tag-editor-organism/TagEditorOrganism.vue';
  import { useStore } from 'vuex';
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import Tag from '@/models/tag/tag.model';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import { AdditionalParams } from '@/shared/filterParams';
  import ResourceModalMolecule from '@/components/molecules/resouce-modal-molecule/ResourceModalMolecule.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'TagsDetailsView',
  setup(__props) {

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const tag = computed(() => store.getters['tag/getTag']);

  const loading = ref(true);
  const showResourcesModal = ref(false);
  const tableKey = ref(0);
  const tag_id = ref('');

  let primaryButtonText;
  let primaryButtonHandler;

  onMounted(() => {
    if (route.query.id) {
      tag_id.value = route.query.id.toString();
      store.dispatch('tag/fetchItemDetails', tag_id.value).then(() => {
        loading.value = false;
      });

      primaryButtonText = 'Save';
    } else {
      store.commit('tag/SET_TAG', new Tag({}));
      loading.value = false;
      primaryButtonText = 'Publish';
    }
    primaryButtonHandler = publish;
  });

  const publish = async () => {
    store.dispatch('tag/createItem', tag.value).then(() => {
      router.back();
    });
  };

  const openResourcesModal = () => {
    showResourcesModal.value = true;
  };

  const closeResourcesModal = () => {
    showResourcesModal.value = false;
    store.commit('resource/CLEAR_NOT_CONNECTED');
    tableKey.value += 1;
  };

  onBeforeUnmount(() => {
    store.commit('tag/CLEAR_TAG');
    store.commit('resource/CLEAR_CONNECTED_RESOURCE');
    store.commit('resource/CLEAR_NOT_CONNECTED');
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(TopbarOrganism, {
        linkText: "Back to tags",
        showIcon: true,
        icon: "angleLeft",
        primaryButtonText: _unref(primaryButtonText),
        route: "/tagsList",
        onPrimaryClick: _unref(primaryButtonHandler)
      }, null, 8, ["primaryButtonText", "onPrimaryClick"]),
      (!loading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(TagEditorOrganism, { initialDetails: tag.value }, null, 8, ["initialDetails"]),
            (_openBlock(), _createBlock(TableMolecule, {
              dataType: "ConnectedResources",
              key: tableKey.value,
              dynamicAdditionalFilter: 
          new (_unref(AdditionalParams))('tags', 'tag_id', tag_id.value, 'some')
        ,
              onActionClick: openResourcesModal
            }, null, 8, ["dynamicAdditionalFilter"]))
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, "Loading..."))
    ]),
    (showResourcesModal.value)
      ? (_openBlock(), _createBlock(ResourceModalMolecule, {
          key: 0,
          onClick: closeResourcesModal
        }, {
          default: _withCtx(() => [
            _createVNode(TableMolecule, {
              dataType: "NotConnectedResources",
              dynamicAdditionalFilter: 
        new (_unref(AdditionalParams))('tags', 'tag_id', tag_id.value, 'none')
      
            }, null, 8, ["dynamicAdditionalFilter"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})