import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Line } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
  } from 'chart.js';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'LineChart',
  props: {
    data: {},
    xAxisTitle: {},
    yAxisTitle: {},
    dataKey: { default: 'data' },
    labelKey: { default: 'label' },
    lineColor: { default: '#1D43A6' }
  },
  setup(__props: any) {

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    Filler,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
  );

  const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Line), {
    data: {
      labels: props.data.map(
        (dataArr) =>
          dataArr[_ctx.labelKey].charAt(0).toUpperCase() +
          dataArr[_ctx.labelKey].substring(1),
      ),
      datasets: [
        {
          data: props.data.map((dataSet) => dataSet[_ctx.dataKey]),
          backgroundColor: 'rgba(29, 67, 166, 0.15)',
          borderColor: '#1D43A6',
          borderWidth: 1,
          fill: {
            target: 'origin',
            below: 'rgba(29, 67, 166, 0.15)',
          },
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        filler: {
          propagate: false,
        },
      },
      scales: {
        x: {
          suggestedMax: Math.max(
            ...props.data.map((dataSet) => dataSet[_ctx.dataKey]),
          ),
          title: { text: _ctx.xAxisTitle ?? '', display: _ctx.xAxisTitle !== undefined },
          grid: {
            display: false,
          },
        },
        y: {
          suggestedMax: Math.max(
            ...props.data.map((dataSet) => dataSet[_ctx.dataKey]),
          ),
          title: {
            text: _ctx.yAxisTitle ?? '',
            display: _ctx.yAxisTitle !== undefined,
          },
          grid: {
            display: true,
          },
        },
      },
    }
  }, null, 8, ["data", "options"]))
}
}

})