import Resource from '@/models/resource/resource.model';
import APIService from '../api.service';
import { FilterParams } from '@/shared/filterParams';

class ResourceService {
  public async getAll(params: FilterParams): Promise<Resource[]> {
    return APIService.post<Resource[]>(`/resources/cms/search`, params);
  }

  public async getConnected(params: FilterParams): Promise<Resource[]> {
    return APIService.post<Resource[]>(`/resources/cms/connected`, params);
  }

  public async getById(id: string): Promise<Resource> {
    return APIService.get<Resource>(`/resources/${id}`);
  }

  public async create(data: any): Promise<Resource> {
    return APIService.post<Resource>(`/resources`, data);
  }

  public async update(id: string, data: any): Promise<Resource> {
    return APIService.post(`/resources/update/${id}`, data);
  }

  public async deleteMultiple(data: any): Promise<Resource[]> {
    return APIService.post(`/resources/delete/multiple`, data);
  }

  public async publish(id: string): Promise<Resource> {
    return APIService.post(`/resources/publish/${id}`, {});
  }

  public async restore(id: string): Promise<Resource> {
    return APIService.post(`/resources/restore/${id}`, {});
  }

  public async delete(id: string): Promise<any> {
    return APIService.delete(`/resources/${id}`);
  }

  public async disconnect(payload): Promise<any> {
    return APIService.post(`/resources/disconnect`, payload);
  }

  public async connect(payload): Promise<any> {
    return APIService.post(`/resources/connect`, payload);
  }

  public async syncMetadata(id: string, payload: any): Promise<any> {
    return APIService.patch(`/resources/duration/${id}`, payload);
  }
}

export default new ResourceService();
