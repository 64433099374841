import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { RouterView } from "vue-router";
import LoaderAtom from "./components/atoms/LoaderAtom.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(LoaderAtom),
    _createVNode(_unref(RouterView))
  ], 64))
}
}

})