import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';

  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Notification from '@/models/notification/notification.model';
  import NotificationFormOrganisam from '@/components/organisms/notification-form-organism/NotificationFormOrganisam.vue';
  import ScheduleNotificationModal from '@/components/atoms/ScheduleNotificationModal.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationDetailsView',
  setup(__props) {

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const notification = computed(
    () => store.getters['notification/getNotification'],
  );
  const showScheduleNotificationModal = ref(false);

  const loading = ref(true);

  let topbarPrimaryButtonText = '';
  let topbarSecondaryButtonText = '';
  let topbarPrimaryButtonHandler;
  let topbarSecondaryButtonHandler;
  let modalActionButtonOneText = '';
  let modalActionButtonTwoText = '';
  let modalActionButtonOneHandler;
  let modalActionButtonTwoHandler;

  onMounted(() => {
    if (route.query.id) {
      store
        .dispatch('notification/fetchItemDetails', route.query.id)
        .then(() => {
          loading.value = false;
        });

      topbarPrimaryButtonText = 'Save';
      topbarSecondaryButtonText = 'Cancel';
      topbarSecondaryButtonHandler = cancelHandler;
      topbarPrimaryButtonHandler = sendNotification;
    } else {
      store.commit('notification/SET_NOTIFICATION', new Notification({}));
      loading.value = false;
      loading.value = false;
      topbarPrimaryButtonText = 'Send notification';
      modalActionButtonOneText = 'Schedule';
      modalActionButtonTwoText = 'Save to draft';
      topbarSecondaryButtonText = 'Cancel';

      modalActionButtonOneHandler = showScheduleModal;
      modalActionButtonTwoHandler = submitToDraft;

      topbarPrimaryButtonHandler = sendNotification;
      topbarSecondaryButtonHandler = cancelHandler;
    }
  });

  const showScheduleModal = () => {
    showScheduleNotificationModal.value = true;
  };

  const sendNotification = async () => {
    store.commit('notification/SET_NOTIFICATION', {
      ...notification.value,
      status: 'sent',
    });

    await nextTick();
    store.dispatch('notification/createItem', notification.value).then(() => {
      router.back();
    });
  };

  const submitToDraft = async () => {
    store.commit('notification/SET_NOTIFICATION', {
      ...notification.value,
      status: 'draft',
    });

    await nextTick();
    store.dispatch('notification/createItem', notification.value).then(() => {
      router.back();
    });
  };

  onBeforeUnmount(() => {
    store.commit('notification/CLEAR_NOTIFICATION');
  });

  const cancelHandler = async () => {
    router.back();
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(TopbarOrganism, {
      linkText: "Back to notifications",
      showIcon: true,
      icon: "angleLeft",
      secondaryButtonText: _unref(topbarSecondaryButtonText),
      primaryButtonText: _unref(topbarPrimaryButtonText),
      onSecondaryClick: _unref(topbarSecondaryButtonHandler),
      onPrimaryClick: _unref(topbarPrimaryButtonHandler),
      modalButtonOne: _unref(modalActionButtonOneText),
      modalButtonTwo: _unref(modalActionButtonTwoText),
      onButtonOneClick: _unref(modalActionButtonOneHandler),
      onButtonTwoClick: _unref(modalActionButtonTwoHandler)
    }, null, 8, ["secondaryButtonText", "primaryButtonText", "onSecondaryClick", "onPrimaryClick", "modalButtonOne", "modalButtonTwo", "onButtonOneClick", "onButtonTwoClick"]),
    (!loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(NotificationFormOrganisam, { initialDetails: notification.value }, null, 8, ["initialDetails"]),
          _createVNode(ScheduleNotificationModal, {
            show: showScheduleNotificationModal.value,
            "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showScheduleNotificationModal.value = $event))
          }, null, 8, ["show"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})