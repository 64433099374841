import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tag-editor-molecule" }

import { ref, watch } from "vue";
import InputMolecule from "../input-molecule/InputMolecule.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TagEditorMolecule',
  props: {
    details: {}
  },
  emits: ["updateDetails"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const localDetails = ref({ ...props.details });

const showAddNewTagModal = ref(false);
const tags = ref([]);
const coverImage = ref<string | null>(null);
const uploadedImg = ref<HTMLInputElement | null>(null);
const tagOptions = ref(["Parenting", "Adoption", "Co-parenting", "Teenagers"]);

watch(
  () => props.details,
  (newValue) => {
    localDetails.value = { ...newValue };
  },
  { immediate: true, deep: true }
);

watch(
  localDetails,
  () => {
    emit("updateDetails", localDetails);
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InputMolecule, {
      placeholder: "Name",
      title: "Add new tag here",
      label: "Title",
      modelValue: localDetails.value.tag_name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localDetails.value.tag_name) = $event))
    }, null, 8, ["modelValue"])
  ]))
}
}

})