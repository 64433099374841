import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref, watch } from 'vue';
  import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    PieController,
    ChartData,
    ChartOptions,
  } from 'chart.js';
  import type { Chart } from 'chart.js';

  interface Props {
    chartData: ChartData<'pie'>;
    chartOptions: ChartOptions<'pie'>;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'PieChart',
  props: {
    chartData: {},
    chartOptions: {}
  },
  setup(__props: any) {

  ChartJS.register(ArcElement, Tooltip, Legend, PieController);

  const props = __props;
  const chartCanvas = ref<HTMLCanvasElement | null>(null);
  let chartInstance: Chart<'pie'> | null = null;

  onMounted(() => {
    if (chartCanvas.value) {
      const config = {
        type: 'pie' as const,
        data: props.chartData,
        options: {
          ...props.chartOptions,
          rotation: -90,
          circumference: 360,
          responsive: false,
          maintainAspectRatio: true,
        },
      };

      chartInstance = new ChartJS(chartCanvas.value, config);
    }
  });

  watch(
    () => props.chartData,
    (newData) => {
      if (chartInstance) {
        chartInstance.data = newData;
        chartInstance.update();
      }
    },
    { deep: true },
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("canvas", {
    ref_key: "chartCanvas",
    ref: chartCanvas,
    class: "chart-canvas",
    width: "200",
    height: "200",
    style: {"width":"200px","height":"200px","margin":"auto auto"}
  }, null, 512))
}
}

})