import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "article-editor-molecule" }
const _hoisted_2 = { class: "cover-img" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "tags" }
const _hoisted_6 = { class: "collection" }
const _hoisted_7 = {
  key: 0,
  class: "selected-collections-container"
}
const _hoisted_8 = { class: "name-and-img" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "text-editor" }

import {
    ref,
    watch,
    onMounted,
    computed,
  } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import InputSelectAtom from '@/components/atoms/InputSelectAtom.vue';
  import AddNewTagAtom from '@/components/atoms/AddNewTagAtom.vue';
  import TinyEditor from '@/components/atoms/TinyEditor.vue';
  import AddToCollectionAtom from '@/components/atoms/AddToCollectionAtom.vue';
  import {
    SelectOption,
    transformToSelectOptions,
  } from '@/shared/selectOption';
  import tagService from '@/services/tag/tag.service';
  import Collection from '@/models/collection/collection.model';
  import collectionService from '@/services/collection/collection.service';
  import { useStore } from 'vuex';

  const actionCollectionsGetter = 'resource/getResourceCollections';
  const actionCollectionSet = 'resource/addCollection';
  const actionCollectionRemove = 'resource/removeCollection';
  const actionTagGetter = 'resource/getResourceTags';
  const actionTagSet = 'resource/addTag';
  const actionTagRemove = 'resource/removeTag';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ArticleEditorMolecule',
  props: {
    resourceDetails: {}
  },
  emits: ['updateResourceDetails', 'updatePreview'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const store = useStore();

  const localResourceDetails = ref({ ...props.resourceDetails });
  const showAddNewTagModal = ref(false);
  const showAddToCollectionModal = ref(false);
  const coverImage = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);
  const tagOptions = ref<SelectOption[]>([]);
  const collectionOptions = ref<Collection[]>([]);

  const selectedCollections = computed(
    () => store.getters[actionCollectionsGetter] || [],
  );
  const selectedTags = computed(() => store.getters[actionTagGetter] || []);

  onMounted(() => {
    fetchTags();
    fetchCollections();
  });

  watch(
    () => props.resourceDetails,
    (newValue) => {
      localResourceDetails.value = { ...newValue };
      coverImage.value = localResourceDetails.value.banner;
    },
    { immediate: true, deep: true },
  );

  watch(
    localResourceDetails,
    () => {
      updatePreview();
    },
    { deep: true },
  );

  const fetchTags = async () => {
    tagOptions.value = transformToSelectOptions(
      ((await tagService.getAllNoFilter()) as any).data,
      'tag_id',
      'tag_name',
    );
  };

  const fetchCollections = async () => {
    collectionOptions.value = (
      (await collectionService.getAllNoFilter()) as any
    ).data;
  };

  function handleNewTagAdded() {
    console.log(456);

    showAddNewTagModal.value = false;
    fetchTags();
  }

  function addTagToSelection(newTag) {
    if (!localResourceDetails.value.tags) {
      localResourceDetails.value.tags = [];
    }

    if (localResourceDetails.value.tags.length < 3) {
      localResourceDetails.value.tags.push({
        id: newTag.tag_id,
        value: newTag.tag_name,
      });

      store.dispatch(actionTagSet, {
        id: newTag.tag_id,
        value: newTag.tag_name,
      });
    }
  }

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function openAddToCollectionModal() {
    showAddToCollectionModal.value = true;
  }

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
      updatePreview();
    };
    reader.readAsDataURL(file);
    localResourceDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
      localResourceDetails.value.banner = '';
    }
    updatePreview();
  }

  function updatePreview() {
    emit('updatePreview', coverImage.value);

    emit('updateResourceDetails', localResourceDetails);
  }

  function handleCollectionsUpdate(collections: string[]) {
    //selectedCollections.value = collections;
    localResourceDetails.value.collections = collections;
    showAddToCollectionModal.value = false;
  }

  function handleCollectionRemove(collection: string) {
    // selectedCollections.value = selectedCollections.value.filter(
    //   (c) => c !== collection
    // );

    store.dispatch(actionCollectionRemove, collection);
    localResourceDetails.value.collections = selectedCollections.value;

    if (selectedCollections.value.length === 0) {
      showAddToCollectionModal.value = false;
    }

    updatePreview();
  }

  updatePreview();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "editor-title" }, "Add new articles details", -1)),
      _createVNode(ButtonAtom, {
        class: "btn btn-secondary coverBtn",
        style: _normalizeStyle({
          backgroundImage: coverImage.value ? `url(${coverImage.value})` : '',
          border: coverImage.value ? 'none' : '1px dashed rgba(8, 14, 30, 0.30)',
        }),
        onClick: triggerImgUpload
      }, {
        default: _withCtx(() => [
          (!coverImage.value)
            ? (_openBlock(), _createBlock(IconAtom, {
                key: 0,
                icon: "cameraIcon"
              }))
            : _createCommentVNode("", true),
          (!coverImage.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Cover"))
            : _createCommentVNode("", true),
          (coverImage.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "clear-image-btn",
                onClick: _withModifiers(clearImage, ["stop"])
              }, " X "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["style"]),
      _createElementVNode("input", {
        type: "file",
        ref_key: "uploadedImg",
        ref: uploadedImg,
        accept: "image/*",
        onChange: imgupload,
        style: {"display":"none"}
      }, null, 544)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[6] || (_cache[6] = _createElementVNode("label", { for: "title" }, "Title", -1)),
      _createVNode(InputAtom, {
        id: "title",
        modelValue: localResourceDetails.value.resource_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localResourceDetails.value.resource_name) = $event)),
        placeholder: "Name",
        class: "input"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[7] || (_cache[7] = _createElementVNode("label", { for: "tags" }, "Tags (maximum 3)", -1)),
      _createVNode(InputSelectAtom, {
        id: "tags",
        placeholder: "Add tags",
        modelValue: localResourceDetails.value.tags,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localResourceDetails.value.tags) = $event)),
        options: tagOptions.value,
        onAddNew: openAddNewTagModal,
        tagsGetter: actionTagGetter,
        tagSet: actionTagSet,
        tagRemove: actionTagRemove
      }, null, 8, ["modelValue", "options"]),
      _createVNode(AddNewTagAtom, {
        show: showAddNewTagModal.value,
        "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (showAddNewTagModal.value = $event)),
        onNewTagAdded: handleNewTagAdded,
        onAddTagToSelection: addTagToSelection
      }, null, 8, ["show"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[9] || (_cache[9] = _createElementVNode("label", { for: "collection" }, "Collection", -1)),
      (selectedCollections.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCollections.value, (collection) => {
              return (_openBlock(), _createElementBlock("div", {
                key: collection,
                class: "collection-item"
              }, [
                _createElementVNode("div", _hoisted_8, [
                  (collection.banner)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: collection.banner,
                        alt: "Collection Image",
                        class: "collection-icon"
                      }, null, 8, _hoisted_9))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(collection.collection_name), 1)
                ]),
                _createVNode(IconAtom, {
                  icon: "deleteIcon",
                  onClick: () => handleCollectionRemove(collection),
                  class: "pointer"
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(ButtonAtom, {
        class: "btn btn-secondary collectionBtn",
        onClick: openAddToCollectionModal
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode(" + Add to a collection ")
        ])),
        _: 1
      }),
      _createVNode(AddToCollectionAtom, {
        show: showAddToCollectionModal.value,
        collections: collectionOptions.value,
        "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (showAddToCollectionModal.value = $event)),
        onUpdateCollections: handleCollectionsUpdate,
        actionCollectionsGetter: actionCollectionsGetter,
        actionCollectionSet: actionCollectionSet,
        actionCollectionRemove: actionCollectionRemove
      }, null, 8, ["show", "collections"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[10] || (_cache[10] = _createElementVNode("label", { for: "text" }, "Text", -1)),
      _createVNode(TinyEditor, {
        modelValue: localResourceDetails.value.text_content,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((localResourceDetails.value.text_content) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}
}

})