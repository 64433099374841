import commentService from '@/services/comment/comment.service';
import Comment from '@/models/comment/comment.model';
import { FilterParams, SearchParamsAND } from '@/shared/filterParams';

const userModule = {
  namespaced: true,
  state: {
    items: [],
    totalPages: 1,
    count: 0,
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data.data;
      state.totalPages = data.totalPages;
    },
    CLEAR_ITEMS(state) {
      state.items = [];
      state.totalPages = 0;
    },
    SET_COUNT(state, data) {
      state.count = data.counts.comment_id.all;
    },
  },
  actions: {
    async fetchComments({ commit }, params: FilterParams) {
      try {
        const isParentCommentLookup = params.searchParamsAND?.some(
          (param) => param.searchField === 'parent_comment_id',
        );

        if (isParentCommentLookup) {
          const parentParam = params.searchParamsAND?.find(
            (param) => param.searchField === 'parent_comment_id',
          );

          if (!parentParam?.searchTerm) {
            console.error('Parent comment ID not found');
            return;
          }

          const data = await commentService.lookupParentComments(
            parentParam.searchTerm,
          );
          commit('SET_ITEMS', data);
          return;
        }

        const data = await commentService.getAll(params);
        commit('SET_ITEMS', data);

        if (params.searchParamsOR?.searchTerm == '') {
          commit('SET_COUNT', data);
        }
      } catch (error) {
        console.error('Failed to fetch comments:', error);
      }
    },
    async deleteItems({ dispatch }, payload: { data: string[] }) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await commentService.deleteMultiple({ comment_ids: payload.data });
      } catch (error) {
        console.error('Error deleting groups', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
  },
  getters: {
    getItems(state) {
      return state.items;
    },

    getTotalPages(state) {
      return state.totalPages;
    },
    getCount(state) {
      return state.count;
    },
  },
};

export default userModule;
