import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "collections-organism" }
const _hoisted_2 = { class: "sections-container" }
const _hoisted_3 = { class: "cover-section" }
const _hoisted_4 = { class: "cover-img" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "details-section" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "tags" }

import {
    ref,
    watch,
    onMounted,
    onBeforeUnmount,
  } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import InputSelectAtom from '@/components/atoms/InputSelectAtom.vue';
  import AddNewTagAtom from '@/components/atoms/AddNewTagAtom.vue';
  import SwitchAtom from '@/components/atoms/SwitchAtom.vue';
  import TinyEditor from '@/components/atoms/TinyEditor.vue';
  import AddToCollectionAtom from '@/components/atoms/AddToCollectionAtom.vue';
  import tagService from '@/services/tag/tag.service';
  import Tag from '@/models/tag/tag.model';
  import {
    SelectOption,
    transformToSelectOptions,
  } from '@/shared/selectOption';
  import { useStore } from 'vuex';

  const actionTagGetter = 'collection/getCollectionTags';
  const actionTagSet = 'collection/addTag';
  const actionTagRemove = 'collection/removeTag';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CollectionEditMolecule',
  props: {
    details: {},
    resourceDetails: {}
  },
  emits: ['updateDetails'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const store = useStore();

  const localDetails = ref({ ...props.details });
  const localResourceDetails = ref({ ...props.resourceDetails });

  const showAddNewTagModal = ref(false);
  const showAddToCollectionModal = ref(false);
  const tags = ref([]);
  const coverImage = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);
  const tagOptions = ref<SelectOption[]>([]);
  const fetchTags = async () => {
    tagOptions.value = transformToSelectOptions(
      ((await tagService.getAllNoFilter()) as any).data,
      'tag_id',
      'tag_name',
    );
  };

  onMounted(() => {
    fetchTags();
  });

  watch(
    () => props.details,
    (newValue) => {
      localDetails.value = { ...newValue };
      coverImage.value = localDetails.value.banner;
    },
    { immediate: true, deep: true },
  );

  function handleNewTagAdded(newTag: string) {
    fetchTags();
    showAddNewTagModal.value = false;
  }

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
    localDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    localDetails.value.banner = '';
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
    }
  }

  watch(
    localDetails,
    () => {
      emit('updateDetails', localDetails);
    },
    { deep: true },
  );

  function addTagToSelection(newTag) {
    if (!localResourceDetails.value.tags) {
      localResourceDetails.value.tags = [];
    }

    if (localResourceDetails.value.tags.length < 3) {
      localResourceDetails.value.tags.push({
        id: newTag.tag_id,
        value: newTag.tag_name,
      });

      store.dispatch(actionTagSet, {
        id: newTag.tag_id,
        value: newTag.tag_name,
      });
    }
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("label", { for: "title" }, "Add new articles details", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(ButtonAtom, {
            class: "btn btn-secondary coverBtn",
            style: _normalizeStyle({
              backgroundImage: coverImage.value ? `url(${coverImage.value})` : '',
              border: coverImage.value ? 'none' : '2px dashed #ccc',
            }),
            onClick: triggerImgUpload
          }, {
            default: _withCtx(() => [
              (!coverImage.value)
                ? (_openBlock(), _createBlock(IconAtom, {
                    key: 0,
                    icon: "cameraIcon"
                  }))
                : _createCommentVNode("", true),
              (!coverImage.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Cover"))
                : _createCommentVNode("", true),
              (coverImage.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "clear-image-btn",
                    onClick: _withModifiers(clearImage, ["stop"])
                  }, " X "))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["style"]),
          _createElementVNode("input", {
            type: "file",
            ref_key: "uploadedImg",
            ref: uploadedImg,
            accept: "image/*",
            onChange: imgupload,
            style: {"display":"none"}
          }, null, 544)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "title" }, "Title", -1)),
          _createVNode(InputAtom, {
            id: "title",
            modelValue: localDetails.value.collection_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localDetails.value.collection_name) = $event)),
            placeholder: "Name",
            class: "input"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "tags" }, "Tags (maximum 3)", -1)),
          _createVNode(InputSelectAtom, {
            id: "tags",
            placeholder: "Add tags",
            modelValue: localDetails.value.collectionTag,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localDetails.value.collectionTag) = $event)),
            options: tagOptions.value,
            onAddNew: openAddNewTagModal,
            tagsGetter: actionTagGetter,
            tagSet: actionTagSet,
            tagRemove: actionTagRemove
          }, null, 8, ["modelValue", "options"]),
          _createVNode(AddNewTagAtom, {
            show: showAddNewTagModal.value,
            "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (showAddNewTagModal.value = $event)),
            onNewTagAdded: handleNewTagAdded,
            onAddTagToSelection: addTagToSelection
          }, null, 8, ["show"])
        ]),
        _createVNode(SwitchAtom, {
          modelValue: localDetails.value.is_featured,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((localDetails.value.is_featured) = $event))
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}
}

})