import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "center-container" }
const _hoisted_3 = { class: "cover-section" }
const _hoisted_4 = { class: "cover-img" }
const _hoisted_5 = { class: "clear-image-btn" }
const _hoisted_6 = { class: "details-container" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "link" }
const _hoisted_9 = { class: "attach-resources" }
const _hoisted_10 = { class: "send-to" }
const _hoisted_11 = { class: "personal-details" }
const _hoisted_12 = { class: "account-details" }
const _hoisted_13 = { class: "buttons" }

import { onMounted, onUnmounted } from 'vue';
  import InputAtom from './InputAtom.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import userImgSrc from '@/assets/user-default.png';
  import UserImgAtom from '@/components/atoms/UserImgAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'SendNotificationModal',
  props: {
    show: Boolean,
  },
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const emits = __emit;

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "send-notification-modal-overlay",
        onClick: closeModal
      }, [
        _createElementVNode("div", {
          class: "send-notification-modal",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "send-notification-modal-title" }, "Personal Push Notification", -1)),
          _createVNode(IconAtom, {
            class: "close-icon",
            icon: "closeIcon",
            onClick: closeModal
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(ButtonAtom, { class: "btn btn-secondary coverBtn" }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", _hoisted_5, [
                        _createVNode(IconAtom, {
                          icon: "editIcon",
                          color: "#6B6E78"
                        })
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("label", { for: "title" }, "Title", -1)),
                _createVNode(InputAtom, {
                  id: "title",
                  placeholder: "Name",
                  class: "input"
                })
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text" }, [
                _createElementVNode("label", { for: "text" }, "Text"),
                _createElementVNode("textarea", {
                  id: "text",
                  placeholder: "Message",
                  class: "input textarea",
                  rows: "4"
                })
              ], -1)),
              _createElementVNode("div", _hoisted_8, [
                _cache[2] || (_cache[2] = _createElementVNode("label", { for: "link" }, "URL Link", -1)),
                _createVNode(InputAtom, {
                  id: "link",
                  placeholder: "Link",
                  class: "input"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "attach-resources-text" }, "Attach Resources (Optional)", -1)),
              _createVNode(ButtonAtom, { class: "btn btn-secondary" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Select")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[7] || (_cache[7] = _createElementVNode("label", null, "Send to", -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(UserImgAtom, {
                    class: "image-detail",
                    src: _unref(userImgSrc)
                  }, null, 8, ["src"]),
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "name-email-details" }, [
                    _createElementVNode("p", { class: "name-detail" }, "Amir Kishk"),
                    _createElementVNode("p", { class: "email-detail" }, "kishk.amir@gmail.com")
                  ], -1))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(ButtonAtom, {
                class: "btn btn-secondary",
                onClick: closeModal
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1
              }),
              _createVNode(ButtonAtom, { class: "btn btn-primary" }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Send now")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})