import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-overlay"
}

import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoaderAtom',
  setup(__props) {

const store = useStore();

const isLoading = computed(() => store.getters["loader/getIsLoading"]);

return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "loader" }, null, -1)
      ])))
    : _createCommentVNode("", true)
}
}

})