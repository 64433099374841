import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

import { ref, watch, nextTick, computed } from "vue";
import CollectionEditMolecule from "@/components/molecules/collection-edit-molecule/CollectionEditMolecule.vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'CollectionsFormOrganism',
  props: {
    initialDetails: {}
  },
  emits: ["update:collectionDetails"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

let isUpdating = false;

const store = useStore();

const collection = computed(() => store.getters["collection/getCollection"]);
const localDetails = ref({ ...props.initialDetails });

watch(
  collection,
  (newValue) => {
    if (!isUpdating && newValue) {
      localDetails.value = { ...newValue };
    }
  },
  { immediate: true, deep: true }
);

const updateDetails = (updatedDetails) => {
  if (isUpdating) return;
  isUpdating = true;

  store.commit("collection/SET_COLLECTION", updatedDetails);

  emit("update:collectionDetails", updatedDetails);

  nextTick(() => {
    isUpdating = false;
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CollectionEditMolecule, {
      details: localDetails.value,
      onUpdateDetails: updateDetails
    }, null, 8, ["details"])
  ]))
}
}

})