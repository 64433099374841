import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "schedule-details" }
const _hoisted_2 = { class: "field date" }
const _hoisted_3 = { class: "field time" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "field repeat" }
const _hoisted_7 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "field frequency" }
const _hoisted_10 = { class: "dropdown-toggle" }
const _hoisted_11 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "buttons-section" }

import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  const startTime = 9;
  const endTime = 17;

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleNotificationModal',
  props: {
    show: Boolean,
  },
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

  const date = ref<Date>(new Date());
  const isDateVisible = ref(false);

  const store = useStore();
  const notification = computed(
    () => store.getters['notification/getNotification'],
  );

  const selectedTime = ref('09:00');
  const selectedRepeat = ref('Does not repeat');
  const selectedFrequency = ref('Once');
  const router = useRouter();
  const timeOptions: string[] = [];
  for (let hour = startTime; hour <= endTime; hour++) {
    timeOptions.push(`${hour.toString().padStart(2, '0')}:00`);
    timeOptions.push(`${hour.toString().padStart(2, '0')}:30`);
  }
  const repeatOptions = ['Does not repeat', 'Daily', 'Weekly', 'Monthly'];
  const frequencyOptions = ['Once', 'Custom'];

  const isDropdownOpen = ref({
    time: false,
    repeat: false,
    frequency: false,
  });

  const closeOptions = () => {
    isDropdownOpen.value = {
      time: false,
      repeat: false,
      frequency: false,
    };
  };

  const toggleDropdown = (field) => {
    const isCurrentlyOpen = isDropdownOpen.value[field];
    isDropdownOpen.value = {
      time: false,
      repeat: false,
      frequency: false,
    };
    isDropdownOpen.value[field] = !isCurrentlyOpen;

    if (isDropdownOpen.value[field]) {
      document.addEventListener('click', handleOutsideClick, true); // Capture phase
    } else {
      document.removeEventListener('click', handleOutsideClick, true);
    }
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.dropdown')) {
      closeOptions();
      document.removeEventListener('click', handleOutsideClick, true);
    }
  };

  const selectTime = (option) => {
    selectedTime.value = option;
    isDropdownOpen.value.time = false;
    document.removeEventListener('click', handleOutsideClick, true);
  };

  const selectRepeat = (option) => {
    selectedRepeat.value = option;
    isDropdownOpen.value.repeat = false;
    if (option === 'Does not repeat') {
      selectedFrequency.value = 'Once';
    } else {
      selectedFrequency.value = 'Custom';
    }
    document.removeEventListener('click', handleOutsideClick, true);
  };

  const selectFrequency = (option) => {
    selectedFrequency.value = option;
    isDropdownOpen.value.frequency = false;
    document.removeEventListener('click', handleOutsideClick, true);
  };

  const props = __props;

  const scheduleNotification = async () => {
    const scheduleDate = new Date(date.value);
    const [hours, minutes] = selectedTime.value.split(':').map(Number);
    scheduleDate.setHours(hours, minutes);
    store.commit('notification/SET_NOTIFICATION', {
      ...notification.value,
      status: 'scheduled',
      schedule_at: scheduleDate,
      repeat_type:
        selectedRepeat.value === 'Does not repeat'
          ? 'NONE'
          : selectedRepeat.value.toUpperCase(),
    });

    await nextTick();

    store.dispatch('notification/createItem', notification.value).then(() => {
      closeModal();
      router.back();
    });
  };

  const emits = __emit;

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('click', handleOutsideClick, true);
  });

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "schedule-notification-modal-overlay",
        onClick: _cache[8] || (_cache[8] = ($event: any) => {
      closeModal();
      closeOptions();
    })
      }, [
        _createElementVNode("div", {
          class: "schedule-notification-modal",
          onClick: _withModifiers(closeOptions, ["stop"])
        }, [
          _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "schedule-notification-title" }, "Schedule notification", -1)),
          _createVNode(IconAtom, {
            class: "close-icon",
            icon: "closeIcon",
            onClick: closeModal
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[9] || (_cache[9] = _createElementVNode("label", { for: "date" }, "Date", -1)),
              _createVNode(_unref(VueDatePicker), {
                modelValue: date.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event)),
                type: "date",
                "month-name-format": "long",
                "enable-time-picker": false,
                "hide-input-icon": true,
                "auto-apply": true,
                placeholder: "Select date",
                format: 'dd/MM/yyyy',
                class: "date-input",
                onOpen: _cache[1] || (_cache[1] = ($event: any) => (isDateVisible.value = true)),
                onClosed: _cache[2] || (_cache[2] = ($event: any) => (isDateVisible.value = false))
              }, null, 8, ["modelValue"]),
              _createVNode(IconAtom, {
                icon: isDateVisible.value ? 'angleUp' : 'angleDown',
                class: "date-icon"
              }, null, 8, ["icon"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "time" }, "Time", -1)),
              _createElementVNode("div", {
                class: "dropdown",
                onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("div", {
                  class: "dropdown-toggle",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (toggleDropdown('time')))
                }, [
                  _createElementVNode("span", null, _toDisplayString(selectedTime.value), 1),
                  _createVNode(IconAtom, {
                    icon: isDropdownOpen.value.time ? 'angleUp' : 'angleDown',
                    class: "dropdown-icon"
                  }, null, 8, ["icon"])
                ]),
                (isDropdownOpen.value.time)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(timeOptions, (option) => {
                        return _createElementVNode("li", {
                          key: option,
                          onClick: ($event: any) => (selectTime(option))
                        }, _toDisplayString(option), 9, _hoisted_5)
                      }), 64))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[11] || (_cache[11] = _createElementVNode("label", { for: "repeat" }, "Repeat", -1)),
              _createElementVNode("div", {
                class: "dropdown",
                onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("div", {
                  class: "dropdown-toggle",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (toggleDropdown('repeat')))
                }, [
                  _createElementVNode("span", null, _toDisplayString(selectedRepeat.value), 1),
                  _createVNode(IconAtom, {
                    icon: isDropdownOpen.value.repeat ? 'angleUp' : 'angleDown',
                    class: "dropdown-icon"
                  }, null, 8, ["icon"])
                ]),
                (isDropdownOpen.value.repeat)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(repeatOptions, (option) => {
                        return _createElementVNode("li", {
                          key: option,
                          onClick: ($event: any) => (selectRepeat(option))
                        }, _toDisplayString(option), 9, _hoisted_8)
                      }), 64))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[12] || (_cache[12] = _createElementVNode("label", { for: "frequency" }, "Frequency", -1)),
              _createElementVNode("div", {
                class: "dropdown",
                onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(selectedFrequency.value), 1),
                  _createVNode(IconAtom, {
                    icon: isDropdownOpen.value.frequency ? 'angleUp' : 'angleDown',
                    class: "dropdown-icon"
                  }, null, 8, ["icon"])
                ]),
                (isDropdownOpen.value.frequency)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(frequencyOptions, (option) => {
                        return _createElementVNode("li", {
                          key: option,
                          onClick: ($event: any) => (selectFrequency(option))
                        }, _toDisplayString(option), 9, _hoisted_12)
                      }), 64))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(ButtonAtom, {
              class: "btn btn-secondary",
              onClick: closeModal
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" Cancel ")
              ])),
              _: 1
            }),
            _createVNode(ButtonAtom, {
              onClick: scheduleNotification,
              class: "btn btn-primary"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Schedule Notification")
              ])),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})