<script setup lang="ts">
  import {
    ref,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    onUnmounted,
    computed,
  } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import InputSelectAtom from '@/components/atoms/InputSelectAtom.vue';
  import AddNewTagAtom from '@/components/atoms/AddNewTagAtom.vue';
  import TinyEditor from '@/components/atoms/TinyEditor.vue';
  import AddToCollectionAtom from '@/components/atoms/AddToCollectionAtom.vue';
  import {
    SelectOption,
    transformToSelectOptions,
  } from '@/shared/selectOption';
  import tagService from '@/services/tag/tag.service';
  import Collection from '@/models/collection/collection.model';
  import collectionService from '@/services/collection/collection.service';
  import { useStore } from 'vuex';

  const props = defineProps<{ resourceDetails: any }>();
  const emit = defineEmits(['updateResourceDetails', 'updatePreview']);
  const store = useStore();

  const localResourceDetails = ref({ ...props.resourceDetails });
  const showAddNewTagModal = ref(false);
  const showAddToCollectionModal = ref(false);
  const coverImage = ref<string | null>(null);
  const videoFile = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);
  const uploadedFile = ref<HTMLInputElement | null>(null);
  const tagOptions = ref<SelectOption[]>([]);
  const collectionOptions = ref<Collection[]>([]);

  const actionCollectionsGetter = 'resource/getResourceCollections';
  const actionCollectionSet = 'resource/addCollection';
  const actionCollectionRemove = 'resource/removeCollection';
  const actionTagGetter = 'resource/getResourceTags';
  const actionTagSet = 'resource/addTag';
  const actionTagRemove = 'resource/removeTag';

  const videoPlayer = ref<HTMLVideoElement | null>(null);
  const playButton = ref<HTMLButtonElement | null>(null);
  const playPauseButton = ref<HTMLButtonElement | null>(null);
  const playIcon = ref<HTMLElement | null>(null);
  const pauseIcon = ref<HTMLElement | null>(null);
  const progress = ref<HTMLInputElement | null>(null);
  const muteToggle = ref<HTMLButtonElement | null>(null);
  const volumeControl = ref<HTMLInputElement | null>(null);
  const timeDisplay = ref<HTMLElement | null>(null);
  const fullscreenToggle = ref<HTMLButtonElement | null>(null);

  const selectedCollections = computed(
    () => store.getters[actionCollectionsGetter] || [],
  );
  const selectedTags = computed(() => store.getters[actionTagGetter] || []);

  onMounted(() => {
    fetchTags();
    fetchCollections();
    setupVideoPlayerEvents();
  });

  onUnmounted(() => {
    cleanupVideoPlayerEvents();
  });

  watch(
    () => props.resourceDetails,
    (newValue) => {
      localResourceDetails.value = { ...newValue };
      coverImage.value = localResourceDetails.value.banner;
      videoFile.value = localResourceDetails.value.url_path;
    },
    { immediate: true, deep: true },
  );

  watch(
    localResourceDetails,
    () => {
      updatePreview();
    },
    { deep: true },
  );

  const fetchTags = async () => {
    tagOptions.value = transformToSelectOptions(
      ((await tagService.getAllNoFilter()) as any).data,
      'tag_id',
      'tag_name',
    );
  };

  const fetchCollections = async () => {
    collectionOptions.value = (
      (await collectionService.getAllNoFilter()) as any
    ).data;
  };

  function handleNewTagAdded(newTag: string) {
    fetchTags();
    showAddNewTagModal.value = false;
  }

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function openAddToCollectionModal() {
    showAddToCollectionModal.value = true;
  }

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function triggerVideoUpload() {
    uploadedFile.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
      updatePreview();
    };
    reader.readAsDataURL(file);
    localResourceDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
      localResourceDetails.value.banner = '';
    }
    updatePreview();
  }

  function clearVideoFile() {
    if (videoFile.value) {
      videoFile.value = '';
      localResourceDetails.value.url_path = '';
    }
    videoFile.value = null;
    updatePreview();

    if (uploadedFile.value) {
      uploadedFile.value.value = '';
    }
  }

  function updatePreview() {
    emit('updatePreview', videoFile.value);
    emit('updateResourceDetails', localResourceDetails);
    setVideoFileUrl();
  }

  function handleCollectionsUpdate(collections: string[]) {
    //selectedCollections.value = collections;
    localResourceDetails.value.collections = collections;
    showAddToCollectionModal.value = false;
  }

  function handleCollectionRemove(collection: string) {
    // selectedCollections.value = selectedCollections.value.filter(
    //   (c) => c !== collection
    // );

    store.dispatch(actionCollectionRemove, collection);
    localResourceDetails.value.collections = selectedCollections.value;

    if (selectedCollections.value.length === 0) {
      showAddToCollectionModal.value = false;
    }

    updatePreview();
  }

  function setVideoFileUrl() {
    if (!localResourceDetails.value.url_path) {
      return null;
    }
    if (typeof localResourceDetails.value.url_path == 'object') {
      const fileURL = URL.createObjectURL(localResourceDetails.value.url_path);
      videoFile.value = fileURL;
    }
  }

  function videoChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      videoFile.value = e.target?.result as string;
      updatePreview();
    };
    reader.readAsDataURL(file);
    localResourceDetails.value.url_path = file;
  }

  function addTagToSelection(newTag) {
    if (!localResourceDetails.value.tags) {
      localResourceDetails.value.tags = [];
    }

    if (localResourceDetails.value.tags.length < 3) {
      localResourceDetails.value.tags.push({
        id: newTag.tag_id,
        value: newTag.tag_name,
      });

      store.dispatch(actionTagSet, {
        id: newTag.tag_id,
        value: newTag.tag_name,
      });
    }
  }

  updatePreview();

  function setupVideoPlayerEvents() {
    if (videoPlayer.value) {
      videoPlayer.value.controls = false;
      videoPlayer.value.addEventListener('click', togglePlayPause);
      playButton.value?.addEventListener('click', togglePlayPause);
      playPauseButton.value?.addEventListener('click', togglePlayPause);
      videoPlayer.value?.addEventListener('play', onPlay);
      videoPlayer.value?.addEventListener('pause', onPause);
      videoPlayer.value?.addEventListener('loadedmetadata', updateDuration);
      videoPlayer.value?.addEventListener('timeupdate', updateTime);
      progress.value?.addEventListener('input', seek);
      muteToggle.value?.addEventListener('click', toggleMute);
      volumeControl.value?.addEventListener('input', adjustVolume);
      fullscreenToggle.value?.addEventListener('click', toggleFullscreen);
    }
  }

  function cleanupVideoPlayerEvents() {
    if (videoPlayer.value) {
      videoPlayer.value.removeEventListener('click', togglePlayPause);
      playButton.value?.removeEventListener('click', togglePlayPause);
      playPauseButton.value?.removeEventListener('click', togglePlayPause);
      videoPlayer.value?.removeEventListener('play', onPlay);
      videoPlayer.value?.removeEventListener('pause', onPause);
      videoPlayer.value?.removeEventListener('loadedmetadata', updateDuration);
      videoPlayer.value?.removeEventListener('timeupdate', updateTime);
      progress.value?.removeEventListener('input', seek);
      muteToggle.value?.removeEventListener('click', toggleMute);
      volumeControl.value?.removeEventListener('input', adjustVolume);
      fullscreenToggle.value?.removeEventListener('click', toggleFullscreen);
    }
  }

  function togglePlayPause() {
    const videoEl = videoPlayer.value;
    if (!videoEl) return;

    if (videoEl.paused || videoEl.ended) {
      videoEl.play();
    } else {
      videoEl.pause();
    }
  }

  function onPlay() {
    if (playIcon.value) {
      playIcon.value.style.display = 'none';
    }
    if (pauseIcon.value) {
      pauseIcon.value.style.display = 'inline';
    }
    if (playButton.value) {
      playButton.value.style.display = 'none';
    }
  }

  function onPause() {
    if (playIcon.value) {
      playIcon.value.style.display = 'inline';
    }
    if (pauseIcon.value) {
      pauseIcon.value.style.display = 'none';
    }
    if (playButton.value) {
      playButton.value.style.display = 'flex';
    }
  }

  function updateDuration() {
    const videoEl = videoPlayer.value;
    const timeEl = timeDisplay.value;

    if (!videoEl || !timeEl) return;

    store.commit('resource/SET_METADATA', { duration: videoEl.duration });

    timeEl.textContent = `00:00 / ${formatTime(videoEl.duration)}`;
  }

  function updateTime() {
    const videoEl = videoPlayer.value;
    const progressEl = progress.value;
    const timeEl = timeDisplay.value;

    if (!videoEl || !progressEl || !timeEl) return;

    const value = (100 / videoEl.duration) * videoEl.currentTime;
    progressEl.value = value.toString();
    timeEl.textContent = `${formatTime(videoEl.currentTime)} / ${formatTime(
      videoEl.duration,
    )}`;
    progressEl.style.setProperty('--progress-percent', `${value}%`);
  }

  function seek() {
    if (videoPlayer.value && progress.value) {
      const time =
        (parseFloat(progress.value.value) / 100) * videoPlayer.value.duration;
      videoPlayer.value.currentTime = time;
    }
  }

  function toggleMute() {
    if (videoPlayer.value) {
      videoPlayer.value.muted = !videoPlayer.value.muted;
      updateMuteIcon();
    }
  }

  function adjustVolume() {
    if (videoPlayer.value && volumeControl.value) {
      videoPlayer.value.volume = parseFloat(volumeControl.value.value) / 100;
      volumeControl.value.style.setProperty(
        '--volume-percent',
        `${volumeControl.value.value}%`,
      );
    }
  }

  function updateMuteIcon() {
    const volumeIcon = document.getElementById('volume-icon');
    const muteIcon = document.getElementById('mute-icon');
    if (!volumeIcon || !muteIcon) return;
    if (videoPlayer.value?.muted) {
      volumeIcon.style.display = 'none';
      muteIcon.style.display = 'inline';
    } else {
      volumeIcon.style.display = 'inline';
      muteIcon.style.display = 'none';
    }
  }

  function toggleFullscreen() {
    const videoEl = videoPlayer.value;
    if (!videoEl) return;
    if (videoEl.requestFullscreen) {
      videoEl.requestFullscreen();
    } else if ((videoEl as any).mozRequestFullScreen) {
      (videoEl as any).mozRequestFullScreen();
    } else if ((videoEl as any).webkitRequestFullscreen) {
      (videoEl as any).webkitRequestFullscreen();
    } else if ((videoEl as any).msRequestFullscreen) {
      (videoEl as any).msRequestFullscreen();
    }
  }

  function formatTime(time: number) {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor(time % 60).toString();
    return `${minutes}:${seconds}`;
  }

  watch(videoFile, (newVideo) => {
    if (!newVideo) cleanupVideoPlayerEvents();
    else setupVideoPlayerEvents();
  });
</script>

<template>
  <div class="video-editor-molecule">
    <div class="cover-img">
      <h3 class="editor-title">Add new video details</h3>
      <ButtonAtom
        class="btn btn-secondary coverBtn"
        :style="{
          backgroundImage: coverImage ? `url(${coverImage})` : '',
          border: coverImage ? 'none' : '1px dashed rgba(8, 14, 30, 0.30)',
        }"
        @click="triggerImgUpload"
      >
        <IconAtom v-if="!coverImage" icon="cameraIcon" />
        <p v-if="!coverImage">Cover</p>

        <button
          v-if="coverImage"
          class="clear-image-btn"
          @click.stop="clearImage"
        >
          X
        </button>
      </ButtonAtom>
      <input
        type="file"
        ref="uploadedImg"
        accept="image/*"
        @change="imgupload"
        style="display: none"
      />
    </div>

    <div class="cover-video">
      <label for="title">Media</label>
      <ButtonAtom
        v-if="!videoFile"
        class="btn btn-secondary videoCoverButton"
        @click="triggerVideoUpload"
      >
        <IconAtom v-if="!videoFile" icon="videoIcon" />
        <p v-if="!videoFile">Select a video file</p>
      </ButtonAtom>
      <!-- MY VIDEO START -->
      <div v-if="videoFile" class="my-video">
        <video ref="videoPlayer" id="video-player">
          <source :src="videoFile" type="video/mp4" />
          <source :src="videoFile" type="video/quicktime" />
          Your browser does not support the video element.
        </video>
        <div ref="playButton" class="play-button" id="play-button">
          <svg viewBox="0 0 24 24">
            <path d="M8 5v14l11-7z"></path>
          </svg>
        </div>
        <div class="video-controls">
          <button ref="playPauseButton" id="play-pause">
            <svg ref="playIcon" id="play-icon" viewBox="0 0 24 24" fill="#fff">
              <path d="M8 5v14l11-7z"></path>
            </svg>
            <svg
              ref="pauseIcon"
              id="pause-icon"
              viewBox="0 0 24 24"
              style="display: none"
              fill="#fff"
            >
              <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path>
            </svg>
          </button>
          <span ref="timeDisplay" id="time-display">00:00 / 00:00</span>
          <input
            type="range"
            ref="progress"
            id="progress"
            value="0"
            max="100"
          />
          <button ref="muteToggle" id="mute-toggle">
            <svg
              id="volume-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              id="mute-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="display: none"
            >
              <path
                d="M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 2L22 22"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <input
            type="range"
            ref="volumeControl"
            id="volume-control"
            value="100"
            max="100"
          />
          <button ref="fullscreenToggle" id="fullscreen-toggle">
            <svg viewBox="0 0 24 24" fill="#fff">
              <path
                d="M7 14h2v2H7zm0-4h2v2H7zm4 0h2v2h-2zm-4 8h2v2H7zm8-4h2v2h-2zm0-4h2v2h-2zm-4 8h2v2h-2zm4-8h2v2h-2z"
              ></path>
            </svg>
          </button>
        </div>
        <button
          v-if="videoFile"
          class="clear-video-button"
          @click.stop="clearVideoFile"
        >
          X
        </button>
      </div>
      <input
        type="file"
        ref="uploadedFile"
        accept="video/*"
        @change="videoChange"
        style="display: none"
      />
    </div>

    <div class="title">
      <label for="title">Title</label>
      <InputAtom
        id="title"
        v-model="localResourceDetails.resource_name"
        placeholder="Name"
        class="input"
      />
    </div>

    <div class="text-editor">
      <label for="text">Description</label>
      <TinyEditor v-model="localResourceDetails.text_content" />
    </div>

    <div class="tags">
      <label for="tags">Tags (maximum 3)</label>
      <InputSelectAtom
        id="tags"
        placeholder="Add tags"
        v-model="localResourceDetails.tags"
        :options="tagOptions"
        @addNew="openAddNewTagModal"
        :tagsGetter="actionTagGetter"
        :tagSet="actionTagSet"
        :tagRemove="actionTagRemove"
      />
      <AddNewTagAtom
        :show="showAddNewTagModal"
        @update:show="showAddNewTagModal = $event"
        @newTagAdded="handleNewTagAdded"
        @addTagToSelection="addTagToSelection"
      />
    </div>

    <div class="collection">
      <label for="collection">Collection</label>
      <div v-if="selectedCollections.length > 0">
        <div
          v-for="collection in selectedCollections"
          :key="collection"
          class="collection-item"
        >
          <div class="name-and-img">
            <img
              v-if="collection.banner"
              :src="collection.banner"
              alt="Collection Image"
              class="collection-icon"
            />

            {{ collection.collection_name }}
          </div>
          <IconAtom
            icon="deleteIcon"
            @click="() => handleCollectionRemove(collection)"
            class="pointer"
          />
        </div>
      </div>
      <ButtonAtom
        class="btn btn-secondary collectionBtn"
        @click="openAddToCollectionModal"
      >
        + Add to a collection
      </ButtonAtom>
      <AddToCollectionAtom
        :show="showAddToCollectionModal"
        :collections="collectionOptions"
        @update:show="showAddToCollectionModal = $event"
        @updateCollections="handleCollectionsUpdate"
        :actionCollectionsGetter="actionCollectionsGetter"
        :actionCollectionSet="actionCollectionSet"
        :actionCollectionRemove="actionCollectionRemove"
      />
    </div>
  </div>
</template>

<style scoped>
  .video-editor-molecule {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    height: 100%;
    margin: auto;
  }

  .cover-img {
    display: flex;
    flex-direction: column;
  }

  .btn {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
  }

  .btn.coverBtn {
    height: 100px;
    width: 100px;
  }

  .clear-image-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }

  .cover-video {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .btn.videoCoverButton {
    height: 240px;
    width: 100%;
    position: relative;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn.videoCoverButton video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .clear-video-button {
    position: absolute;
    top: -10px;
    right: -10px;
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    z-index: 10;
  }

  .btn.collectionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 10px;
  }

  .editor-title {
    display: block;
    margin: 16px 0;
    font-weight: bold;
    text-align: left;
  }

  label {
    display: block;
    margin: 16px 0;
    font-weight: bold;
    text-align: left;
  }

  .title,
  .tags,
  .collection,
  .text-editor {
    display: flex;
    flex-direction: column;
  }

  .collection-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: var(--color-gray-50);
  }

  .collection-item .pointer {
    cursor: pointer;
  }

  .collection-item {
    display: flex;
    align-items: center;
  }

  .collection-icon {
    height: 40px;
    width: auto;
    margin-right: 10px;
    border-radius: 6px;
  }

  .name-and-img {
    display: flex;
    align-items: center;
  }

  .my-video {
    position: relative;
    width: 100%;
    height: auto;
  }

  .my-video video {
    width: 100%;
    display: block;
    cursor: pointer;
    border-radius: 16px;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
      to right,
      #fda99a 45%,
      #f3a9df 60%,
      #86cafe 100%
    );
    opacity: 0.8;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }

  .play-button:hover {
    opacity: 0.9;
  }

  .play-button svg {
    width: 40px;
    height: 40px;
    fill: #fff;
  }

  .video-controls {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 16px;
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    box-sizing: border-box;
  }

  .video-controls button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .video-controls button svg {
    width: 24px;
    height: 24px;
  }

  #time-display {
    font-size: 12px;
    color: #fff;
    min-width: 100px;
    text-align: center;
  }

  #progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    background: #cecfd2;
    cursor: pointer;
    margin: 0 10px;
    height: 5px;
    border-radius: 5px;
    position: relative;
    overflow: visible;
  }

  #volume-control {
    -webkit-appearance: none;
    appearance: none;
    width: 50%;
    background: #cecfd2;
    cursor: pointer;
    margin: 0 10px;
    height: 5px;
    border-radius: 5px;
    position: relative;
    overflow: visible;
  }

  #progress::-webkit-slider-runnable-track,
  #volume-control::-webkit-slider-runnable-track {
    height: 5px;
    background: transparent;
  }

  #progress::-webkit-slider-thumb,
  #volume-control::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -5px;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }

  #progress:before,
  #volume-control:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      to right,
      #fda99a 45%,
      #f3a9df 60%,
      #86cafe 100%
    );
    border-radius: 5px;
    z-index: 1;
  }

  #progress:before {
    width: var(--progress-percent, 0%);
  }

  #volume-control:before {
    width: var(--volume-percent, 100%);
  }

  #progress:focus,
  #volume-control:focus {
    outline: none;
  }

  .video-controls #volume-icon {
    width: 24px;
    height: 24px;
    stroke: #000;
    fill: none;
  }

  .my-video:hover .video-controls,
  .my-video:hover .play-button {
    opacity: 1;
  }
</style>
