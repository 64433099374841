import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AnalyticsView from '@/views/AnalyticsView.vue';
import VideosListView from '@/views/VideosListView.vue';
import VideosDetailsView from '@/views/VideosDetailsView.vue';
import AudiosListView from '@/views/AudiosListView.vue';
import AudiosDetailsView from '@/views/AudiosDetailsView.vue';
import CollectionsListView from '@/views/CollectionsListView.vue';
import CollectionsDetailsView from '@/views/CollectionsDetailsView.vue';
import GroupsListView from '@/views/GroupsListView.vue';
import GroupsDetailsView from '@/views/GroupsDetailsView.vue';
import UsersListView from '@/views/UsersListView.vue';
import NotificationListView from '@/views/NotificationListView.vue';
import NotificationDetailsView from '@/views/NotificationDetailsView.vue';
import ArticlesListView from '@/views/articles-list-view/ArticlesListView.vue';
import ArticlesDetailsView from '@/views/ArticlesDetailsView.vue';
import TagsListView from '@/views/TagsListView.vue';
import TagsDetailsView from '@/views/TagsDetailsView.vue';
import SignInView from '@/views/sign-in/SignInView.vue';
import store from '@/store';
import UsersAnalytics from '@/views/analytics/UsersAnalytics.vue';
import PerformanceAnalytics from '@/views/analytics/PerformanceAnalytics.vue';
import FinanceAnalytics from '@/views/analytics/FinanceAnalytics.vue';
import TechnicalAnalytics from '@/views/analytics/TechnicalAnalytics.vue';
import DemographicsAnalytics from '@/views/analytics/DemographicsAnalytics.vue';
import SubscriptionsAnalytics from '@/views/analytics/SubscriptionsAnalytics.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomeView,
    redirect: 'analytics',
    children: [
      {
        path: 'analytics',
        name: 'analytics',
        component: AnalyticsView,
        children: [
          {
            path: '',
            name: 'users-analytics',
            component: UsersAnalytics,
          },
          {
            path: 'subscriptions',
            name: 'subscriptions-analytics',
            component: SubscriptionsAnalytics,
          },
          {
            path: 'performance',
            name: 'performance-analytics',
            component: PerformanceAnalytics,
          },
          {
            path: 'financial',
            name: 'financial-analytics',
            component: FinanceAnalytics,
          },
          {
            path: 'technical',
            name: 'technical-analytics',
            component: TechnicalAnalytics,
          },
          {
            path: 'demographics',
            name: 'demographics-analytics',
            component: DemographicsAnalytics,
          },
        ],
      },
      {
        path: 'articlesList',
        name: 'articlesList',
        component: ArticlesListView,
      },
      {
        path: 'articlesDetails',
        name: 'articlesDetails',
        component: ArticlesDetailsView,
      },
      {
        path: 'videosList',
        name: 'videosList',
        component: VideosListView,
      },
      {
        path: 'videosDetails',
        name: 'videosDetails',
        component: VideosDetailsView,
      },
      {
        path: 'audiosList',
        name: 'audiosList',
        component: AudiosListView,
      },
      {
        path: 'audiosDetails',
        name: 'audiosDetails',
        component: AudiosDetailsView,
      },
      {
        path: 'collectionsList',
        name: 'collectionsList',
        component: CollectionsListView,
      },
      {
        path: 'collectionsDetails',
        name: 'collectionsDetails',
        component: CollectionsDetailsView,
      },
      {
        path: 'groupsList',
        name: 'groupsList',
        component: GroupsListView,
      },
      {
        path: 'groupsDetails',
        name: 'groupsDetails',
        component: GroupsDetailsView,
      },
      {
        path: 'usersList',
        name: 'usersList',
        component: UsersListView,
      },
      {
        path: 'tagsList',
        name: 'tagsList',
        component: TagsListView,
      },
      {
        path: 'tagsDetails',
        name: 'tagsDetails',
        component: TagsDetailsView,
      },
      {
        path: 'notificationList',
        name: 'notificationList',
        component: NotificationListView,
      },
      {
        path: 'notificationDetails',
        name: 'notificationDetails',
        component: NotificationDetailsView,
      },
    ],
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: SignInView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const user = store.getters['user/getUser'];

  const publicPages = ['signIn'];

  const authRequired = to.name
    ? !publicPages.includes(to.name.toString())
    : true;

  if (user && to.name === 'signIn') {
    return next({ name: 'analytics' });
  }

  if (authRequired && !user) {
    return next({ name: 'signIn' });
  }
  next();
});

export default router;
