import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-container" }

import UsersAnalyticsOrganism from '@/components/organisms/users-analytics/UsersAnalyticsOrganism.vue';
  import store from '@/store';
  import { computed, onBeforeMount, watch } from 'vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'UsersAnalytics',
  setup(__props) {

  onBeforeMount(() => {
    store.dispatch('analytics/fetchAnalyticsData');
  });

  const dateRange = computed(() => store.getters['analytics/getDateRange']);
  watch(dateRange.value, () => {
    store.dispatch('analytics/fetchAnalyticsData');
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UsersAnalyticsOrganism)
  ]))
}
}

})