import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "sidebar-main" }
const _hoisted_3 = { class: "container" }

import SidebarOrganism from '@/components/organisms/sidebar-organism/SidebarOrganism.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SidebarOrganism)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_RouterView)
    ])
  ]))
}
}

})