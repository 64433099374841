import Comment from '@/models/comment/comment.model';
import APIService from '../api.service';
import { FilterParams } from '@/shared/filterParams';

class CommentService {
  public async getAll(data: FilterParams): Promise<Comment[]> {
    return APIService.post<Comment[]>(`/comments/search`, data);
  }

  public async getById(id: string): Promise<Comment> {
    return APIService.get<Comment>(`/comment/${id}`);
  }

  public async create(data: Comment): Promise<Comment> {
    return APIService.post<Comment>(`/comment`, data);
  }

  public async update(data: Comment): Promise<Comment> {
    return APIService.put(`/comment/update/${data.comment_id}`, data);
  }

  public async delete(id: string): Promise<any> {
    return APIService.delete(`/comment/${id}`);
  }

  public async deleteMultiple(data: any): Promise<Comment[]> {
    return APIService.post(`/comments/delete/multiple`, data);
  }

  public async lookupParentComments(parentId: string): Promise<Comment[]> {
    return APIService.get<Comment[]>(
      `/comments/lookupParentComments/${parentId}`,
    );
  }
}

export default new CommentService();
