import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-main" }

import { computed, ref } from 'vue';
  import ScheduleNotificationModal from '@/components/atoms/ScheduleNotificationModal.vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TabbarOrganism from '@/components/organisms/tabbar-organism/TabbarOrganism.vue';
  import { RouterView } from 'vue-router';
  import router from '@/router';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'AnalyticsView',
  setup(__props) {

  const showAddNewTagModal = ref(false);
  const activeTab = ref('users');

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function updateActiveTab(tab: string) {
    activeTab.value = tab;
    router.push({ name: `${tab}-analytics` });
  }

  const showDatePicker = computed(() => {
    return !['technical', 'demographics'].includes(activeTab.value);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TopbarOrganism, { title: "Analytics" }),
    _createVNode(TabbarOrganism, {
      "filter-field": "",
      buttons: [
        { text: 'Usage & Users', value: 'users' },
        { text: 'Subscriptions', value: 'subscriptions' },
        { text: 'Performance', value: 'performance' },
        // { text: 'Financials', value: 'financial' },
        { text: 'Technical', value: 'technical' },
        { text: 'Demographics', value: 'demographics' },
      ],
      "model-value": { page: 1, pageSize: 10 },
      "onUpdate:activeTab": updateActiveTab,
      "show-date-picker": showDatePicker.value
    }, null, 8, ["show-date-picker"]),
    _createVNode(_unref(RouterView)),
    _createVNode(ScheduleNotificationModal, {
      show: showAddNewTagModal.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showAddNewTagModal.value = $event))
    }, null, 8, ["show"])
  ]))
}
}

})