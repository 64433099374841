import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "statusbar-container" }
const _hoisted_2 = { class: "statusbar-collection" }
const _hoisted_3 = ["to"]
const _hoisted_4 = {
  key: 1,
  class: "date-picker-container"
}

import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { FilterParams, SearchParamsAND } from '@/shared/filterParams';
  import { computed, ref, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import VueDatePicker from '@vuepic/vue-datepicker';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'TabbarOrganism',
  props: {
    filterField: {},
    buttons: {},
    detailsButtonprops: {},
    showButton: { type: Boolean },
    showDatePicker: { type: Boolean },
    modelValue: {},
    countGetter: {}
  },
  emits: [
    'update:modelValue',
    'buttonClick',
    'update:activeTab',
    'update:dateRange',
  ],
  setup(__props: any, { emit: __emit }) {

  const emits = __emit;
  const store = useStore();

  const props = __props;

  const activeButton = ref<string>(props?.buttons?.at(0)?.text || 'All');
  const filterParams = ref(props.modelValue);
  const dateRange = ref<[Date | null, Date | null]>([
    new Date(store.state.analytics.dateRange.startDate),
    new Date(store.state.analytics.dateRange.endDate),
  ]);

  const counts = computed(() =>
    props.countGetter ? store.getters[props.countGetter || ''] : null,
  );

  const toggleButton = (buttonText: string, status: string) => {
    activeButton.value = buttonText; // Update activeButton on click
    emits(
      'update:modelValue',
      new SearchParamsAND(status, props.filterField, 'equals'),
    );
    emits('update:activeTab', status);
  };

  const isActive = (buttonText: string) => {
    return activeButton.value === buttonText;
  };

  // Watch modelValue prop to update filterParams accordingly
  watch(
    () => props.modelValue,
    (newValue) => {
      filterParams.value = newValue;
    },
  );

  watch(dateRange, ([startDate, endDate]) => {
    if (startDate && endDate) {
      store.commit('analytics/SET_DATE_RANGE', { startDate, endDate });
      emits('update:dateRange', { from: startDate, to: endDate });
    }
  });

  const emitButtonClick = () => {
    emits('buttonClick', props.detailsButtonprops);
  };

  onMounted(() => {
    if (props.buttons && props.buttons.length > 0) {
      activeButton.value = props.buttons[0]?.text;
    }
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.buttons, (button) => {
        return (_openBlock(), _createBlock(ButtonAtom, {
          key: button.text,
          class: _normalizeClass(["status-btn btn btn-secondary", { active: isActive(button.text) }]),
          onClick: ($event: any) => (toggleButton(button.text, button.value))
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(button.text), 1),
            (counts.value)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["count", { 'count-active': isActive(button.text) }])
                }, _toDisplayString(counts.value[button.value === '' ? 'all' : button.value] || 0), 3))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["class", "onClick"]))
      }), 128))
    ]),
    (props.detailsButtonprops)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: emitButtonClick,
          class: "link",
          to: props.detailsButtonprops.route
        }, [
          _createVNode(ButtonAtom, {
            class: _normalizeClass(props.detailsButtonprops.buttonClass)
          }, {
            default: _withCtx(() => [
              _createVNode(IconAtom, {
                icon: props.detailsButtonprops.icon,
                color: props.detailsButtonprops.iconColor
              }, null, 8, ["icon", "color"]),
              _createElementVNode("p", null, _toDisplayString(props.detailsButtonprops.buttonText), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ], 8, _hoisted_3))
      : _createCommentVNode("", true),
    (props.showDatePicker)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_unref(VueDatePicker), {
            modelValue: dateRange.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
            range: true,
            "auto-apply": true,
            "enable-time-picker": false,
            format: 'yyyy/MM/dd',
            "max-date": new Date()
          }, null, 8, ["modelValue", "max-date"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})