import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-wrapper" }
const _hoisted_2 = { class: "video-editor" }
const _hoisted_3 = { class: "preview-container" }
const _hoisted_4 = { class: "video-preview" }

import {
    ref,
    computed,
    watch,
    nextTick,
  } from 'vue';
  import VideoEditorMolecule from '@/components/molecules/video-editor-molecules/VideoEditorMolecule.vue';
  import VideoPreviewMolecule from '@/components/molecules/video-editor-molecules/VideoPreviewMolecule.vue';
  import viewCommentsAtom from '@/components/atoms/viewCommentsAtom.vue';
  import ViewCommentsMolecule from '@/components/molecules/view-comments-molecule/ViewCommentsMolecule.vue';
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'VideoEditorOrganism',
  props: {
    initialResourceDetails: {}
  },
  emits: ['update:resourceDetails'],
  setup(__props: any, { emit: __emit }) {

  const store = useStore();

  const props = __props;

  const previewData = ref({
    resource_name: '',
    tags: [],
    text_content: '',
  });
  const showNoCommentsModal = ref(false);

  const videoFile = ref('');

  const resource = computed(() => store.getters['resource/getResource']);
  const localResourceDetails = ref({ ...props.initialResourceDetails });

  const emit = __emit;

  let isUpdating = false;

  watch(
    resource,
    (newValue) => {
      if (!isUpdating && newValue) {
        localResourceDetails.value = { ...newValue };
        updatePreviewData({ ...newValue });
      }
    },
    { immediate: true, deep: true },
  );

  const updateResourceDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('resource/SET_RESOURCE', updatedDetails);

    emit('update:resourceDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };

  function updatePreviewData(data) {
    videoFile.value = data;
  }

  function openNoCommentsModal() {
    showNoCommentsModal.value = true;
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VideoEditorMolecule, {
        resourceDetails: localResourceDetails.value,
        onUpdateResourceDetails: updateResourceDetails,
        onUpdatePreview: updatePreviewData
      }, null, 8, ["resourceDetails"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(viewCommentsAtom, {
        onOpen: openNoCommentsModal,
        resource_id: localResourceDetails.value.resource_id
      }, null, 8, ["resource_id"]),
      _createVNode(ViewCommentsMolecule, {
        show: showNoCommentsModal.value,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showNoCommentsModal.value = $event)),
        resource_id: localResourceDetails.value.resource_id
      }, null, 8, ["show", "resource_id"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(VideoPreviewMolecule, {
          previewData: localResourceDetails.value,
          videoFile: videoFile.value
        }, null, 8, ["previewData", "videoFile"])
      ])
    ])
  ]))
}
}

})