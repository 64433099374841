import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, onMounted, onUnmounted } from 'vue';
  import InputAtom from './InputAtom.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import store from '@/store';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'AddNewTagAtom',
  props: {
    show: Boolean,
  },
  emits: [
    'newTagAdded',
    'closeModal',
    'update:show',
    'addTagToSelection',
  ],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const newTagName = ref('');
  const emits = __emit;

  async function handleAdd() {
    if (newTagName.value.trim() !== '') {
      const newTag = await store.dispatch('tag/createItem', {
        tag_name: newTagName.value,
        tag_id: null,
      });

      if (newTag) {
        emits('addTagToSelection', {
          tag_id: newTag.tag_id,
          tag_name: newTag.tag_name,
        });
      }
    }
    newTagName.value = '';
    emits('newTagAdded');
  }

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "new-tag-modal-overlay",
        onClick: closeModal
      }, [
        _createElementVNode("div", {
          class: "new-tag-modal",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "newTagTitle" }, "Add new tag", -1)),
          _createVNode(IconAtom, {
            class: "close-icon",
            icon: "closeIcon",
            onClick: closeModal
          }),
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "newTagInput" }, "Name", -1)),
          _createVNode(InputAtom, {
            id: "newTagInput",
            modelValue: newTagName.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newTagName).value = $event)),
            placeholder: "Name",
            class: "input"
          }, null, 8, ["modelValue"]),
          _createVNode(ButtonAtom, {
            class: "btn btn-primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Add")
            ])),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})