import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CollectionsListView',
  setup(__props) {

const buttons = [
  { text: 'All', count: 12 },
  { text: 'Published', count: 0 },
  { text: 'Drafts', count: 0 },
  { text: 'Trash', count: 0 },
];

const detailsButtonprops = {
  route: '/collectionsDetails',
  buttonClass: 'btn btn-primary',
  icon: 'plusIcon',
  iconColor: 'white',
  buttonText: 'New Collection',
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(TopbarOrganism, { title: "Collections" }),
    _createVNode(TableMolecule, { dataType: "Collection" })
  ]))
}
}

})