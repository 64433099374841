import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, onMounted, onUnmounted } from 'vue';
  import InputAtom from './InputAtom.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePasswordModal',
  props: {
    show: Boolean,
  },
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const newTagName = ref('');
  const emits = __emit;

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "change-password-modal-overlay",
        onClick: closeModal
      }, [
        _createElementVNode("div", {
          class: "change-password-modal",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "change-password-title" }, "Change Password", -1)),
          _createVNode(IconAtom, {
            class: "close-icon",
            icon: "closeIcon",
            onClick: closeModal
          }),
          _createVNode(InputAtom, {
            id: "changePassword",
            placeholder: "Current Password",
            class: "input"
          }),
          _createVNode(InputAtom, {
            id: "changePassword",
            placeholder: "New Password",
            class: "input"
          }),
          _createVNode(InputAtom, {
            id: "changePassword",
            placeholder: "Confirm Password",
            class: "input"
          }),
          _createVNode(ButtonAtom, { class: "btn btn-primary" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Submit")
            ])),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})