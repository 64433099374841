<template>
  <div class="analytics-container">
    <SubscriptionsAnalyticsOrganism />
  </div>
</template>

<script setup lang="ts">
  import SubscriptionsAnalyticsOrganism from '@/components/organisms/subscriptions-analytics/SubscriptionsAnalyticsOrganism.vue';
  import store from '@/store';
  import { onBeforeMount } from 'vue';

  onBeforeMount(() => {
    store.dispatch('analytics/fetchSubscriptionsAnalytics');
  });
</script>
