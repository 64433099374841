<template>
  <div class="analytics-grid">
    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.activeUsers?.daily"
      name="Daily active users"
      icon="analyticsUsers"
      date-picker
      :custom-evaluation-period="
        new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      "
    />

    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.activeUsers?.newUsers"
      name="New users in selected date range"
      icon="analyticsUsers"
    />
    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.activeUsers?.monthly"
      name="Active users in selected date range"
      icon="analyticsUsers"
    />
  </div>
  <div class="analytics-grid-2-cols">
    <BaseAnalyticsCardMolecule name="Churn rate" icon="analyticsUsers" />
    <BaseAnalyticsCardMolecule
      :count="analyticsData?.totalDownloads"
      name="Installs in selected date range"
      icon="download-icon"
    />
  </div>
  <div class="analytics-grid-2-cols">
    <AnalyticsCardOrganism
      title="iOS app downloads"
      header-subtitle="Total downloads"
    >
      <template #body>
        <div class="app-analytics-body">
          <h3>
            {{ analyticsData?.app_downloads_ios || 'n/a' }}
          </h3>
        </div>
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism
      title="Android app downloads"
      header-subtitle="Total downloads"
    >
      <template #body>
        <div class="app-analytics-body">
          <h3>
            {{ analyticsData?.app_downloads_android || 'n/a' }}
          </h3>
        </div>
      </template>
    </AnalyticsCardOrganism>
  </div>
  <div class="analytics-grid">
    <AnalyticsCardOrganism title="User Growth Rate" class="colspan-2">
      <template #body>
        <LineChart
          v-if="analyticsData?.userGrowthRate"
          :data="analyticsData?.userGrowthRate"
        />
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism title="Retention Rate">
      <template #body>
        <BarChart
          :data="[
            { label: '30d', data: 10 },
            { label: '60d', data: 20 },
            { label: '90d', data: 30 },
          ]"
        />
      </template>
    </AnalyticsCardOrganism>
  </div>
</template>

<script setup lang="ts">
  import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import { computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import PieChart from '../charts/PieChart.vue';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';
  import BarChart from '../charts/BarChart.vue';
  import LineChart from '../charts/LineChart.vue';

  const store = useStore();
  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

  watch(analyticsData, () => {
    console.log(analyticsData.value);
  });
</script>

<style scoped src="./style.css" />
