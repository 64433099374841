import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-select-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["id", "placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "dropdown-list"
}
const _hoisted_5 = ["onClick"]

import { SelectOption } from '@/shared/selectOption';
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'InputSelectAtom',
  props: {
    id: {},
    placeholder: {},
    options: {},
    tagsGetter: {},
    tagSet: {},
    tagRemove: {}
  },
  emits: ['addNew', 'update:modelValue'],
  setup(__props: any, { emit: __emit }) {

  const store = useStore();

  const props = __props;

  const emits = __emit;

  const showDropdown = ref(false);
  const userInput = ref('');

  // Get the selected tags using the Vuex store getter
  const selectedTags = computed(() => store.getters[props.tagsGetter]); // Ensure it's never undefined
  const rawSelectedTags = computed(() => [...selectedTags.value]);

  function handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    userInput.value = target.value;
  }

  const filteredOptions = computed(() => {
    return (
      props.options?.filter(
        (option: SelectOption) =>
          option.value.toLowerCase().includes(userInput.value.toLowerCase()) &&
          !selectedTags.value.map((tag) => tag.id).includes(option.id),
      ) || []
    );
  });

  function selectOption(option: SelectOption) {
    if (selectedTags.value.length < 3) {
      store.dispatch(props.tagSet, option);
      userInput.value = '';
    }
    showDropdown.value = false;
    emits('update:modelValue', selectedTags.value);
  }

  function removeTag(tag: SelectOption) {
    store.dispatch(props.tagRemove, tag);
    emits('update:modelValue', selectedTags.value);
  }

  function addNew() {
    emits('addNew', userInput.value);
    showDropdown.value = false;
  }

  function handleBlur() {
    setTimeout(() => {
      showDropdown.value = false;
    }, 200);
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "tags-input",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (showDropdown.value = true))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rawSelectedTags.value, (tag) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tag.id,
          class: "tag"
        }, [
          _createTextVNode(_toDisplayString(tag.value) + " ", 1),
          _createElementVNode("span", {
            class: "remove-tag",
            onClick: _withModifiers(($event: any) => (removeTag(tag)), ["stop"])
          }, "×", 8, _hoisted_2)
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("input", {
        class: "input",
        id: props.id,
        placeholder: rawSelectedTags.value?.length === 0 ? props.placeholder : '',
        onInput: handleInput,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userInput).value = $event)),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (showDropdown.value = true)),
        onBlur: handleBlur
      }, null, 40, _hoisted_3), [
        [_vModelText, userInput.value]
      ])
    ]),
    (showDropdown.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "dropdown-item",
            onClick: addNew
          }, "+ Add new"),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptions.value, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.id,
              class: "dropdown-item",
              onClick: ($event: any) => (selectOption(option))
            }, _toDisplayString(option.value), 9, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})