import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 4 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex-container" }
const _hoisted_5 = { class: "checkbox-label" }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 3,
  class: "flex-container"
}
const _hoisted_13 = {
  key: 4,
  class: "flex-container"
}
const _hoisted_14 = { class: "checkbox-label" }
const _hoisted_15 = ["checked", "disabled"]
const _hoisted_16 = { key: 8 }
const _hoisted_17 = { class: "checkbox-label" }
const _hoisted_18 = ["checked", "disabled"]
const _hoisted_19 = { class: "pagination" }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = ["onClick", "disabled"]
const _hoisted_22 = ["disabled"]

import { ref, computed, onMounted, watch } from 'vue';
  import { useStore } from 'vuex';
  import { ColumnConfigurationInterface } from '@/shared/columnConfiguration.interface';
  import {
    FilterParams,
    SearchParamsAND,
    AdditionalParams,
  } from '@/shared/filterParams';
  import { tableConfigurations } from '@/shared/tableConfigurations';
  import Resource from '@/models/resource/resource.model';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { useRoute, useRouter } from 'vue-router';
  import NoDataMolecule from '../no-data-molecule/NoDataMolecule.vue';
  import { NoDataStateProps } from '@/shared/noDataStateProps';
  import SearchbarMolecule from '../searchbar-molecule/SearchbarMolecule.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import TabbarOrganism from '@/components/organisms/tabbar-organism/TabbarOrganism.vue';
  import userDefaultImage from '@/assets/default-user-img.png';
  import defaultImage from '@/assets/default-image.png';
  import UserActionModal from '../user-action-modal/UserActionModal.vue';
  import ViewCommentsMolecule from '@/components/molecules/view-comments-molecule/ViewCommentsMolecule.vue';
  import NotificationActionModal from '../notification-action-modal/NotificationActionModal.vue';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'TableMolecule',
  props: {
    dataType: {},
    filterOptions: {},
    dynamicDefaultFilter: {},
    dynamicAdditionalFilter: {},
    onActionClick: { type: Function },
    type: {},
    currentId: {}
  },
  emits: ['update:selectedRows', 'navigateToParent'],
  setup(__props: any, { emit: __emit }) {

  const emits = __emit;

  const props = __props;
  const route = useRoute();
  const router = useRouter();

  const store = useStore();
  const currentPage = ref(1);
  const userActionModal = ref(false);
  const notificationActionModal = ref(false);
  const selectedId = ref<string>('');
  const selectedDataType = ref<string>('');
  const activeButton = ref<string>('');
  const showNoCommentsModal = ref(false);
  const filterParams = ref(
    new FilterParams(
      1,
      10,
      { searchTerm: '' },
      tableConfigurations[props.dataType]?.defaultFilters ??
        props.dynamicDefaultFilter ??
        [],
      props.dynamicAdditionalFilter || undefined,
    ),
  );
  const statusBarSelection = ref();
  const selectedOptions = ref<string[]>([]);

  const showResourcesModal = ref(false);

  const actionButton = ref<any>(null);

  const tableActionButtons = () => {
    const buttonCount =
      tableConfigurations[props.dataType]?.tableActionButtonProps?.length ?? 0;
    const buttons =
      tableConfigurations[props.dataType]?.tableActionButtonProps ?? [];

    if (buttonCount > 0) {
      if (buttonCount == 1 && selectedOptions.value.length == 0) {
        actionButton.value = null;
      } else if (selectedOptions.value.length > 0 && buttonCount > 1) {
        actionButton.value = buttons[1];
      } else {
        actionButton.value = buttons[0];
      }
    } else {
      actionButton.value = null;
    }
  };

  const columnConfiguration = ref<ColumnConfigurationInterface[]>(
    tableConfigurations[props.dataType].columns,
  );

  const noDataStateProps = computed<NoDataStateProps>(() => {
    return tableConfigurations[props.dataType].noDataStateProps;
  });

  const rowsData = computed(() => {
    return store.getters[
      `${tableConfigurations[props.dataType].storeActions.items}`
    ];
  });

  const totalPages = computed(() => {
    return store.getters[
      `${tableConfigurations[props.dataType].storeActions.totalPages}`
    ];
  });

  let isUpdating = false;

  watch(selectedOptions, (newVal) => {
    tableActionButtons();
  });

  function getFilterParams(): FilterParams {
    const newFilterParams = structuredClone(filterParams.value);
    newFilterParams.searchParamsAND = newFilterParams.searchParamsAND?.filter(
      (item) => {
        return (
          item.searchField != tableConfigurations[props.dataType].tabs.field
        );
      },
    );

    if (statusBarSelection.value && statusBarSelection.value.searchTerm != '') {
      newFilterParams.searchParamsAND?.push(statusBarSelection.value);
    }

    newFilterParams.searchParamsAND = [
      ...(newFilterParams.searchParamsAND || []),
      ...(tableConfigurations[props.dataType]?.defaultFilters || []),
      ...(props.dynamicDefaultFilter || []),
    ];

    if (props.dynamicAdditionalFilter) {
      newFilterParams.additionalParams = props.dynamicAdditionalFilter;
    }

    if (statusBarSelection.value && statusBarSelection.value.searchTerm != '') {
      if (newFilterParams.searchParamsAND) {
        newFilterParams.searchParamsAND?.push(statusBarSelection.value);
      } else {
        newFilterParams.searchParamsAND = [statusBarSelection.value];
      }
    }

    return newFilterParams;
  }

  const createActionButtuon = (
    buttonName: string,
    dataModel: string,
    actionName: string,
  ) => {
    columnConfiguration.value = [
      ...tableConfigurations[props.dataType].columns,
      {
        actionName: buttonName,
        actionHandler: async (data: any) => {
          await store.dispatch(dataModel + actionName, {
            id: data[`${dataModel}_id`],
            filters: { ...filterParams.value },
          });
        },
      },
    ];
  };

  const openCommentsModal = (
    dataType: string,
    id: string,
    isCommentColumn: boolean,
  ) => {
    if (isCommentColumn) {
      selectedDataType.value = dataType;
      selectedId.value = id;
      showNoCommentsModal.value = true;
    }
  };

  const openDiscussionModal = () => {
    store.commit('group/SHOW_DISCUSSION_MODAL', true);
  };

  watch(
    () => props.dynamicDefaultFilter,
    (newVal) => {
      if (isUpdating) return;
      const newFilterParams = getFilterParams();

      isUpdating = true;
      currentPage.value = 1;
      fetchData(newFilterParams).finally(() => {
        isUpdating = false;
      });
    },
    { deep: true },
  );

  watch(
    filterParams,
    (newVal) => {
      if (isUpdating) return;
      const newFilterParams = getFilterParams();

      isUpdating = true;
      currentPage.value = newFilterParams.page;
      fetchData(newFilterParams).finally(() => {
        isUpdating = false;
      });
    },
    { deep: true },
  );

  watch(statusBarSelection, (newVal) => {
    if (
      [
        'Article',
        'Video',
        'Audio',
        'Group',
        'User',
        'Collection',
        'Discussion',
      ].includes(props.dataType)
    ) {
      const dataModel = ['Article', 'Video', 'Audio'].includes(props.dataType)
        ? 'resource'
        : props.dataType.toLowerCase();

      switch (statusBarSelection.value.searchTerm) {
        case 'draft':
          createActionButtuon('Publish', dataModel, '/publishItem');
          break;
        case 'unpublished':
        case 'banned':
          createActionButtuon('Restore', dataModel, '/restoreItem');
          break;
        case 'inactive':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) => column.title != 'Actions',
            ),
          ];
          break;
        default:
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns,
          ];
          break;
      }
    }
    if (props.dataType == 'Notification') {
      switch (statusBarSelection.value.searchTerm) {
        case 'sent':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) =>
                column.title != 'Scheduled' &&
                column.title != 'Repeat' &&
                column.title != 'Last Modified',
            ),
          ];
          break;
        case 'draft':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) =>
                column.title != 'Scheduled' &&
                column.title != 'Repeat' &&
                column.title != 'Time Sent' &&
                column.title != 'Last Modified',
            ),
          ];
          break;
        case 'scheduled':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) => column.title != 'Time Sent',
            ),
          ];
          break;
        default:
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns,
          ];
          break;
      }
    }

    const newFilterParams = filterParams.value;
    newFilterParams.page = 1;
    filterParams.value = { ...newFilterParams };
  });

  const deleteComment = (comment_id, event) => {
    event.stopPropagation();

    store.dispatch('comment/deleteItems', { data: [comment_id] });
    fetchData(getFilterParams());
  };

  const openActionModal = (actionType: string, id: string, event) => {
    if (actionType == 'edit') {
      if (props.dataType == 'Notification') {
        openNotificationActionsModal(id, event);
      } else {
        openUserActionsModal(id, event);
      }
    } else if (actionType == 'viewParentComments') {
      emits('navigateToParent', id);
    } else {
      deleteComment(id, event);
    }
  };

  const setPage = (page: number) => {
    if (page >= 1 && page <= totalPages.value) {
      currentPage.value = page;
      filterParams.value.page = page;
    }
  };

  const visiblePages = computed(() => {
    const pages: (number | string)[] = [];
    const current = currentPage.value;
    const total = totalPages.value;
    const delta = 2;

    if (total <= 1) return [1];

    pages.push(1);

    const rangeStart = Math.max(2, current - delta);
    const rangeEnd = Math.min(total - 1, current + delta);

    if (rangeStart > 2) {
      pages.push('...');
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(i);
    }

    if (rangeEnd < total - 1) {
      pages.push('...');
    }

    pages.push(total);

    return pages;
  });

  const fetchData = async (params: FilterParams = getFilterParams()) => {
    await tableConfigurations[props.dataType].fetchData(store, params);
  };

  // Fetch data when the component is mounted
  onMounted(() => {
    tableActionButtons();
    let params = JSON.parse(JSON.stringify(filterParams.value));

    params.searchParamsAND = [
      ...(tableConfigurations[props.dataType].initialFilters ?? []),
      ...(params.searchParamsAND || []),
    ];

    if (props.dataType === 'Notification') {
      columnConfiguration.value = [
        ...tableConfigurations[props.dataType].columns.filter(
          (column) =>
            column.title !== 'Scheduled' &&
            column.title !== 'Repeat' &&
            column.title !== 'Last Modified',
        ),
      ];
      params.searchParamsAND = [
        { searchTerm: 'sent', searchField: 'status', action: 'equals' },
      ];
    }

    fetchData(params);
  });

  const getProperty = (element, propName) => {
    if (!element || typeof element !== 'object') {
      return element;
    }
    return resolveProperty(element, propName.split('.'));
  };
  const resolveUpdatedAt = (obj: any): string => {
    var label = 'Last Modified: ';
    if (obj instanceof Resource) {
      if (obj.is_published) {
        label = 'Published: ';
      }
    }

    const date = new Date(obj.updated_at);
    const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '/');

    return label + formattedDate;
  };

  const resolveDate = (date: Date): string => {
    if (!date) {
      return '';
    }
    const dateObj = new Date(date);
    const formattedDate = dateObj
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '/');

    return formattedDate;
  };

  const resolveDateTime = (dateTime: Date): string => {
    const time = new Date(dateTime);
    const date = resolveDate(dateTime);
    return (
      date +
      ', ' +
      time.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    );
  };

  const cutArray = (array, showMax) => {
    if (!showMax) {
      return array;
    }
    if (array.length > showMax) {
      const cutArray = array.slice(0, showMax - 1);
      cutArray.push(`+${array.length - showMax + 1}`);
      return cutArray;
    }
    return array;
  };

  const resolveProperty = (obj, propNameSplit): string => {
    if (propNameSplit.length > 1) {
      return resolveProperty(obj[propNameSplit[0]], propNameSplit.slice(1));
    } else {
      return obj[propNameSplit[0]];
    }
  };

  const openNotificationActionsModal = (notification_id, event) => {
    event.stopPropagation();
    notificationActionModal.value = true;
    selectedId.value = notification_id;
  };

  const handleNotificationAction = () => {
    notificationActionModal.value = false;
  };

  const openUserActionsModal = (user_id, event) => {
    event.stopPropagation();
    store.dispatch('user/selectUser', user_id);
    userActionModal.value = true;
    selectedId.value = user_id;
  };

  const handleUserAction = (action) => {
    store.dispatch('user/' + action, {
      id: selectedId.value,
      filters: filterParams.value || {},
    });
    userActionModal.value = false;
  };

  const settingsOnClick = (url, id) => {
    router.push({ path: url, query: { id } });
  };

  const emitSelectedOptions = () => {
    emits('update:selectedRows', selectedOptions.value);
  };

  const openResourcesModal = () => {
    showResourcesModal.value = true;
  };

  const deleteResources = () => {
    console.log('deleteResources');
  };

  const deleteSelected = () => {
    let payload = {
      data: selectedOptions.value,
    };
    if (
      (props.dataType === 'ConnectedResources' ||
        props.dataType === 'NotConnectedResources') &&
      filterParams.value.additionalParams
    ) {
      payload = {
        ...payload,
        [filterParams.value.additionalParams.name as string]: route.query.id,
      };
    }
    if (!tableConfigurations[props.dataType].storeActions.deleteAction) {
      console.error(
        'To use table delete functionality you have to provide deleteAction in storeActions for the table configuration.',
      );
    }
    store
      .dispatch(
        `${tableConfigurations[props.dataType].storeActions.deleteAction}`,
        payload,
      )
      .then(() => {
        fetchData(getFilterParams());
        selectedOptions.value = [];
      });
  };

  const handleClick = (buttonProps) => {
    const functionsMap = {
      deleteSelected,
      openResourcesModal,
      deleteResources,
      openDiscussionModal,
    };
    if (buttonProps.action != undefined) {
      const fnName = buttonProps.action;
      if (functionsMap[fnName]) {
        functionsMap[fnName]();
        if (fnName === 'openResourcesModal' && props.onActionClick) {
          props.onActionClick();
        }
      } else {
        console.error(`Function ${fnName} not found!`);
      }
    } else {
      router.push(buttonProps.route);
    }
  };

  const refreshSentNotifications = () => {
    statusBarSelection.value = {
      searchTerm: 'sent',
      searchField: 'status',
      action: 'equals',
    };

    fetchData(getFilterParams());
  };

  const handleRowClick = (row: any) => {
    openCommentsModal(props.dataType, row.discussion_id, true);
  };

  const shouldShowParentCommentIcon = (column: any, row: any) => {
    if (column.action !== 'viewParentComments') return true;

    if (props.type === 'parent_comment') {
      return row.parent_comment_id && row.parent_comment_id !== props.currentId;
    } else {
      return row.parent_comment_id;
    }
  };

  const getActionIcon = (action: string) => {
    return action === 'edit' || action === 'notification_action'
      ? 'threeDotsIcon'
      : 'deleteIcon';
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showNoCommentsModal.value)
      ? (_openBlock(), _createBlock(ViewCommentsMolecule, {
          key: 0,
          show: showNoCommentsModal.value,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showNoCommentsModal.value = $event)),
          resource_id: selectedId.value,
          type: selectedDataType.value
        }, null, 8, ["show", "resource_id", "type"]))
      : _createCommentVNode("", true),
    (_unref(tableConfigurations)[props.dataType].tabs)
      ? (_openBlock(), _createBlock(TabbarOrganism, {
          key: 1,
          buttons: _unref(tableConfigurations)[props.dataType].tabs.fields,
          countGetter: _unref(tableConfigurations)[props.dataType].tabs.countGetter,
          detailsButtonprops: actionButton.value,
          modelValue: statusBarSelection.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((statusBarSelection).value = $event)),
          activeButton: activeButton.value,
          "onUpdate:activeButton": _cache[2] || (_cache[2] = ($event: any) => ((activeButton).value = $event)),
          onButtonClick: handleClick,
          filterField: _unref(tableConfigurations)[props.dataType].tabs.field
        }, null, 8, ["buttons", "countGetter", "detailsButtonprops", "modelValue", "activeButton", "filterField"]))
      : _createCommentVNode("", true),
    (_ctx.type !== 'parent_comment')
      ? (_openBlock(), _createBlock(SearchbarMolecule, {
          key: 2,
          modelValue: filterParams.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filterParams).value = $event)),
          showFilter: 
      _unref(tableConfigurations)[props.dataType].filterSettings?.title ?? false
    ,
          filterTitle: 
      _unref(tableConfigurations)[props.dataType].filterSettings?.title ?? ''
    ,
          filterOptions: props.filterOptions
        }, null, 8, ["modelValue", "showFilter", "filterTitle", "filterOptions"]))
      : _createCommentVNode("", true),
    (!rowsData.value || rowsData.value.length == 0)
      ? (_openBlock(), _createBlock(NoDataMolecule, {
          key: 3,
          noDataButtonProps: noDataStateProps.value,
          icon: "bookIcon",
          onButtonClick: handleClick
        }, null, 8, ["noDataButtonProps"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                (_unref(tableConfigurations)[props.dataType].rowSelection)
                  ? (_openBlock(), _createElementBlock("th", _hoisted_2))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columnConfiguration.value, (column) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: column.propertyName
                  }, _toDisplayString(column.title), 1))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rowsData.value, (row) => {
                return (_openBlock(), _createElementBlock("tr", _mergeProps({
                  key: row.id,
                  class: { 'clickable-row': props.dataType === 'GroupDiscussion' }
                }, _toHandlers(
            props.dataType === 'GroupDiscussion'
              ? { click: () => handleRowClick(row) }
              : {}
          , true)), [
                  (_unref(tableConfigurations)[props.dataType].rowSelection)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("label", _hoisted_5, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              class: "table-row-checkbox-input",
                              value: 
                    row[
                      _unref(tableConfigurations)[props.dataType].rowSelection
                        .rowSelectionProperty
                    ]
                  ,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedOptions).value = $event)),
                              onChange: emitSelectedOptions,
                              onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
                            }, null, 40, _hoisted_6), [
                              [_vModelCheckbox, selectedOptions.value]
                            ]),
                            _createElementVNode("span", {
                              class: "table-custom-checkbox",
                              onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
                            }),
                            _cache[16] || (_cache[16] = _createElementVNode("span", { class: "table-checkbox-label" }, null, -1))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columnConfiguration.value, (column) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: column.propertyName
                    }, [
                      (column.settings)
                        ? (_openBlock(), _createBlock(IconAtom, {
                            key: 0,
                            icon: "settingIcon",
                            onClick: ($event: any) => (
                  settingsOnClick(
                    column.settingsUrl,
                    row[column.settingsIdProperty],
                  )
                )
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (column.action)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (shouldShowParentCommentIcon(column, row))
                              ? (_openBlock(), _createBlock(IconAtom, {
                                  key: 0,
                                  icon: column.icon || getActionIcon(column.action),
                                  class: "three-dots-icon",
                                  onClick: _withModifiers(($event: any) => (
                  openActionModal(
                    column.action,
                    row[column.settingsIdProperty],
                    $event,
                  )
                ), ["stop"])
                                }, null, 8, ["icon", "onClick"]))
                              : _createCommentVNode("", true),
                            (
                  userActionModal.value &&
                  row[column.settingsIdProperty] == selectedId.value
                )
                              ? (_openBlock(), _createBlock(UserActionModal, {
                                  key: 1,
                                  onClose: _cache[7] || (_cache[7] = ($event: any) => (userActionModal.value = false)),
                                  onAction: handleUserAction,
                                  "is-visible": userActionModal.value
                                }, null, 8, ["is-visible"]))
                              : _createCommentVNode("", true),
                            (
                  notificationActionModal.value &&
                  row[column.settingsIdProperty] == selectedId.value
                )
                              ? (_openBlock(), _createBlock(NotificationActionModal, {
                                  key: 2,
                                  onClose: _cache[8] || (_cache[8] = ($event: any) => (notificationActionModal.value = false)),
                                  onAction: handleNotificationAction,
                                  onRefresh: _cache[9] || (_cache[9] = ($event: any) => (refreshSentNotifications())),
                                  "is-visible": notificationActionModal.value,
                                  activeButton: activeButton.value,
                                  "notification-id": selectedId.value
                                }, null, 8, ["is-visible", "activeButton", "notification-id"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.actionName)
                        ? (_openBlock(), _createBlock(ButtonAtom, {
                            key: 2,
                            class: "btn btn-secondary",
                            onClick: ($event: any) => (column.actionHandler(row))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(column.actionName), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            class: "flex-container",
                            onClick: ($event: any) => (
                  openCommentsModal(
                    props.dataType,
                    row[
                      _unref(tableConfigurations)[props.dataType].rowSelection
                        ?.rowSelectionProperty
                    ],
                    column.title === 'Comments' ? true : false,
                  )
                )
                          }, [
                            (column.imageProperty && row[column.imageProperty])
                              ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                  _createElementVNode("img", {
                                    class: _normalizeClass(column.userImage ? 'user-image' : 'row-image'),
                                    src: row[column.imageProperty]
                                  }, null, 10, _hoisted_9)
                                ]))
                              : _createCommentVNode("", true),
                            (column.imageProperty && !row[column.imageProperty])
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                  _createElementVNode("img", {
                                    class: _normalizeClass(column.userImage ? 'user-image' : 'row-image'),
                                    src: column.userImage ? _unref(userDefaultImage) : _unref(defaultImage)
                                  }, null, 10, _hoisted_11)
                                ]))
                              : _createCommentVNode("", true),
                            (column.icon != null && !column.action)
                              ? (_openBlock(), _createBlock(IconAtom, {
                                  key: 2,
                                  icon: column.icon
                                }, null, 8, ["icon"]))
                              : _createCommentVNode("", true),
                            (Array.isArray(row[column.propertyName]))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cutArray(
                      row[column.propertyName],
                      column.arrayShowMax,
                    ), (element, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: _normalizeClass([column.class, 'row-text']),
                                      key: index
                                    }, _toDisplayString(getProperty(element, column.visibleProperty)), 3))
                                  }), 128))
                                ]))
                              : (typeof row[column.propertyName] === 'object')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    (column.type && column.type == 'checkbox')
                                      ? (_openBlock(), _createElementBlock("div", {
                                          key: column.propertyName
                                        }, [
                                          _createElementVNode("label", _hoisted_14, [
                                            _createElementVNode("input", {
                                              type: "checkbox",
                                              class: "table-row-checkbox-input",
                                              checked: 
                          row[column.propertyName]?.[column.visibleProperty]
                        ,
                                              disabled: column.disabled,
                                              onClick: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"]))
                                            }, null, 8, _hoisted_15),
                                            _createElementVNode("span", {
                                              class: "table-custom-checkbox",
                                              onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"]))
                                            })
                                          ])
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", {
                                          class: _normalizeClass([column.class, 'row-text']),
                                          key: column.propertyName
                                        }, _toDisplayString(getProperty(
                        row[column.propertyName],
                        column.visibleProperty,
                      )), 3))
                                  ]))
                                : (column.propertyName == 'updated_at')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 5,
                                      class: _normalizeClass([column.class, 'row-text'])
                                    }, _toDisplayString(resolveUpdatedAt(row)), 3))
                                  : (column.type && column.type == 'date')
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 6,
                                        class: _normalizeClass([column.class, 'row-text'])
                                      }, _toDisplayString(resolveDate(row[column.propertyName])), 3))
                                    : (column.type && column.type == 'datetime')
                                      ? (_openBlock(), _createElementBlock("div", {
                                          key: 7,
                                          class: _normalizeClass([column.class, 'row-text'])
                                        }, _toDisplayString(resolveDateTime(row[column.propertyName])), 3))
                                      : (column.propertyName == 'user_status')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                            _createElementVNode("div", {
                                              class: _normalizeClass({
                      'status-active-wrapper':
                        row[column.propertyName] == 'active',
                    })
                                            }, [
                                              _createElementVNode("span", {
                                                class: _normalizeClass(["status-text", {
                        'status-border': row[column.propertyName] != 'active',
                        'status-color': row[column.propertyName] == 'banned',
                      }])
                                              }, [
                                                (row[column.propertyName] == 'banned')
                                                  ? (_openBlock(), _createBlock(IconAtom, {
                                                      key: 0,
                                                      icon: "banIcon",
                                                      class: "status-color"
                                                    }))
                                                  : _createCommentVNode("", true),
                                                _createTextVNode(" " + _toDisplayString(row[column.propertyName]), 1)
                                              ], 2)
                                            ], 2)
                                          ]))
                                        : (
                    column.visibleOn &&
                    column.visibleOn == statusBarSelection.value?.searchTerm
                  )
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 9,
                                              class: _normalizeClass([column.class, 'row-text'])
                                            }, _toDisplayString(row[column.propertyName]), 3))
                                          : (column.type == 'checkbox')
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 10,
                                                class: _normalizeClass([column.class, 'row-text'])
                                              }, [
                                                _createElementVNode("label", _hoisted_17, [
                                                  _createElementVNode("input", {
                                                    type: "checkbox",
                                                    class: "table-row-checkbox-input",
                                                    checked: row[column.propertyName],
                                                    disabled: column.disabled,
                                                    onClick: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"]))
                                                  }, null, 8, _hoisted_18),
                                                  _createElementVNode("span", {
                                                    class: "table-custom-checkbox",
                                                    onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"]))
                                                  })
                                                ])
                                              ], 2))
                                            : (_openBlock(), _createElementBlock("div", {
                                                key: 11,
                                                class: _normalizeClass([column.class, 'row-text'])
                                              }, _toDisplayString(row[column.propertyName]), 3))
                          ], 8, _hoisted_7))
                    ]))
                  }), 128))
                ], 16))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("button", {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (setPage(currentPage.value - 1))),
              disabled: currentPage.value === 1
            }, " « ", 8, _hoisted_20),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visiblePages.value, (page) => {
              return (_openBlock(), _createElementBlock("button", {
                key: page,
                onClick: ($event: any) => (typeof page === 'number' && setPage(page)),
                disabled: currentPage.value === page || typeof page !== 'number',
                class: _normalizeClass({ active: currentPage.value === page })
              }, _toDisplayString(page), 11, _hoisted_21))
            }), 128)),
            _createElementVNode("button", {
              onClick: _cache[15] || (_cache[15] = ($event: any) => (setPage(currentPage.value + 1))),
              disabled: currentPage.value >= totalPages.value
            }, " » ", 8, _hoisted_22)
          ])
        ]))
  ], 64))
}
}

})