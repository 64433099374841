import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "view-comments-container" }
const _hoisted_2 = { class: "share" }

import commentsIcon from '@/assets/svg/commentsIcon.vue';
  import shareIcon from '@/assets/svg/shareIcon.vue';
  import { computed } from 'vue';
  
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'viewCommentsAtom',
  props: {
    resource_id: String,
  },
  emits: ['open'],
  setup(__props, { emit: __emit }) {

  const emit = __emit;

  const props = __props;
  const store = useStore();
  const comments = computed(() => store.getters['comment/getCount']);

  const handleClick = () => {
    emit('open');
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "comments comments-icon",
      onClick: handleClick
    }, [
      _createVNode(commentsIcon),
      _createElementVNode("h4", null, "View post comments (" + _toDisplayString(comments.value?.toString() ?? 0) + ")", 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(shareIcon, {
        color: "grey",
        class: "comments-icon"
      })
    ])
  ]))
}
}

})