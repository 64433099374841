<template>
  <PerformanceAnalyticsOrganism />
</template>

<script setup lang="ts">
  import PerformanceAnalyticsOrganism from '@/components/organisms/performance-analytics/PerformanceAnalyticsOrganism.vue';
  import store from '@/store';
  import { computed, onBeforeMount, watch } from 'vue';
  onBeforeMount(() => {
    store.dispatch('analytics/fetchPerformanceAnalytics');
  });

  const dateRange = computed(() => store.getters['analytics/getDateRange']);
  watch(dateRange.value, () => {
    store.dispatch('analytics/fetchPerformanceAnalytics');
  });
</script>
