import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import VideoEditorOrganism from '@/components/organisms/video-editor-organism/VideoEditorOrganism.vue';
  import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Resource from '@/models/resource/resource.model';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'VideosDetailsView',
  setup(__props) {

  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const resource = computed(() => store.getters['resource/getResource']);

  const loading = ref(true);

  let topbarPrimaryButtonText = '';
  let topbarSecondaryButtonText = '';
  let topbarPrimaryButtonHandler;
  let topbarSecondaryButtonHandler;

  onMounted(() => {
    if (route.query.id) {
      store.dispatch('resource/fetchItemDetails', route.query.id).then(() => {
        loading.value = false;
      });
      topbarPrimaryButtonText = 'Save';
      topbarSecondaryButtonText = 'Cancel';
      topbarSecondaryButtonHandler = cancelHandler;
      topbarPrimaryButtonHandler = publish;
    } else {
      store.commit(
        'resource/SET_RESOURCE',
        new Resource({ resource_type: 'video' }),
      );
      loading.value = false;
      topbarPrimaryButtonText = 'Publish';
      topbarSecondaryButtonText = 'Submit to draft';
      topbarPrimaryButtonHandler = publish;
      topbarSecondaryButtonHandler = submitToDraft;
    }
  });

  onBeforeUnmount(() => {
    store.commit('resource/CLEAR_RESOURCE');
  });

  const cancelHandler = async () => {
    router.back();
  };

  const submitToDraft = async () => {
    store.commit('resource/SET_RESOURCE', {
      ...resource.value,
      status: 'draft',
      is_published: false,
    });
    await nextTick();
    store.dispatch('resource/createItem', resource.value).then(() => {
      router.back();
    });
  };

  const publish = async () => {
    store.commit('resource/SET_RESOURCE', {
      ...resource.value,
      status: 'published',
      is_published: true,
    });
    await nextTick();
    store.dispatch('resource/createItem', resource.value).then(() => {
      router.back();
    });
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(TopbarOrganism, {
      linkText: "Back to videos",
      showIcon: true,
      icon: "angleLeft",
      secondaryButtonText: _unref(topbarSecondaryButtonText),
      primaryButtonText: _unref(topbarPrimaryButtonText),
      onSecondaryClick: _unref(topbarSecondaryButtonHandler),
      onPrimaryClick: _unref(topbarPrimaryButtonHandler)
    }, null, 8, ["secondaryButtonText", "primaryButtonText", "onSecondaryClick", "onPrimaryClick"]),
    (!loading.value)
      ? (_openBlock(), _createBlock(VideoEditorOrganism, {
          key: 0,
          initialResourceDetails: resource.value
        }, null, 8, ["initialResourceDetails"]))
      : (_openBlock(), _createElementBlock("p", _hoisted_1, "Loading..."))
  ]))
}
}

})