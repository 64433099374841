<template>
  <div class="card-wrapper">
    <div class="card-header">
      <div v-if="icon" class="icon">
        <IconAtom :icon="icon" color="black" />
      </div>
      <div class="analytics-name">
        <p>{{ name }}</p>
      </div>
      <VueDatePicker
        v-if="datePicker"
        v-model="dauDate"
        :max-date="new Date()"
        auto-apply
        :range="false"
        :clearable="false"
        :time-picker="false"
        :enable-time-picker="false"
        @date-update="
          (e) => {
            store.commit('analytics/SET_DAU_DATE', e);
          }
        "
      >
        <template #trigger>
          <span class="evaluation-period datepick">
            {{ evaluationPeriod }}
          </span>
        </template>
      </VueDatePicker>
      <span v-else class="evaluation-period">
        {{ evaluationPeriod }}
      </span>
    </div>
    <div class="card-body">
      <div class="analytics-value">
        <h3 v-if="prefix">{{ prefix }}</h3>
        <h3>{{ count }}</h3>
        <h3 v-if="suffix">{{ suffix }}</h3>
      </div>
      <AnalyticsTrendMolecule
        v-if="trend"
        :trend-value="trend + '%'"
        :trend-type="trend > 0 ? 'positive' : 'negative'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import AnalyticsTrendMolecule from '../analytics-trend/AnalyticsTrendMolecule.vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';

  const props = withDefaults(
    defineProps<{
      count: number;
      trend?: number;
      name: string;
      icon?: string;
      prefix?: string;
      suffix?: string;
      customEvaluationPeriod?: string;
      datePicker?: boolean;
    }>(),
    { count: 0, name: 'Analytics' },
  );

  const store = useStore();
  const dauDate = computed(() => store.getters['analytics/getDauDate']);

  watch(dauDate, (dauDateNew) => {
    store.commit('analytics/SET_DAU_DATE', dauDateNew);
    store.dispatch('analytics/fetchAnalyticsData');
  });

  const dateRange = computed(() => store.getters['analytics/getDateRange']);
  const defaultEvaluationPeriod = computed(() => {
    const startDate = new Date(dateRange.value.startDate).toLocaleDateString(
      'en-US',
      {
        month: 'short',
        day: 'numeric',
      },
    );
    const endDate = new Date(dateRange.value.endDate).toLocaleDateString(
      'en-US',
      {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      },
    );
    return `${startDate} - ${endDate}`;
  });

  const evaluationPeriod = computed(() => {
    return props.datePicker
      ? dauDate.value.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      : props.customEvaluationPeriod || defaultEvaluationPeriod.value;
  });
</script>

<style scoped src="./BaseAnalyticsCardMolecule.css" />
