import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-item-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "sidebar-subitems-wrapper"
}

import { computed, useSlots } from "vue";
import IconAtom from "@/components/atoms/IconAtom.vue";
import LinkAtom from "@/components/atoms/LinkAtom.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarItemMolecule',
  props: {
  text: String,
  icon: String,
  isOpen: Boolean,
  isActive: Boolean,
},
  setup(__props) {

const props = __props;

const slots = useSlots();

const hasAtoms = computed(() => !!slots.default?.().length);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["sidebar-item-main", { open: props.isOpen, active: props.isActive }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle')))
    }, [
      _createVNode(IconAtom, {
        icon: props.icon
      }, null, 8, ["icon"]),
      _createVNode(LinkAtom, {
        class: "sidebar-item-title",
        text: props.text
      }, null, 8, ["text"]),
      (hasAtoms.value)
        ? (_openBlock(), _createBlock(IconAtom, {
            key: 0,
            icon: props.isOpen ? 'angleUp' : 'angleDown',
            color: "white",
            class: "toggle-icon"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ], 2),
    (props.isOpen && hasAtoms.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})