import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-top-section" }
const _hoisted_2 = { class: "buttons-container" }

import { onMounted, onUnmounted, ref } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import store from '@/store';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CreateDiscussionAtom',
  props: {
    show: Boolean,
    group_id: String,
  },
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const emit = __emit;

  const closeModal = () => {
    emit('update:show', false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const onSave = () => {
    store.dispatch('group/createDiscussion', {
      description: content.value,
      group_id: props.group_id,
    });
    content.value = '';
    closeModal();
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  const content = ref('');

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "discussion-modal-overlay",
        onClick: closeModal
      }, [
        _createElementVNode("div", {
          class: "discussion-modal",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Create discussion", -1)),
            _createVNode(IconAtom, {
              class: "close-icon",
              icon: "closeIcon",
              onClick: closeModal
            })
          ]),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((content).value = $event)),
            name: "placeholder",
            id: "placeholder"
          }, null, 512), [
            [_vModelText, content.value]
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(ButtonAtom, {
              class: "btn btn-primary",
              onClick: onSave
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Save")
              ])),
              _: 1
            }),
            _createVNode(ButtonAtom, {
              class: "btn btn-secondary",
              onClick: closeModal
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})