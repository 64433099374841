import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "article-wrapper" }
const _hoisted_2 = { class: "article-editor" }
const _hoisted_3 = { class: "preview-container" }
const _hoisted_4 = { class: "article-preview" }

import {
    ref,
    computed,
    watch,
    nextTick,
  } from 'vue';
  import ArticleEditorMolecule from '@/components/molecules/article-editor-molecules/ArticleEditorMolecule.vue';
  import ArticlePreviewMolecule from '@/components/molecules/article-editor-molecules/ArticlePreviewMolecule.vue';
  import viewCommentsAtom from '@/components/atoms/viewCommentsAtom.vue';
  import ViewCommentsMolecule from '@/components/molecules/view-comments-molecule/ViewCommentsMolecule.vue';
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ArticleEditorOrganism',
  props: {
    initialResourceDetails: {}
  },
  emits: ['update:resourceDetails'],
  setup(__props: any, { emit: __emit }) {

  const store = useStore();

  const props = __props;

  const previewData = ref({
    resource_name: '',
    tags: [],
    text_content: '',
  });
  const showNoCommentsModal = ref(false);

  const coverImage = ref('');

  const resource = computed(() => store.getters['resource/getResource']);
  const localResourceDetails = ref({ ...props.initialResourceDetails });

  const emit = __emit;

  let isUpdating = false;

  watch(
    resource,
    (newValue) => {
      if (!isUpdating && newValue) {
        localResourceDetails.value = { ...newValue };
        updatePreviewData({ ...newValue });
      }
    },
    { immediate: true, deep: true },
  );

  watch(
    () => store.getters['resource/getResource'],
    (newValue) => {
      if (!isUpdating && newValue) {
        localResourceDetails.value = { ...newValue };
      }
    },
    { immediate: true, deep: true },
  );

  const updateResourceDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('resource/SET_RESOURCE', updatedDetails);

    emit('update:resourceDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };

  const normalizedPreviewData = computed(() => {
    const data = { ...localResourceDetails.value };
    data.tags =
      data.tags?.map((tag) => {
        if (tag.value) {
          return { id: tag.id, value: tag.value };
        } else if (tag.tag_name) {
          return { id: tag.tag_id, value: tag.tag_name };
        } else if (tag.tag && tag.tag.tag_name) {
          return { id: tag.tag.tag_id, value: tag.tag.tag_name };
        } else {
          return { id: '', value: '' };
        }
      }) || [];
    return data;
  });

  function updatePreviewData(newCoverImage) {
    coverImage.value = newCoverImage;
  }

  function openNoCommentsModal() {
    showNoCommentsModal.value = true;
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ArticleEditorMolecule, {
        resourceDetails: localResourceDetails.value,
        onUpdateResourceDetails: updateResourceDetails,
        onUpdatePreview: updatePreviewData
      }, null, 8, ["resourceDetails"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(viewCommentsAtom, {
        onOpen: openNoCommentsModal,
        resource_id: localResourceDetails.value.resource_id
      }, null, 8, ["resource_id"]),
      _createVNode(ViewCommentsMolecule, {
        show: showNoCommentsModal.value,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showNoCommentsModal.value = $event)),
        resource_id: localResourceDetails.value.resource_id
      }, null, 8, ["show", "resource_id"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(ArticlePreviewMolecule, {
          previewData: normalizedPreviewData.value,
          coverImage: coverImage.value
        }, null, 8, ["previewData", "coverImage"])
      ])
    ])
  ]))
}
}

})