import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "notifications-molecule" }
const _hoisted_2 = { class: "sections-container" }
const _hoisted_3 = { class: "cover-section" }
const _hoisted_4 = { class: "cover-img" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "details-section" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "description" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "title" }

import { ref, watch, onMounted } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import DropdownAtom from '@/components/atoms/DropdownAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationFormMolecule',
  props: {
    details: {}
  },
  emits: ['updateDetails'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;

  const localDetails = ref({ ...props.details });
  const coverImage = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);

  watch(
    () => props.details,
    (newValue) => {
      localDetails.value = { ...newValue };
      coverImage.value = localDetails.value.banner;
    },
    { immediate: true, deep: true },
  );

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
    localDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    localDetails.value.banner = '';
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
    }
  }

  watch(
    localDetails,
    () => {
      emit('updateDetails', localDetails);
    },
    { deep: true },
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("label", { for: "title" }, "Notification details", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(ButtonAtom, {
            class: "btn btn-secondary coverBtn",
            style: _normalizeStyle({
              backgroundImage: coverImage.value ? `url(${coverImage.value})` : '',
              border: coverImage.value ? 'none' : '2px dashed #ccc',
            }),
            onClick: triggerImgUpload
          }, {
            default: _withCtx(() => [
              (!coverImage.value)
                ? (_openBlock(), _createBlock(IconAtom, {
                    key: 0,
                    icon: "cameraIcon"
                  }))
                : _createCommentVNode("", true),
              (!coverImage.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Cover"))
                : _createCommentVNode("", true),
              (coverImage.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "clear-image-btn",
                    onClick: _withModifiers(clearImage, ["stop"])
                  }, " X "))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["style"]),
          _createElementVNode("input", {
            type: "file",
            ref_key: "uploadedImg",
            ref: uploadedImg,
            accept: "image/*",
            onChange: imgupload,
            style: {"display":"none"}
          }, null, 544)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "title" }, "Title", -1)),
          _createVNode(InputAtom, {
            id: "title",
            modelValue: localDetails.value.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localDetails.value.title) = $event)),
            placeholder: "Title",
            class: "input"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "message" }, "Message", -1)),
          _withDirectives(_createElementVNode("textarea", {
            id: "message",
            placeholder: "Text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localDetails.value.message) = $event)),
            class: "description-textarea"
          }, null, 512), [
            [_vModelText, localDetails.value.message]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "url" }, "URL Link", -1)),
          _createVNode(InputAtom, {
            id: "url",
            modelValue: localDetails.value.url,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localDetails.value.url) = $event)),
            placeholder: "Link",
            class: "input"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "url" }, "Send to", -1)),
          _createVNode(DropdownAtom, {
            id: "sendTo",
            "model-value": localDetails.value.send_to,
            class: "input"
          }, null, 8, ["model-value"])
        ])
      ])
    ])
  ]))
}
}

})