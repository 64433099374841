import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "topbar" }
const _hoisted_2 = { class: "topbar-section clickable" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "topbar-section" }
const _hoisted_5 = { class: "topbar-buttons" }
const _hoisted_6 = { style: {"position":"relative"} }
const _hoisted_7 = {
  key: 1,
  class: "modal-action-container",
  ref: "userActionModalRef"
}

import { ref } from 'vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import LinkAtom from '@/components/atoms/LinkAtom.vue';
  import TopbarMolecule from '@/components/molecules/topbar-molecule/TopbarMolecule.vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { useRouter } from 'vue-router';

  type TopbarOrganismProps = {
    title?: string;
    onSecondaryClick?: () => void;
    onPrimaryClick?: () => void;
    onButtonOneClick?: () => void;
    onButtonTwoClick?: () => void;
    secondaryButtonText?: string;
    primaryButtonText?: string;
    modalButtonOne?: string;
    modalButtonTwo?: string;
    buttonLabel?: string;
    showIcon?: boolean;
    linkText?: string;
    icon?: string;
  };

  
export default /*@__PURE__*/_defineComponent({
  __name: 'TopbarOrganism',
  props: {
    title: {},
    onSecondaryClick: { type: Function },
    onPrimaryClick: { type: Function },
    onButtonOneClick: { type: Function },
    onButtonTwoClick: { type: Function },
    secondaryButtonText: {},
    primaryButtonText: {},
    modalButtonOne: {},
    modalButtonTwo: {},
    buttonLabel: {},
    showIcon: { type: Boolean },
    linkText: {},
    icon: {}
  },
  setup(__props: any) {

  const router = useRouter();

  const handleClick = (event: Event) => {
    event.stopPropagation();
    isOpen.value = !isOpen.value;
  };

  const isOpen = ref(false);

  const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.showIcon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "topbar-section clickable",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back()))
        }, [
          _createVNode(IconAtom, {
            icon: props.icon || 'angleLeft',
            color: "black"
          }, null, 8, ["icon"]),
          (props.linkText)
            ? (_openBlock(), _createBlock(LinkAtom, {
                key: 0,
                text: props.linkText
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (props.title)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(props.title), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (props.secondaryButtonText)
          ? (_openBlock(), _createBlock(ButtonAtom, {
              key: 0,
              onClick: props.onSecondaryClick,
              class: "btn btn-secondary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.secondaryButtonText), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (props.primaryButtonText)
            ? (_openBlock(), _createBlock(ButtonAtom, {
                key: 0,
                onClick: props.onPrimaryClick,
                class: "btn btn-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.primaryButtonText) + " ", 1),
                  (props.modalButtonOne || props.modalButtonTwo)
                    ? (_openBlock(), _createBlock(IconAtom, {
                        key: 0,
                        icon: isOpen.value ? 'angleUp' : 'angleDown',
                        color: "white",
                        class: "toggle-icon",
                        onClick: handleClick
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (isOpen.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", {
                  class: "modal-action",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (props.onButtonOneClick && props.onButtonOneClick(...args)))
                }, _toDisplayString(props.modalButtonOne), 1),
                _createElementVNode("span", {
                  class: "modal-action",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (props.onButtonTwoClick && props.onButtonTwoClick(...args)))
                }, _toDisplayString(props.modalButtonTwo), 1)
              ], 512))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(TopbarMolecule)
    ])
  ]))
}
}

})