import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

import IconAtom from '@/components/atoms/IconAtom.vue';
  import { onBeforeUnmount, onMounted, ref, computed } from 'vue';
  import UserActionsModal from '@/components/atoms/UserActionsModal.vue';
  import EditUserDetailsModal from '@/components/atoms/EditUserDetailsModal.vue';
  import PushNotificationsModal from '@/components/atoms/PushNotificationsModal.vue';
  import { useStore } from 'vuex';
  import User from '@/models/user/user.model';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'UserActionModal',
  props: {
    isVisible: { type: Boolean }
  },
  emits: ['close', 'action'],
  setup(__props: any, { emit: __emit }) {

  const emit = __emit;
  const userActionModalRef = ref<HTMLElement | null>(null);
  const showActionModal = ref(false);
  const showEditUserDetails = ref(false);
  const showPushNotifications = ref(false);
  const store = useStore();

  const user = computed<User>(() => store.getters['user/getSelectedUser']);
  const action = ref('');

  const openActionModal = (selectedAction: string) => {
    showActionModal.value = true;
    action.value = selectedAction;
  };

  const openEditUserDetails = () => {
    showEditUserDetails.value = true;
  };

  const openPushNotifications = () => {
    showPushNotifications.value = true;
  };

  const onActionClick = (action: string): void => {
    if (action === 'detail') {
      openEditUserDetails();
    } else if (action === 'notification') {
      openPushNotifications();
    } else {
      openActionModal(action);
    }
  };

  const actions = [
    { icon: 'settingIcon', label: 'Edit Details', type: 'detail' },
    { icon: 'bellIcon', label: 'Push Notifications', type: 'notification' },
    { icon: 'lockIcon', label: 'Reset Password', type: 'passwordReset' },
    { icon: 'banIcon', label: 'Ban from Community', type: 'ban' },
    {
      icon: 'deleteRedIcon',
      label: 'Delete Account',
      type: 'delete',
      color: 'red',
    },
  ];

  const props = __props;

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      userActionModalRef.value &&
      !userActionModalRef.value.contains(event.target as Node) &&
      props.isVisible
    ) {
      emit('close');
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "user-action-container",
      ref_key: "userActionModalRef",
      ref: userActionModalRef
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(actions, (action, index) => {
        return _createElementVNode("span", {
          key: index,
          class: _normalizeClass(["user-action", { 'user-inactive': user.value.user_status === 'inactive' }]),
          style: _normalizeStyle({ color: action.color || 'inherit' }),
          onClick: ($event: any) => (
        user.value.user_status != 'inactive' ? onActionClick(action.type) : null
      )
        }, [
          _createVNode(IconAtom, {
            icon: action.icon,
            class: "user-action-icon",
            "aria-label": action.label
          }, null, 8, ["icon", "aria-label"]),
          _createTextVNode(" " + _toDisplayString(action.label), 1)
        ], 14, _hoisted_1)
      }), 64))
    ], 512),
    _createVNode(UserActionsModal, {
      show: showActionModal.value,
      action: action.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showActionModal.value = $event)),
      "onDispatch:action": _cache[1] || (_cache[1] = ($event: any) => (emit('action', action.value)))
    }, null, 8, ["show", "action"]),
    _createVNode(EditUserDetailsModal, {
      show: showEditUserDetails.value,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (showEditUserDetails.value = $event))
    }, null, 8, ["show"]),
    _createVNode(PushNotificationsModal, {
      show: showPushNotifications.value,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (showPushNotifications.value = $event))
    }, null, 8, ["show"])
  ], 64))
}
}

})