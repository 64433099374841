import { FilterParams, SearchParamsAND } from '@/shared/filterParams';
import { localArrayFilter } from '@/shared/localArrayFilter';
import groupService from '@/services/group/group.service';
import Group from '@/models/group/group.model';
import Discussion from '@/models/discussion/discussion.model';
import discussionService from '@/services/discussion/discussion.service';

let allDiscussions = [];

const groupModule = {
  namespaced: true,
  state: {
    group: null,
    counts: { draft: 0, published: 0, unpublished: 0 },
    items: [], // Add an items state to store rowsData
    totalPages: 1, // Add a totalPages state to store pagination data
    discussions: [],
    discussionsCounts: { all: 0, draft: 0, published: 0, unpublished: 0 },
    totalDiscussionPages: 1,
    showDiscussionModal: false,
  },
  mutations: {
    SET_GROUP(state, group) {
      state.group = group;
      if (group.discussions) {
        state.discussions = group.discussions;
        allDiscussions = state.discussions;
      }
    },
    SET_GROUP_DISCUSSIONS(state, discussions) {
      state.discussions = discussions.result;
      state.discussionsCounts = discussions.counts.all;
      state.totalDiscussionPages = discussions.totalPages;
    },
    ADD_DISCUSSION(state, discussion) {
      state.discussions.push(discussion.data);
      state.discussionsCounts += 1;
    },
    SET_ITEMS(state, data) {
      state.items = data.data;
      state.totalPages = data.totalPages;
      state.counts = { ...data.counts.status };
    },
    CLEAR_GROUP(state) {
      state.group = null;
      state.discussions = [];
      allDiscussions = [];
    },
    SHOW_DISCUSSION_MODAL(state, isOpen) {
      state.showDiscussionModal = isOpen;
    },
  },
  actions: {
    async fetchGroups({ commit }, params: FilterParams) {
      const data = await groupService.getAll(params);
      commit('SET_ITEMS', data);
    },
    async createDiscussion({ commit }, data: Discussion) {
      const discussion = await discussionService.create(data);
      commit('ADD_DISCUSSION', discussion);
    },
    async fetchItemDetails({ commit }, id: string) {
      const details: any = await groupService.getById(id);
      commit('SET_GROUP', details.data);
    },
    async fetchGroupDiscussions({ commit }, params: FilterParams) {
      const discussions = localArrayFilter(allDiscussions, params);
      commit('SET_GROUP_DISCUSSIONS', discussions);
    },
    async createItem({ state, dispatch }, payload: Group) {
      dispatch('loader/showLoader', null, { root: true });
      const formData = new FormData();

      // Append string fields
      formData.append('name', payload.name); // String
      formData.append('description', payload.description); // String
      formData.append('status', payload.status); // Enum as string

      // Convert and append boolean fields as strings
      formData.append('is_published', payload.is_published.toString()); // Boolean as string ("true"/"false")

      // Append banner file if it exists
      if (payload.banner && typeof payload.banner == 'object') {
        const file = payload.banner as any;
        const fileName = file.name.replace(/\s+/g, '_');
        formData.append('bannerFile', file, fileName);
      } else if (payload.banner != '') {
        formData.append('banner', payload.banner);
      }

      try {
        if (payload.group_id) {
          await groupService.update(payload.group_id, formData);
        } else {
          await groupService.create(formData);
        }
      } catch (error) {
        console.error('Error creating group', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async deleteItems({ dispatch }, payload: { data: string[] }) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await groupService.deleteMultiple({ group_ids: payload.data });
      } catch (error) {
        console.error('Error deleting groups', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async publishItem(
      { dispatch },
      data: { id: string; filters: FilterParams },
    ) {
      const filters = structuredClone(data.filters);
      await groupService.publish(data.id);
      filters.searchParamsAND?.push(
        new SearchParamsAND('draft', 'status', 'equals'),
      );
      dispatch('fetchGroups', filters);
    },
    async restoreItem(
      { dispatch },
      data: { id: string; filters: FilterParams },
    ) {
      const filters = structuredClone(data.filters);
      await groupService.restore(data.id);
      filters?.searchParamsAND?.push(
        new SearchParamsAND('unpublished', 'status', 'equals'),
      );
      dispatch('fetchGroups', filters);
    },
    async deleteDiscussions({ dispatch, state }, payload: { data: string[] }) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await discussionService.deleteMany(payload.data);
        dispatch(this.fetchItemDetails, state.group.group_id);
      } catch (err) {
        console.error('Error deleting discussions');
        dispatch('loader/hideLoader', null, { root: true });
      } finally {
        dispatch('loader/hideLoader', null, { root: true });
      }
    },
    // Other actions...
  },
  getters: {
    getGroupCounts(state) {
      return state.counts;
    },
    getGroup(state) {
      return state.group;
    },
    getItems(state) {
      return state.items;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
    getGroupDiscussions(state) {
      return state.discussions;
    },
    getGroupDiscussionCount(state) {
      return state.discussionsCounts;
    },
    getTotalDiscussionsPages(state) {
      return state.totalDiscussionPages;
    },
    getIsDiscussionModalOpen(state) {
      return state.showDiscussionModal;
    },
  },
};

export default groupModule;
