import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["onClick"]

import IconAtom from '@/components/atoms/IconAtom.vue';
  import store from '@/store';
  import { onBeforeUnmount, onMounted, ref, computed } from 'vue';
  import { useRouter } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationActionModal',
  props: {
    isVisible: { type: Boolean },
    activeButton: {},
    notificationId: {}
  },
  emits: ['close', 'action', 'refresh'],
  setup(__props: any, { emit: __emit }) {

  const emit = __emit;
  const notificationActionModalRef = ref<HTMLElement | null>(null);
  const router = useRouter();

  const onActionClick = (action: string): void => {
  if (action === 'editDetails') {
    router.push('/notificationDetails?id=' + props.notificationId);
  } else if (action === 'deleteNotification') {
    store.dispatch('notification/deleteItem', props.notificationId).then(() => {
      emit('refresh');
    });
  }
};

  const actions = [
    { icon: 'deleteIcon', label: 'Delete notification', type: 'deleteNotification' },
    { icon: 'settingIcon', label: 'Edit scheduling date', type: 'editDate' },
    { icon: 'shareIcon', label: 'Send right now', type: 'sendNotification' },
    { icon: 'settingIcon', label: 'Edit details', type: 'editDetails' },
  ];

  const props = __props;

  const filteredActions = computed(() => {
    if (props.activeButton === 'Sent' || props.activeButton === '') {
      return [actions[0]];
    }
    if (props.activeButton === 'Scheduled') {
      return [actions[1], actions[2]];
    }
    if (props.activeButton === 'Drafts') {
      return [actions[3], actions[2]];
    }
    return [];
  });

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      notificationActionModalRef.value &&
      !notificationActionModalRef.value.contains(event.target as Node) &&
      props.isVisible
    ) {
      emit('close');
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "notification-action-container",
    ref_key: "notificationActionModalRef",
    ref: notificationActionModalRef
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredActions.value, (action, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: "notification-action",
        onClick: ($event: any) => (onActionClick(action.type))
      }, [
        _createVNode(IconAtom, {
          icon: action.icon,
          class: "notification-action-icon",
          "aria-label": action.label
        }, null, 8, ["icon", "aria-label"]),
        _createTextVNode(" " + _toDisplayString(action.label), 1)
      ], 8, _hoisted_1))
    }), 128))
  ], 512))
}
}

})