import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-container" }

import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import AnalyticsCardOrganism from '@/components/organisms/analytics-card/AnalyticsCardOrganism.vue';
  import LineChart from '@/components/organisms/charts/LineChart.vue';
  import store from '@/store';
  import { computed, onBeforeMount } from 'vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'FinanceAnalytics',
  setup(__props) {

  onBeforeMount(() => {
    store.dispatch('analytics/fetchFinancialAnalytics');
  });

  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseAnalyticsCardMolecule, {
      icon: "revenue-icon",
      prefix: "$",
      name: "MRR",
      count: analyticsData.value?.MRR
    }, null, 8, ["count"]),
    _createVNode(BaseAnalyticsCardMolecule, {
      icon: "revenue-icon",
      prefix: "$",
      name: "ARR",
      count: analyticsData.value?.ARR
    }, null, 8, ["count"]),
    _createVNode(BaseAnalyticsCardMolecule, {
      icon: "revenue-icon",
      suffix: "%",
      name: "Conversion Rate"
    }),
    _createVNode(BaseAnalyticsCardMolecule, {
      icon: "revenue-icon",
      prefix: "$",
      name: "Gross margin"
    }),
    _createVNode(BaseAnalyticsCardMolecule, {
      icon: "revenue-icon",
      prefix: "$",
      name: "Avg.  revenue per user",
      count: analyticsData.value?.averagePerUser
    }, null, 8, ["count"]),
    _createVNode(BaseAnalyticsCardMolecule, {
      icon: "revenue-icon",
      suffix: "%",
      name: "Refund rate"
    }),
    _createVNode(AnalyticsCardOrganism, {
      title: "Monthly Recurring Revenue (MRR)",
      class: "colspan-3"
    }, {
      body: _withCtx(() => [
        (analyticsData.value?.mrrPeriod)
          ? (_openBlock(), _createBlock(LineChart, {
              key: 0,
              data: analyticsData.value?.mrrPeriod
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(AnalyticsCardOrganism, {
      title: "Annual Recurring Revenue (ARR)",
      class: "colspan-3"
    }, {
      body: _withCtx(() => [
        (analyticsData.value?.arrPeriod)
          ? (_openBlock(), _createBlock(LineChart, {
              key: 0,
              data: analyticsData.value?.arrPeriod
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (analyticsData.value?.conversionRateFromInstallToSub)
      ? (_openBlock(), _createBlock(AnalyticsCardOrganism, {
          key: 0,
          title: "Conversion rate from install to subscription",
          class: "colspan-3"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})