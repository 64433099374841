import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "video-editor-molecule" }
const _hoisted_2 = { class: "cover-img" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "cover-video" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "my-video"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "video-controls" }
const _hoisted_10 = {
  id: "mute-icon",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  style: {"display":"none"}
}
const _hoisted_11 = { class: "title" }
const _hoisted_12 = { class: "text-editor" }
const _hoisted_13 = { class: "tags" }
const _hoisted_14 = { class: "collection" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "name-and-img" }
const _hoisted_17 = ["src"]

import {
    ref,
    watch,
    onMounted,
    onUnmounted,
    computed,
  } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import InputSelectAtom from '@/components/atoms/InputSelectAtom.vue';
  import AddNewTagAtom from '@/components/atoms/AddNewTagAtom.vue';
  import TinyEditor from '@/components/atoms/TinyEditor.vue';
  import AddToCollectionAtom from '@/components/atoms/AddToCollectionAtom.vue';
  import {
    SelectOption,
    transformToSelectOptions,
  } from '@/shared/selectOption';
  import tagService from '@/services/tag/tag.service';
  import Collection from '@/models/collection/collection.model';
  import collectionService from '@/services/collection/collection.service';
  import { useStore } from 'vuex';

  const actionCollectionsGetter = 'resource/getResourceCollections';
  const actionCollectionSet = 'resource/addCollection';
  const actionCollectionRemove = 'resource/removeCollection';
  const actionTagGetter = 'resource/getResourceTags';
  const actionTagSet = 'resource/addTag';
  const actionTagRemove = 'resource/removeTag';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'VideoEditorMolecule',
  props: {
    resourceDetails: {}
  },
  emits: ['updateResourceDetails', 'updatePreview'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const store = useStore();

  const localResourceDetails = ref({ ...props.resourceDetails });
  const showAddNewTagModal = ref(false);
  const showAddToCollectionModal = ref(false);
  const coverImage = ref<string | null>(null);
  const videoFile = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);
  const uploadedFile = ref<HTMLInputElement | null>(null);
  const tagOptions = ref<SelectOption[]>([]);
  const collectionOptions = ref<Collection[]>([]);

  const videoPlayer = ref<HTMLVideoElement | null>(null);
  const playButton = ref<HTMLButtonElement | null>(null);
  const playPauseButton = ref<HTMLButtonElement | null>(null);
  const playIcon = ref<HTMLElement | null>(null);
  const pauseIcon = ref<HTMLElement | null>(null);
  const progress = ref<HTMLInputElement | null>(null);
  const muteToggle = ref<HTMLButtonElement | null>(null);
  const volumeControl = ref<HTMLInputElement | null>(null);
  const timeDisplay = ref<HTMLElement | null>(null);
  const fullscreenToggle = ref<HTMLButtonElement | null>(null);

  const selectedCollections = computed(
    () => store.getters[actionCollectionsGetter] || [],
  );
  const selectedTags = computed(() => store.getters[actionTagGetter] || []);

  onMounted(() => {
    fetchTags();
    fetchCollections();
    setupVideoPlayerEvents();
  });

  onUnmounted(() => {
    cleanupVideoPlayerEvents();
  });

  watch(
    () => props.resourceDetails,
    (newValue) => {
      localResourceDetails.value = { ...newValue };
      coverImage.value = localResourceDetails.value.banner;
      videoFile.value = localResourceDetails.value.url_path;
    },
    { immediate: true, deep: true },
  );

  watch(
    localResourceDetails,
    () => {
      updatePreview();
    },
    { deep: true },
  );

  const fetchTags = async () => {
    tagOptions.value = transformToSelectOptions(
      ((await tagService.getAllNoFilter()) as any).data,
      'tag_id',
      'tag_name',
    );
  };

  const fetchCollections = async () => {
    collectionOptions.value = (
      (await collectionService.getAllNoFilter()) as any
    ).data;
  };

  function handleNewTagAdded(newTag: string) {
    fetchTags();
    showAddNewTagModal.value = false;
  }

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function openAddToCollectionModal() {
    showAddToCollectionModal.value = true;
  }

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function triggerVideoUpload() {
    uploadedFile.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
      updatePreview();
    };
    reader.readAsDataURL(file);
    localResourceDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
      localResourceDetails.value.banner = '';
    }
    updatePreview();
  }

  function clearVideoFile() {
    if (videoFile.value) {
      videoFile.value = '';
      localResourceDetails.value.url_path = '';
    }
    videoFile.value = null;
    updatePreview();

    if (uploadedFile.value) {
      uploadedFile.value.value = '';
    }
  }

  function updatePreview() {
    emit('updatePreview', videoFile.value);
    emit('updateResourceDetails', localResourceDetails);
    setVideoFileUrl();
  }

  function handleCollectionsUpdate(collections: string[]) {
    //selectedCollections.value = collections;
    localResourceDetails.value.collections = collections;
    showAddToCollectionModal.value = false;
  }

  function handleCollectionRemove(collection: string) {
    // selectedCollections.value = selectedCollections.value.filter(
    //   (c) => c !== collection
    // );

    store.dispatch(actionCollectionRemove, collection);
    localResourceDetails.value.collections = selectedCollections.value;

    if (selectedCollections.value.length === 0) {
      showAddToCollectionModal.value = false;
    }

    updatePreview();
  }

  function setVideoFileUrl() {
    if (!localResourceDetails.value.url_path) {
      return null;
    }
    if (typeof localResourceDetails.value.url_path == 'object') {
      const fileURL = URL.createObjectURL(localResourceDetails.value.url_path);
      videoFile.value = fileURL;
    }
  }

  function videoChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      videoFile.value = e.target?.result as string;
      updatePreview();
    };
    reader.readAsDataURL(file);
    localResourceDetails.value.url_path = file;
  }

  function addTagToSelection(newTag) {
    if (!localResourceDetails.value.tags) {
      localResourceDetails.value.tags = [];
    }

    if (localResourceDetails.value.tags.length < 3) {
      localResourceDetails.value.tags.push({
        id: newTag.tag_id,
        value: newTag.tag_name,
      });

      store.dispatch(actionTagSet, {
        id: newTag.tag_id,
        value: newTag.tag_name,
      });
    }
  }

  updatePreview();

  function setupVideoPlayerEvents() {
    if (videoPlayer.value) {
      videoPlayer.value.controls = false;
      videoPlayer.value.addEventListener('click', togglePlayPause);
      playButton.value?.addEventListener('click', togglePlayPause);
      playPauseButton.value?.addEventListener('click', togglePlayPause);
      videoPlayer.value?.addEventListener('play', onPlay);
      videoPlayer.value?.addEventListener('pause', onPause);
      videoPlayer.value?.addEventListener('loadedmetadata', updateDuration);
      videoPlayer.value?.addEventListener('timeupdate', updateTime);
      progress.value?.addEventListener('input', seek);
      muteToggle.value?.addEventListener('click', toggleMute);
      volumeControl.value?.addEventListener('input', adjustVolume);
      fullscreenToggle.value?.addEventListener('click', toggleFullscreen);
    }
  }

  function cleanupVideoPlayerEvents() {
    if (videoPlayer.value) {
      videoPlayer.value.removeEventListener('click', togglePlayPause);
      playButton.value?.removeEventListener('click', togglePlayPause);
      playPauseButton.value?.removeEventListener('click', togglePlayPause);
      videoPlayer.value?.removeEventListener('play', onPlay);
      videoPlayer.value?.removeEventListener('pause', onPause);
      videoPlayer.value?.removeEventListener('loadedmetadata', updateDuration);
      videoPlayer.value?.removeEventListener('timeupdate', updateTime);
      progress.value?.removeEventListener('input', seek);
      muteToggle.value?.removeEventListener('click', toggleMute);
      volumeControl.value?.removeEventListener('input', adjustVolume);
      fullscreenToggle.value?.removeEventListener('click', toggleFullscreen);
    }
  }

  function togglePlayPause() {
    const videoEl = videoPlayer.value;
    if (!videoEl) return;

    if (videoEl.paused || videoEl.ended) {
      videoEl.play();
    } else {
      videoEl.pause();
    }
  }

  function onPlay() {
    if (playIcon.value) {
      playIcon.value.style.display = 'none';
    }
    if (pauseIcon.value) {
      pauseIcon.value.style.display = 'inline';
    }
    if (playButton.value) {
      playButton.value.style.display = 'none';
    }
  }

  function onPause() {
    if (playIcon.value) {
      playIcon.value.style.display = 'inline';
    }
    if (pauseIcon.value) {
      pauseIcon.value.style.display = 'none';
    }
    if (playButton.value) {
      playButton.value.style.display = 'flex';
    }
  }

  function updateDuration() {
    const videoEl = videoPlayer.value;
    const timeEl = timeDisplay.value;

    if (!videoEl || !timeEl) return;

    store.commit('resource/SET_METADATA', { duration: videoEl.duration });

    timeEl.textContent = `00:00 / ${formatTime(videoEl.duration)}`;
  }

  function updateTime() {
    const videoEl = videoPlayer.value;
    const progressEl = progress.value;
    const timeEl = timeDisplay.value;

    if (!videoEl || !progressEl || !timeEl) return;

    const value = (100 / videoEl.duration) * videoEl.currentTime;
    progressEl.value = value.toString();
    timeEl.textContent = `${formatTime(videoEl.currentTime)} / ${formatTime(
      videoEl.duration,
    )}`;
    progressEl.style.setProperty('--progress-percent', `${value}%`);
  }

  function seek() {
    if (videoPlayer.value && progress.value) {
      const time =
        (parseFloat(progress.value.value) / 100) * videoPlayer.value.duration;
      videoPlayer.value.currentTime = time;
    }
  }

  function toggleMute() {
    if (videoPlayer.value) {
      videoPlayer.value.muted = !videoPlayer.value.muted;
      updateMuteIcon();
    }
  }

  function adjustVolume() {
    if (videoPlayer.value && volumeControl.value) {
      videoPlayer.value.volume = parseFloat(volumeControl.value.value) / 100;
      volumeControl.value.style.setProperty(
        '--volume-percent',
        `${volumeControl.value.value}%`,
      );
    }
  }

  function updateMuteIcon() {
    const volumeIcon = document.getElementById('volume-icon');
    const muteIcon = document.getElementById('mute-icon');
    if (!volumeIcon || !muteIcon) return;
    if (videoPlayer.value?.muted) {
      volumeIcon.style.display = 'none';
      muteIcon.style.display = 'inline';
    } else {
      volumeIcon.style.display = 'inline';
      muteIcon.style.display = 'none';
    }
  }

  function toggleFullscreen() {
    const videoEl = videoPlayer.value;
    if (!videoEl) return;
    if (videoEl.requestFullscreen) {
      videoEl.requestFullscreen();
    } else if ((videoEl as any).mozRequestFullScreen) {
      (videoEl as any).mozRequestFullScreen();
    } else if ((videoEl as any).webkitRequestFullscreen) {
      (videoEl as any).webkitRequestFullscreen();
    } else if ((videoEl as any).msRequestFullscreen) {
      (videoEl as any).msRequestFullscreen();
    }
  }

  function formatTime(time: number) {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor(time % 60).toString();
    return `${minutes}:${seconds}`;
  }

  watch(videoFile, (newVideo) => {
    if (!newVideo) cleanupVideoPlayerEvents();
    else setupVideoPlayerEvents();
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "editor-title" }, "Add new video details", -1)),
      _createVNode(ButtonAtom, {
        class: "btn btn-secondary coverBtn",
        style: _normalizeStyle({
          backgroundImage: coverImage.value ? `url(${coverImage.value})` : '',
          border: coverImage.value ? 'none' : '1px dashed rgba(8, 14, 30, 0.30)',
        }),
        onClick: triggerImgUpload
      }, {
        default: _withCtx(() => [
          (!coverImage.value)
            ? (_openBlock(), _createBlock(IconAtom, {
                key: 0,
                icon: "cameraIcon"
              }))
            : _createCommentVNode("", true),
          (!coverImage.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Cover"))
            : _createCommentVNode("", true),
          (coverImage.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "clear-image-btn",
                onClick: _withModifiers(clearImage, ["stop"])
              }, " X "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["style"]),
      _createElementVNode("input", {
        type: "file",
        ref_key: "uploadedImg",
        ref: uploadedImg,
        accept: "image/*",
        onChange: imgupload,
        style: {"display":"none"}
      }, null, 544)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[13] || (_cache[13] = _createElementVNode("label", { for: "title" }, "Media", -1)),
      (!videoFile.value)
        ? (_openBlock(), _createBlock(ButtonAtom, {
            key: 0,
            class: "btn btn-secondary videoCoverButton",
            onClick: triggerVideoUpload
          }, {
            default: _withCtx(() => [
              (!videoFile.value)
                ? (_openBlock(), _createBlock(IconAtom, {
                    key: 0,
                    icon: "videoIcon"
                  }))
                : _createCommentVNode("", true),
              (!videoFile.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Select a video file"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (videoFile.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("video", {
              ref_key: "videoPlayer",
              ref: videoPlayer,
              id: "video-player"
            }, [
              _createElementVNode("source", {
                src: videoFile.value,
                type: "video/mp4"
              }, null, 8, _hoisted_7),
              _createElementVNode("source", {
                src: videoFile.value,
                type: "video/quicktime"
              }, null, 8, _hoisted_8),
              _cache[6] || (_cache[6] = _createTextVNode(" Your browser does not support the video element. "))
            ], 512),
            _createElementVNode("div", {
              ref_key: "playButton",
              ref: playButton,
              class: "play-button",
              id: "play-button"
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("svg", { viewBox: "0 0 24 24" }, [
                _createElementVNode("path", { d: "M8 5v14l11-7z" })
              ], -1)
            ]), 512),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                ref_key: "playPauseButton",
                ref: playPauseButton,
                id: "play-pause"
              }, [
                (_openBlock(), _createElementBlock("svg", {
                  ref_key: "playIcon",
                  ref: playIcon,
                  id: "play-icon",
                  viewBox: "0 0 24 24",
                  fill: "#fff"
                }, _cache[8] || (_cache[8] = [
                  _createElementVNode("path", { d: "M8 5v14l11-7z" }, null, -1)
                ]), 512)),
                (_openBlock(), _createElementBlock("svg", {
                  ref_key: "pauseIcon",
                  ref: pauseIcon,
                  id: "pause-icon",
                  viewBox: "0 0 24 24",
                  style: {"display":"none"},
                  fill: "#fff"
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("path", { d: "M6 19h4V5H6v14zm8-14v14h4V5h-4z" }, null, -1)
                ]), 512))
              ], 512),
              _createElementVNode("span", {
                ref_key: "timeDisplay",
                ref: timeDisplay,
                id: "time-display"
              }, "00:00 / 00:00", 512),
              _createElementVNode("input", {
                type: "range",
                ref_key: "progress",
                ref: progress,
                id: "progress",
                value: "0",
                max: "100"
              }, null, 512),
              _createElementVNode("button", {
                ref_key: "muteToggle",
                ref: muteToggle,
                id: "mute-toggle"
              }, [
                _cache[11] || (_cache[11] = _createElementVNode("svg", {
                  id: "volume-icon",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z",
                    stroke: "white",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  })
                ], -1)),
                (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[10] || (_cache[10] = [
                  _createElementVNode("path", {
                    d: "M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z",
                    stroke: "white",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, null, -1),
                  _createElementVNode("path", {
                    d: "M2 2L22 22",
                    stroke: "white",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, null, -1)
                ])))
              ], 512),
              _createElementVNode("input", {
                type: "range",
                ref_key: "volumeControl",
                ref: volumeControl,
                id: "volume-control",
                value: "100",
                max: "100"
              }, null, 512),
              _createElementVNode("button", {
                ref_key: "fullscreenToggle",
                ref: fullscreenToggle,
                id: "fullscreen-toggle"
              }, _cache[12] || (_cache[12] = [
                _createElementVNode("svg", {
                  viewBox: "0 0 24 24",
                  fill: "#fff"
                }, [
                  _createElementVNode("path", { d: "M7 14h2v2H7zm0-4h2v2H7zm4 0h2v2h-2zm-4 8h2v2H7zm8-4h2v2h-2zm0-4h2v2h-2zm-4 8h2v2h-2zm4-8h2v2h-2z" })
                ], -1)
              ]), 512)
            ]),
            (videoFile.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "clear-video-button",
                  onClick: _withModifiers(clearVideoFile, ["stop"])
                }, " X "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        type: "file",
        ref_key: "uploadedFile",
        ref: uploadedFile,
        accept: "video/*",
        onChange: videoChange,
        style: {"display":"none"}
      }, null, 544)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[14] || (_cache[14] = _createElementVNode("label", { for: "title" }, "Title", -1)),
      _createVNode(InputAtom, {
        id: "title",
        modelValue: localResourceDetails.value.resource_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localResourceDetails.value.resource_name) = $event)),
        placeholder: "Name",
        class: "input"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[15] || (_cache[15] = _createElementVNode("label", { for: "text" }, "Description", -1)),
      _createVNode(TinyEditor, {
        modelValue: localResourceDetails.value.text_content,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localResourceDetails.value.text_content) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[16] || (_cache[16] = _createElementVNode("label", { for: "tags" }, "Tags (maximum 3)", -1)),
      _createVNode(InputSelectAtom, {
        id: "tags",
        placeholder: "Add tags",
        modelValue: localResourceDetails.value.tags,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localResourceDetails.value.tags) = $event)),
        options: tagOptions.value,
        onAddNew: openAddNewTagModal,
        tagsGetter: actionTagGetter,
        tagSet: actionTagSet,
        tagRemove: actionTagRemove
      }, null, 8, ["modelValue", "options"]),
      _createVNode(AddNewTagAtom, {
        show: showAddNewTagModal.value,
        "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (showAddNewTagModal.value = $event)),
        onNewTagAdded: handleNewTagAdded,
        onAddTagToSelection: addTagToSelection
      }, null, 8, ["show"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[18] || (_cache[18] = _createElementVNode("label", { for: "collection" }, "Collection", -1)),
      (selectedCollections.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCollections.value, (collection) => {
              return (_openBlock(), _createElementBlock("div", {
                key: collection,
                class: "collection-item"
              }, [
                _createElementVNode("div", _hoisted_16, [
                  (collection.banner)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: collection.banner,
                        alt: "Collection Image",
                        class: "collection-icon"
                      }, null, 8, _hoisted_17))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(collection.collection_name), 1)
                ]),
                _createVNode(IconAtom, {
                  icon: "deleteIcon",
                  onClick: () => handleCollectionRemove(collection),
                  class: "pointer"
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(ButtonAtom, {
        class: "btn btn-secondary collectionBtn",
        onClick: openAddToCollectionModal
      }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [
          _createTextVNode(" + Add to a collection ")
        ])),
        _: 1
      }),
      _createVNode(AddToCollectionAtom, {
        show: showAddToCollectionModal.value,
        collections: collectionOptions.value,
        "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => (showAddToCollectionModal.value = $event)),
        onUpdateCollections: handleCollectionsUpdate,
        actionCollectionsGetter: actionCollectionsGetter,
        actionCollectionSet: actionCollectionSet,
        actionCollectionRemove: actionCollectionRemove
      }, null, 8, ["show", "collections"])
    ])
  ]))
}
}

})