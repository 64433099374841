<template>
  <div class="analytics-container">
    <UsersAnalyticsOrganism />
  </div>
</template>

<script setup lang="ts">
  import UsersAnalyticsOrganism from '@/components/organisms/users-analytics/UsersAnalyticsOrganism.vue';
  import store from '@/store';
  import { computed, onBeforeMount, watch } from 'vue';

  onBeforeMount(() => {
    store.dispatch('analytics/fetchAnalyticsData');
  });

  const dateRange = computed(() => store.getters['analytics/getDateRange']);
  watch(dateRange.value, () => {
    store.dispatch('analytics/fetchAnalyticsData');
  });
</script>
