import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "analytics-grid performance-analytics-grid" }
const _hoisted_2 = { class: "monthly-top-container" }

import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import store from '@/store';
  import { computed, watch } from 'vue';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';
  import MostViewedCardMolecule from '@/components/molecules/most-viewed-card/MostViewedCardMolecule.vue';
  import BarChart from '../charts/BarChart.vue';
  import GroupActivityTableMolecule from '@/components/molecules/group-activity-table/GroupActivityTableMolecule.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'PerformanceAnalyticsOrganism',
  setup(__props) {

  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

  watch(analyticsData, (val) => {
    console.log(val);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseAnalyticsCardMolecule, _mergeProps(analyticsData.value?.challengesPerformance?.checkins, {
      name: "Checkins initiated",
      icon: "challenges-icon",
      class: "col-basecard"
    }), null, 16),
    _createVNode(BaseAnalyticsCardMolecule, _mergeProps(analyticsData.value?.challengesPerformance?.challenges, {
      name: "Challenges completed",
      icon: "challenges-icon",
      class: "col-basecard"
    }), null, 16),
    _createVNode(AnalyticsCardOrganism, {
      title: "Most activity on a group",
      class: "col-groupactivity"
    }, {
      body: _withCtx(() => [
        _createVNode(GroupActivityTableMolecule)
      ]),
      _: 1
    }),
    _createVNode(AnalyticsCardOrganism, {
      title: "Most viewed resource content",
      class: "col-mostviewed"
    }, {
      body: _withCtx(() => [
        (analyticsData.value?.mostViewedResourceContent)
          ? (_openBlock(), _createBlock(BarChart, {
              key: 0,
              data: analyticsData.value?.mostViewedResourceContent,
              "bar-color": "#1D43A6",
              orientation: "horizontal",
              "x-axis-title": "Views"
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(AnalyticsCardOrganism, {
      title: "Top resources in selected date range",
      class: "col-full"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(analyticsData.value?.monthlyTopByType?.monthlyTop, (resource) => {
            return (_openBlock(), _createBlock(MostViewedCardMolecule, _mergeProps({
              key: resource.resource_id,
              ref_for: true
            }, resource), null, 16))
          }), 128))
        ])
      ]),
      _: 1
    }),
    (analyticsData.value?.roiOverTime)
      ? (_openBlock(), _createBlock(AnalyticsCardOrganism, {
          key: 0,
          title: "ROI over time",
          class: "col-full"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})