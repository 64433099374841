import analyticsService from '@/services/analytics/analytics.service';

const analyticsModule = {
  namespaced: true,
  state: {
    data: {},
    dateRange: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
    },
    dauDate: new Date(),
  },
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_DATE_RANGE(state, payload) {
      state.dateRange.startDate = payload.startDate;
      state.dateRange.endDate = payload.endDate;
    },
    SET_DAU_DATE(state, payload: Date) {
      state.dauDate = payload;
    },
  },
  actions: {
    async fetchAnalyticsData({ commit, state }) {
      const { startDate, endDate } = state.dateRange;
      const response: any = await analyticsService.getUserAnalytics(
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0],
        state.dauDate.toISOString().split('T')[0],
      );

      const totalAppDownloads: any =
        await analyticsService.getDownloadsForDateInterval(
          startDate.toISOString().split('T')[0],
          endDate.toISOString().split('T')[0],
        );

      console.log(totalAppDownloads);

      const downloadsStats: any = await analyticsService.getDownloadCount();
      commit('SET_DATA', {
        ...response.data,
        ...downloadsStats.data,
        ...totalAppDownloads.data,
      });
    },
    async fetchPerformanceAnalytics({ commit, state }) {
      const { startDate, endDate } = state.dateRange;
      const general: any = await analyticsService.getPerformanceAnalytics(
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0],
      );
      const mostViewedResourceContent: any =
        await analyticsService.getMostViewedResourceContent(
          startDate.toISOString().split('T')[0],
          endDate.toISOString().split('T')[0],
        );
      const groupsPerformance: any = await analyticsService.getGroupStats(
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0],
      );
      commit('SET_DATA', {
        ...general.data,
        mostViewedResourceContent: mostViewedResourceContent.data,
        mostActiveGroups: groupsPerformance.data,
      });
    },
    async fetchDemographicsAnalytics({ commit }) {
      const response: any = await analyticsService.getDemographicsAnalytics();
      commit('SET_DATA', response.data);
    },
    async fetchFinancialAnalytics({ commit }) {
      const response: any = await analyticsService.getFinancialAnalytics();
      const dataForPeriod: any =
        await analyticsService.getFinancialAnalyticsForPeriod();
      commit('SET_DATA', { ...response.data, ...dataForPeriod.data });
    },
    async fetchSubscriptionsAnalytics({ commit, state }) {
      const response: any = await analyticsService.getSubscriptionsAnalytics();

      commit('SET_DATA', {
        ...state.data,
        subscriptionsAnalytics: response.data,
      });
    },
  },
  getters: {
    getAnalyticsData(state) {
      return state.data;
    },
    getDateRange(state) {
      return state.dateRange;
    },
    getDauDate(state) {
      return state.dauDate;
    },
  },
};

export default analyticsModule;
