import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Video-container" }

import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import tagService from '@/services/tag/tag.service';
  import { SearchbarModalOptions } from '@/shared/searchbarModalOptions';
  import { onMounted, ref } from 'vue';

  const dataType = 'Video';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'VideosListView',
  setup(__props) {

  const filterOptions = ref<SearchbarModalOptions[]>([]);

  onMounted(async () => {
    try {
      const allTags: any = await tagService.getAllNoFilter();
      const tagFilterOptions = allTags.data
        .filter((tag) => tag.resource_count > 0)
        .map((tag) => {
          return new SearchbarModalOptions(
            `tags.tag.tag_name:${tag.tag_name}`,
            tag.tag_name,
          );
        });
      filterOptions.value = [...tagFilterOptions];
    } catch (error) {
      console.error('Failed to load tags:', error);
    }
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TopbarOrganism, { title: "Videos" }),
    _createVNode(TableMolecule, {
      dataType: dataType,
      filterOptions: filterOptions.value
    }, null, 8, ["filterOptions"])
  ]))
}
}

})