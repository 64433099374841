import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "reset-password-modal-overlay"
}
const _hoisted_2 = { class: "reset-password-modal" }
const _hoisted_3 = { class: "buttons" }

import { ref, onMounted, onUnmounted } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordModal',
  props: {
    show: Boolean,
  },
  emits: ['update:show', 'go-back'],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const emits = __emit;

  const handleGoBack = () => {
    emits('go-back');
  };

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "reset-title" }, " Reset password has been sent. Check your email. ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(ButtonAtom, { class: "btn btn-primary" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Go to Mail")
              ])),
              _: 1
            }),
            _createVNode(ButtonAtom, {
              class: "btn btn-secondary",
              onClick: handleGoBack
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Go Back")
              ])),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})