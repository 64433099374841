<script setup lang="ts">
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { FilterParams, SearchParamsAND } from '@/shared/filterParams';
  import { computed, ref, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import VueDatePicker from '@vuepic/vue-datepicker';

  const emits = defineEmits([
    'update:modelValue',
    'buttonClick',
    'update:activeTab',
    'update:dateRange',
  ]);
  const store = useStore();

  const props = defineProps<{
    filterField: string;
    buttons: { text: string; value: string }[];
    detailsButtonprops?: {
      route: string;
      buttonClass: string;
      icon: string;
      iconColor: string;
      buttonText: string;
    };
    showButton: boolean;
    showDatePicker?: boolean;
    modelValue: FilterParams;
    countGetter?: string;
  }>();

  const activeButton = ref<string>(props?.buttons?.at(0)?.text || 'All');
  const filterParams = ref(props.modelValue);
  const dateRange = ref<[Date | null, Date | null]>([
    new Date(store.state.analytics.dateRange.startDate),
    new Date(store.state.analytics.dateRange.endDate),
  ]);

  const counts = computed(() =>
    props.countGetter ? store.getters[props.countGetter || ''] : null,
  );

  const toggleButton = (buttonText: string, status: string) => {
    activeButton.value = buttonText; // Update activeButton on click
    emits(
      'update:modelValue',
      new SearchParamsAND(status, props.filterField, 'equals'),
    );
    emits('update:activeTab', status);
  };

  const isActive = (buttonText: string) => {
    return activeButton.value === buttonText;
  };

  // Watch modelValue prop to update filterParams accordingly
  watch(
    () => props.modelValue,
    (newValue) => {
      filterParams.value = newValue;
    },
  );

  watch(dateRange, ([startDate, endDate]) => {
    if (startDate && endDate) {
      store.commit('analytics/SET_DATE_RANGE', { startDate, endDate });
      emits('update:dateRange', { from: startDate, to: endDate });
    }
  });

  const emitButtonClick = () => {
    emits('buttonClick', props.detailsButtonprops);
  };

  onMounted(() => {
    if (props.buttons && props.buttons.length > 0) {
      activeButton.value = props.buttons[0]?.text;
    }
  });
</script>

<template>
  <div class="statusbar-container">
    <div class="statusbar-collection">
      <ButtonAtom
        v-for="button in props.buttons"
        :key="button.text"
        class="status-btn btn btn-secondary"
        :class="{ active: isActive(button.text) }"
        @click="toggleButton(button.text, button.value)"
      >
        <p>{{ button.text }}</p>
        <span
          v-if="counts"
          class="count"
          :class="{ 'count-active': isActive(button.text) }"
        >
          {{ counts[button.value === '' ? 'all' : button.value] || 0 }}
        </span>
      </ButtonAtom>
    </div>
    <div
      @click="emitButtonClick"
      v-if="props.detailsButtonprops"
      class="link"
      :to="props.detailsButtonprops.route"
    >
      <ButtonAtom :class="props.detailsButtonprops.buttonClass">
        <IconAtom
          :icon="props.detailsButtonprops.icon"
          :color="props.detailsButtonprops.iconColor"
        />
        <p>{{ props.detailsButtonprops.buttonText }}</p>
      </ButtonAtom>
    </div>
    <div v-if="props.showDatePicker" class="date-picker-container">
      <VueDatePicker
        v-model="dateRange"
        :range="true"
        :auto-apply="true"
        :enable-time-picker="false"
        :format="'yyyy/MM/dd'"
        :max-date="new Date()"
      />
    </div>
  </div>
</template>
<style scoped src="./TabbarOrganism.css"></style>
