import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "user-details-section" }
const _hoisted_2 = { class: "center-container" }
const _hoisted_3 = { class: "cover-section" }
const _hoisted_4 = { class: "cover-img" }
const _hoisted_5 = { class: "clear-image-btn" }
const _hoisted_6 = { class: "form-section" }
const _hoisted_7 = { class: "form-item title" }
const _hoisted_8 = { class: "form-item username" }
const _hoisted_9 = { class: "form-item mobile" }
const _hoisted_10 = { class: "form-item email-id" }
const _hoisted_11 = { class: "buttons-section" }

import { onMounted, onUnmounted, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import User from '@/models/user/user.model';
  import { watch } from 'vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'EditUserDetailsModal',
  props: {
    show: { type: Boolean }
  },
  emits: ['update:show'],
  setup(__props: any, { emit: __emit }) {

  const store = useStore();
  const selectedUser = computed<User>(
    () => store.getters['user/getSelectedUser'],
  );
  const props = __props;

  const emits = __emit;

  const localUser = ref<User>({} as User);

  onMounted(() => {
    if (selectedUser.value) {
      localUser.value = JSON.parse(JSON.stringify(selectedUser.value));
    }
  });

  watch(selectedUser, (newVal) => {
    if (newVal) {
      localUser.value = JSON.parse(JSON.stringify(newVal));
    }
  });

  const closeModal = () => {
    emits('update:show', false);
    localUser.value = JSON.parse(JSON.stringify(selectedUser.value));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const updateUser = () => {
    const updatedUser = {
      name: localUser.value.full_name?.split(' ')[0],
      surname: localUser.value.full_name?.split(' ')[1],
      phone_number: localUser.value.phone_number,
      email: localUser.value.email,
      username: localUser.value.username,
      user_id: localUser.value.user_id,
    };
    store.dispatch('user/updateUser', updatedUser);
    closeModal();
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (props.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "edit-detials-modal-overlay",
          onClick: closeModal
        }, [
          _createElementVNode("div", {
            class: "edit-details-modal",
            onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
          }, [
            _cache[13] || (_cache[13] = _createElementVNode("h2", null, "Edit details", -1)),
            _createVNode(IconAtom, {
              class: "close-icon",
              icon: "closeIcon",
              onClick: closeModal
            }),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(ButtonAtom, { class: "btn btn-secondary coverBtn" }, {
                      default: _withCtx(() => [
                        _createVNode(IconAtom, { icon: "cameraIcon" }),
                        _cache[6] || (_cache[6] = _createElementVNode("p", null, "Cover", -1)),
                        _createElementVNode("button", _hoisted_5, [
                          _createVNode(IconAtom, {
                            icon: "editIcon",
                            color: "#6B6E78"
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createElementVNode("input", {
                      type: "file",
                      ref: "uploadedImg",
                      accept: "image/*",
                      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.imgupload && _ctx.imgupload(...args))),
                      style: {"display":"none"}
                    }, null, 544)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "title" }, "Name", -1)),
                  _createVNode(InputAtom, {
                    id: "title",
                    placeholder: "Name",
                    class: "input",
                    modelValue: localUser.value.full_name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localUser.value.full_name) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", { for: "username" }, "Username", -1)),
                  _createVNode(InputAtom, {
                    id: "username",
                    placeholder: "Username",
                    modelValue: localUser.value.username,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localUser.value.username) = $event)),
                    class: "input"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { for: "mobile" }, "Mobile", -1)),
                  _createVNode(InputAtom, {
                    id: "mobile",
                    placeholder: "Mobile",
                    modelValue: localUser.value.phone_number,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((localUser.value.phone_number) = $event)),
                    class: "input"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", { for: "email-id" }, "Email ID", -1)),
                  _createVNode(InputAtom, {
                    id: "email-id",
                    placeholder: "Email ID",
                    modelValue: localUser.value.email,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((localUser.value.email) = $event)),
                    class: "input"
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(ButtonAtom, {
                class: "btn btn-secondary",
                onClick: closeModal
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("p", null, "Cancel", -1)
                ])),
                _: 1
              }),
              _createVNode(ButtonAtom, {
                class: "btn btn-primary",
                onClick: updateUser
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("p", null, "Save", -1)
                ])),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})