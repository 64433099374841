import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-section" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "buttons-section" }

import {
    onMounted,
    onUnmounted,
    computed,
    ref,
    watch,
  } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import { useStore } from 'vuex';

  type UserActionsModal = {
    title: string;
    message?: string;
    buttonPrimaryText: string;
    buttonSecondaryText: string;
  };
  
export default /*@__PURE__*/_defineComponent({
  __name: 'UserActionsModal',
  props: {
    show: Boolean,
    action: String,
  },
  emits: ['update:show', 'dispatch:action'],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const store = useStore();
  const selectedUser = computed(() => store.getters['user/getSelectedUser']);
  const emits = __emit;

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const modalContext = ref<UserActionsModal>({
    title: '',
    message: '',
    buttonPrimaryText: '',
    buttonSecondaryText: '',
  });

  const handleAction = () => {
    emits('dispatch:action');
    closeModal();
  };

  const updateModalContext = () => {
    switch (props.action) {
      case 'delete':
        modalContext.value.title = `Are you sure you want to delete ${selectedUser.value.full_name}'s account?`;
        modalContext.value.message =
          'Account cannot be restored, only created again';
        modalContext.value.buttonPrimaryText = 'Yes, delete account';
        modalContext.value.buttonSecondaryText = 'No, keep account';
        break;

      case 'passwordReset':
        modalContext.value.title = `Do you want to send a password reset to ${selectedUser.value.name}?`;
        modalContext.value.message =
          'User will be able to reset their password';
        modalContext.value.buttonPrimaryText = 'Yes, send';
        modalContext.value.buttonSecondaryText = 'No';
        break;

      case 'ban':
        modalContext.value.title = `Are you sure you want to ban ${selectedUser.value.name} from all communities they joined?`;
        modalContext.value.message = '';
        modalContext.value.buttonPrimaryText = 'Yes, ban from community';
        modalContext.value.buttonSecondaryText = 'No';
        break;

      case 'notification':
        modalContext.value.title = `Are you sure you want to ban ${selectedUser.value.name} from all communities they joined?`;
        modalContext.value.message = '';
        modalContext.value.buttonSecondaryText = 'Cancel';
        modalContext.value.buttonPrimaryText = 'Send personal notification';
        break;

      default:
        modalContext.value.title = '';
        modalContext.value.message = '';
        modalContext.value.buttonPrimaryText = '';
        modalContext.value.buttonSecondaryText = '';
        break;
    }
  };

  watch(() => props.action, updateModalContext, { immediate: true });

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

return (_ctx: any,_cache: any) => {
  return (__props.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "delete-modal-overlay",
        onClick: closeModal
      }, [
        _createElementVNode("div", {
          class: "delete-modal",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h2", null, _toDisplayString(modalContext.value.title), 1),
            _createVNode(IconAtom, {
              class: "close-icon",
              icon: "closeIcon",
              onClick: closeModal
            }),
            (modalContext.value.message)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(modalContext.value.message), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(ButtonAtom, {
              class: "btn btn-primary",
              onClick: handleAction
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(modalContext.value.buttonPrimaryText), 1)
              ]),
              _: 1
            }),
            _createVNode(ButtonAtom, {
              class: "btn btn-secondary",
              onClick: closeModal
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(modalContext.value.buttonSecondaryText), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})