import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "analytics-grid-2-cols" }
const _hoisted_3 = { class: "app-analytics-body" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "analytics-grid-2-cols" }
const _hoisted_6 = { class: "app-analytics-body" }
const _hoisted_7 = { class: "app-analytics-body" }
const _hoisted_8 = { class: "app-analytics-body" }

import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionsAnalyticsOrganism',
  setup(__props) {

  const store = useStore();
  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "analytics-group-title" }, "Number of users using coupon", -1)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(analyticsData.value?.subscriptionsAnalytics?.usersPerCoupon
          ?.coupons, (coupon) => {
          return (_openBlock(), _createBlock(AnalyticsCardOrganism, {
            title: coupon.couponCode,
            key: coupon.couponCode,
            icon: "analyticsUsers",
            class: "analytics-card"
          }, {
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString(coupon.usersCount || 'n/a'), 1)
              ])
            ]),
            _: 2
          }, 1032, ["title"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "analytics-group-title" }, "Subscription details", -1)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(AnalyticsCardOrganism, {
          title: "Users in free trial",
          icon: "analyticsUsers",
          class: "analytics-card"
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", null, _toDisplayString(analyticsData.value?.subscriptionsAnalytics?.usersInTrial || 'n/a'), 1)
            ])
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(analyticsData.value?.subscriptionsAnalytics
          ?.payingUsers?.subscriptionPlans, (subscriptionPlan) => {
          return (_openBlock(), _createBlock(AnalyticsCardOrganism, {
            title: subscriptionPlan.plan_id.replaceAll('_', ' '),
            key: subscriptionPlan.plan_id,
            icon: "analyticsUsers",
            class: "analytics-card"
          }, {
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", null, _toDisplayString(subscriptionPlan.usersCount || 'n/a'), 1)
              ])
            ]),
            _: 2
          }, 1032, ["title"]))
        }), 128)),
        (_openBlock(), _createBlock(AnalyticsCardOrganism, {
          title: "Total paying users",
          key: analyticsData.value?.subscriptionsAnalytics?.payingUsers?.totalUsers,
          icon: "analyticsUsers",
          class: "analytics-card"
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h3", null, _toDisplayString(analyticsData.value?.subscriptionsAnalytics?.payingUsers
                  ?.totalUsers || 'n/a'), 1)
            ])
          ]),
          _: 1
        }))
      ])
    ])
  ], 64))
}
}

})