import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-in-wrapper" }
const _hoisted_2 = { class: "sign-in-form" }
const _hoisted_3 = { class: "sign-in-inputs" }

import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import apiService from '@/services/api.service';
  import authService from '@/services/auth/auth.service';
  import { jwtDecode } from 'jwt-decode';
  import { computed, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import ResetPasswordModal from '@/components/atoms/ResetPasswordModal.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'SignInView',
  setup(__props) {

  const store = useStore();
  const router = useRouter();

  const user = computed(() => store.getters['user/getUser']);

  const username = ref('');
  const password = ref('');
  const showAddNewTagModal = ref(false);
  const isVisible = ref(true);

  const login = async () => {
    try {
      const response = await authService.login(username.value, password.value);
      if (response.access_token) {
        await apiService.setAuthHeaders(response.access_token);
        await apiService.storeAuthData(response);

        const decodedToken = jwtDecode(response.access_token);
        store.dispatch('user/fetchUser', decodedToken.sub);
        watch(user, (newUser, oldUser) => {
          if (newUser) {
            router.push('/');
          }
        });
      } else {
        //showAlert("Error loging in", response.data.message);
      }
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error
    }
  };

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
    isVisible.value = !isVisible.value;
  }

  function handleGoBack() {
    isVisible.value = !isVisible.value;
    showAddNewTagModal.value = false;
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "logo-text" }, "Bryan Post", -1)),
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "header" }, [
          _createElementVNode("h1", { class: "sign-in-title" }, "Sign in to Bryan Post")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InputAtom, {
            class: "input-signin",
            id: "username-input",
            placeholder: "Email",
            modelValue: username.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
            onKeyup: _withKeys(login, ["enter"])
          }, null, 8, ["modelValue"]),
          _createVNode(InputAtom, {
            class: "input-signin",
            id: "password-input",
            placeholder: "Password",
            modelValue: password.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            type: "password",
            onKeyup: _withKeys(login, ["enter"])
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", { class: "reset-password" }, [
          _createElementVNode("label", {
            class: "reset-password-btn",
            onClick: openAddNewTagModal
          }, "Reset password")
        ]),
        _createVNode(ButtonAtom, {
          class: "btn btn-primary sign-in-btn",
          onClick: login
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Sign in")
          ])),
          _: 1
        })
      ], 512), [
        [_vShow, isVisible.value]
      ])
    ]),
    _createVNode(ResetPasswordModal, {
      show: showAddNewTagModal.value,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (showAddNewTagModal.value = $event)),
      onGoBack: handleGoBack
    }, null, 8, ["show"])
  ], 64))
}
}

})