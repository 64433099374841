import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';

  const dataType = 'User';

export default /*@__PURE__*/_defineComponent({
  __name: 'UsersListView',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(TopbarOrganism, { title: "Users" }),
    _createVNode(TableMolecule, { dataType: dataType })
  ]))
}
}

})