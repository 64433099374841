import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sort-modal-title modal-item" }
const _hoisted_2 = { class: "sort-options" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "sort-radio-label" }

import { SearchbarModalOptions } from "@/shared/searchbarModalOptions";
import { onBeforeUnmount, onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SortModalMolecule',
  props: {
    isVisible: { type: Boolean },
    top: {},
    left: {},
    sortModalTitle: { default: "Sort By:" },
    sortOptions: {}
  },
  emits: ["close", "update:sort"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const modalStyle = ref({
  position: "absolute",
  top: `${props.top}px`,
  left: `${props.left}px`,
});

const emit = __emit;
const sortModalRef = ref<HTMLElement | null>(null);
const selectedOption = ref<string | null>(null);

const emitSelectedOption = () => {
  emit("update:sort", selectedOption.value);
};

const handleClickOutside = (event: MouseEvent): void => {
  if (
    sortModalRef.value &&
    !sortModalRef.value.contains(event.target as Node) &&
    props.isVisible
  ) {
    emit("close");
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "sortModalRef",
        ref: sortModalRef,
        style: _normalizeStyle({ position: 'absolute', top: _ctx.top + 'px', left: _ctx.left + 'px' }),
        class: "sort-modal"
      }, [
        _createElementVNode("span", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(props.sortModalTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.sortOptions, (option) => {
            return (_openBlock(), _createElementBlock("p", {
              key: option.key,
              class: "modal-item"
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                class: "sort-radio-input",
                value: option.key,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOption).value = $event)),
                onChange: emitSelectedOption
              }, null, 40, _hoisted_3), [
                [_vModelRadio, selectedOption.value]
              ]),
              _createElementVNode("label", _hoisted_4, _toDisplayString(option.value), 1)
            ]))
          }), 128))
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})