import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    scales,
  } from 'chart.js';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'BarChart',
  props: {
    orientation: { default: 'vertical' },
    data: {},
    xAxisTitle: {},
    yAxisTitle: {},
    dataKey: { default: 'data' },
    labelKey: { default: 'label' },
    barColor: { default: '#1D43A6' },
    barThickness: { default: 40 }
  },
  setup(__props: any) {

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  );
  const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Bar), {
    options: {
      indexAxis: _ctx.orientation === 'horizontal' ? 'y' : 'x',
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          suggestedMax:
            Math.max(...props.data.map((dataSet) => dataSet[_ctx.dataKey])) * 1.2,
          title: { text: _ctx.xAxisTitle ?? '', display: _ctx.xAxisTitle !== undefined },
          grid: {
            display: _ctx.orientation === 'horizontal',
          },
        },
        y: {
          suggestedMax:
            Math.max(...props.data.map((dataSet) => dataSet[_ctx.dataKey])) * 1.2,
          title: {
            text: _ctx.yAxisTitle ?? '',
            display: _ctx.yAxisTitle !== undefined,
          },
          grid: {
            display: _ctx.orientation === 'vertical',
          },
        },
      },
    },
    data: {
      labels: props.data.map(
        (dataArr) =>
          dataArr[_ctx.labelKey].charAt(0).toUpperCase() +
          dataArr[_ctx.labelKey].substring(1),
      ),
      datasets: [
        {
          data: props.data.map((dataSet) => dataSet[_ctx.dataKey]),
          backgroundColor: props.data.map((i) => props.barColor),
          barThickness: props.barThickness,
        },
      ],
    }
  }, null, 8, ["options", "data"]))
}
}

})