import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-container" }

import { onMounted, ref, watch } from "vue";
import IconAtom from "@/components/atoms/IconAtom.vue";
import InputAtom from "@/components/atoms/InputAtom.vue";
import ButtonAtom from "@/components/atoms/ButtonAtom.vue";
import { useStore } from "vuex";
import { FilterParams } from "@/shared/filterParams";
import SortModalMolecule from "../sort-modal-molecule/SortModalMolecule.vue";
import { SearchbarModalOptions } from "@/shared/searchbarModalOptions";
import FilterModalMolecule from "../filter-modal-molecule/FilterModalMolecule.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchbarMolecule',
  props: {
    showSort: { type: Boolean, default: true },
    showFilter: { type: Boolean, default: true },
    filterTitle: {},
    itemsFetchAction: {},
    pageSize: { default: 10 },
    additionalSortOptions: {},
    filterOptions: { default: () => [
      new SearchbarModalOptions(
        "tags.tag.tag_name:postman test",
        "postman test"
      ),
    ] },
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const searchQuery = ref("");
const sortProperty = ref("");
const filterProperties = ref<string[]>([]);
const store = useStore();
const showSortModal = ref(false);
const showFilterModal = ref(false);
const sortModalPosition = ref({ top: 0, left: 0 });
const filterModalPosition = ref({ top: 0, right: 0 });
const emit = __emit;

const defaultSortOptions: SearchbarModalOptions[] = [
  new SearchbarModalOptions("created_at:desc", "Recent first"),
  new SearchbarModalOptions("created_at:asc", "Old first"),
];

const sortOptions = ref<SearchbarModalOptions[]>([]);

const props = __props;

onMounted(() => {
  sortOptions.value = props.additionalSortOptions
    ? [...defaultSortOptions, ...props.additionalSortOptions]
    : [...defaultSortOptions];
});

const filterParams = ref(props.modelValue);

const buildFilterParams = () => {
  let filterParamsNew = new FilterParams(1, props.pageSize, {
    searchTerm: searchQuery.value,
  });

  if (sortProperty.value && sortProperty.value != "") {
    const sortProps = sortProperty.value.split(":");
    filterParamsNew.sortParams = {
      sortBy: sortProps[0],
      sortOrder: sortProps[1],
    };
  }

  if (filterProperties.value && filterProperties.value.length > 0) {
    const searchParamsAND = filterProperties.value.map((filterProp) => {
      const propParts = filterProp.split(":");
      return {
        searchField: propParts[0],
        searchTerm: propParts[1],
        action: "equals",
      };
    });
    filterParamsNew.searchParamsAND = searchParamsAND;
  }

  filterParams.value = filterParamsNew;

  emit("update:modelValue", filterParams.value);
};

watch(searchQuery, (newVal) => {
  buildFilterParams();
  //return store.dispatch("resource/fetchResources", buildFilterParams());
});

watch(sortProperty, (newVal) => {
  buildFilterParams();
  //return store.dispatch("resource/fetchResources", buildFilterParams());
});

watch(filterProperties, (newVal) => {
  buildFilterParams();
  //return store.dispatch("resource/fetchResources", buildFilterParams());
});

const openSortModal = (event: MouseEvent): void => {
  showFilterModal.value = false;
  event.stopPropagation();
  let targetElement = event.target as HTMLElement;

  // Traverse up the DOM tree to find the closest button element
  while (targetElement && targetElement.tagName !== "BUTTON") {
    targetElement = targetElement.parentElement as HTMLElement;
  }

  if (targetElement) {
    const buttonRect = targetElement.getBoundingClientRect();

    sortModalPosition.value = {
      top: buttonRect.bottom,
      left: buttonRect.left,
    };
    showSortModal.value = true;
  }
};

const openFilterModal = (event: MouseEvent): void => {
  showSortModal.value = false;
  event.stopPropagation();
  let targetElement = event.target as HTMLElement;

  // Traverse up the DOM tree to find the closest button element
  while (targetElement && targetElement.tagName !== "BUTTON") {
    targetElement = targetElement.parentElement as HTMLElement;
  }

  if (targetElement) {
    const buttonRect = targetElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth; // Get the width of the viewport
    filterModalPosition.value = {
      top: buttonRect.bottom,
      right: viewportWidth - buttonRect.right, // Calculate right position
    };
    showFilterModal.value = true;
  }
};

const handleSortUpdate = (selectedSort: string) => {
  sortProperty.value = selectedSort;
};
const handleFilterUpdate = (selectedFilters: string[]) => {
  filterProperties.value = selectedFilters ? [...selectedFilters] : [];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ButtonAtom, { class: "btn btn-secondary" }, {
      default: _withCtx(() => [
        _createVNode(IconAtom, {
          icon: "searchIcon",
          color: "#333"
        })
      ]),
      _: 1
    }),
    _createVNode(InputAtom, {
      class: "search-input",
      placeholder: "Search...",
      modelValue: searchQuery.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event))
    }, null, 8, ["modelValue"]),
    (props.showSort)
      ? (_openBlock(), _createBlock(ButtonAtom, {
          key: 0,
          class: "btn btn-secondary",
          onClick: openSortModal
        }, {
          default: _withCtx(() => [
            _createVNode(IconAtom, {
              icon: "sortIcon",
              color: "#333"
            }),
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "sort", -1))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (props.showFilter)
      ? (_openBlock(), _createBlock(ButtonAtom, {
          key: 1,
          class: "btn btn-secondary",
          onClick: openFilterModal
        }, {
          default: _withCtx(() => [
            _createVNode(IconAtom, {
              icon: "filterIcon",
              color: "#333"
            }),
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "filter", -1))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(SortModalMolecule, {
      isVisible: showSortModal.value,
      top: sortModalPosition.value.top,
      left: sortModalPosition.value.left,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (showSortModal.value = false)),
      "onUpdate:sort": handleSortUpdate,
      sortOptions: sortOptions.value
    }, null, 8, ["isVisible", "top", "left", "sortOptions"]),
    _createVNode(FilterModalMolecule, {
      filterModalTitle: props.filterTitle,
      isVisible: showFilterModal.value,
      top: filterModalPosition.value.top,
      right: filterModalPosition.value.right,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (showFilterModal.value = false)),
      filterOptions: _ctx.filterOptions,
      "onUpdate:sort": handleFilterUpdate
    }, null, 8, ["filterModalTitle", "isVisible", "top", "right", "filterOptions"])
  ]))
}
}

})