import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-grid" }
const _hoisted_2 = { class: "analytics-grid-2-cols" }
const _hoisted_3 = { class: "analytics-grid-2-cols" }
const _hoisted_4 = { class: "app-analytics-body" }
const _hoisted_5 = { class: "app-analytics-body" }
const _hoisted_6 = { class: "analytics-grid" }

import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import { computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import PieChart from '../charts/PieChart.vue';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';
  import BarChart from '../charts/BarChart.vue';
  import LineChart from '../charts/LineChart.vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'UsersAnalyticsOrganism',
  setup(__props) {

  const store = useStore();
  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

  watch(analyticsData, () => {
    console.log(analyticsData.value);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BaseAnalyticsCardMolecule, _mergeProps(analyticsData.value?.activeUsers?.daily, {
        name: "Daily active users",
        icon: "analyticsUsers",
        "date-picker": "",
        "custom-evaluation-period": 
        new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      
      }), null, 16, ["custom-evaluation-period"]),
      _createVNode(BaseAnalyticsCardMolecule, _mergeProps(analyticsData.value?.activeUsers?.newUsers, {
        name: "New users in selected date range",
        icon: "analyticsUsers"
      }), null, 16),
      _createVNode(BaseAnalyticsCardMolecule, _mergeProps(analyticsData.value?.activeUsers?.monthly, {
        name: "Active users in selected date range",
        icon: "analyticsUsers"
      }), null, 16)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BaseAnalyticsCardMolecule, {
        name: "Churn rate",
        icon: "analyticsUsers"
      }),
      _createVNode(BaseAnalyticsCardMolecule, {
        count: analyticsData.value?.totalDownloads,
        name: "Installs in selected date range",
        icon: "download-icon"
      }, null, 8, ["count"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(AnalyticsCardOrganism, {
        title: "iOS app downloads",
        "header-subtitle": "Total downloads"
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", null, _toDisplayString(analyticsData.value?.app_downloads_ios || 'n/a'), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(AnalyticsCardOrganism, {
        title: "Android app downloads",
        "header-subtitle": "Total downloads"
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", null, _toDisplayString(analyticsData.value?.app_downloads_android || 'n/a'), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(AnalyticsCardOrganism, {
        title: "User Growth Rate",
        class: "colspan-2"
      }, {
        body: _withCtx(() => [
          (analyticsData.value?.userGrowthRate)
            ? (_openBlock(), _createBlock(LineChart, {
                key: 0,
                data: analyticsData.value?.userGrowthRate
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(AnalyticsCardOrganism, { title: "Retention Rate" }, {
        body: _withCtx(() => [
          _createVNode(BarChart, { data: [
            { label: '30d', data: 10 },
            { label: '60d', data: 20 },
            { label: '90d', data: 30 },
          ] })
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})