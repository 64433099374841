import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filter-modal-title modal-item" }
const _hoisted_2 = { class: "filter-options" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "filter-checkbox-label" }

import { SearchbarModalOptions } from "@/shared/searchbarModalOptions";
import { onBeforeUnmount, onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterModalMolecule',
  props: {
    isVisible: { type: Boolean },
    top: {},
    right: {},
    filterModalTitle: { default: "Filter By:" },
    filterOptions: {}
  },
  emits: ["close", "update:sort"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const filterModalRef = ref<HTMLElement | null>(null);
const selectedOptions = ref<string[]>([]);

const emitSelectedOptions = () => {
  emit("update:sort", selectedOptions.value);
};

const handleClickOutside = (event: MouseEvent): void => {
  if (
    filterModalRef.value &&
    !filterModalRef.value.contains(event.target as Node) &&
    props.isVisible
  ) {
    emit("close");
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "filterModalRef",
        ref: filterModalRef,
        style: _normalizeStyle({ position: 'absolute', top: _ctx.top + 'px', right: _ctx.right + 'px' }),
        class: "filter-modal"
      }, [
        _createElementVNode("span", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.filterModalTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterOptions, (option) => {
            return (_openBlock(), _createElementBlock("label", {
              key: option.key,
              class: "modal-item"
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "filter-checkbox-input",
                value: option.key,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOptions).value = $event)),
                onChange: emitSelectedOptions
              }, null, 40, _hoisted_3), [
                [_vModelCheckbox, selectedOptions.value]
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "custom-checkbox" }, null, -1)),
              _createElementVNode("span", _hoisted_4, _toDisplayString(option.value), 1)
            ]))
          }), 128))
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})